<menu></menu>
<div id="container">
    <div class="form">
        <div class="header">Listagem de Laudos de Folha</div>
        <div class="struct" id="struct">
            <div class="search grid5">
                <div class="item">
                    Produtor
                    <div class="input">
                        <input [(ngModel)]="form.produtor" (keyup.enter)="searchLaudo()"/>
                    </div>
                </div>
                <div class="item">
                    Talhão
                    <div class="input">
                        <input [(ngModel)]="form.talhao" (keyup.enter)="searchLaudo()"/>
                    </div>
                </div>
                <div class="item">
                    Solicitante
                    <div class="input">
                        <input [(ngModel)]="form.solicitante" (keyup.enter)="searchLaudo()"/>
                    </div>
                </div>
                <div class="item">
                    Data
                    <div class="input">
                        <input type="date" [(ngModel)]="form.data" (keyup.enter)="searchLaudo()"/>
                    </div>
                </div>
                <div class="item">
                    <div></div>
                    <div class="button" (click)="searchLaudo()">
                        Pesquisar
                    </div>
                </div>
            </div>
            <div class="table" style="--cs: 1fr 200px 1fr 100px" *ngIf="list">
                <div class="toptable" table="true" *ngIf="list.length>0">
                    <div class="label">Produtor</div>
                    <div class="label">Talhão</div>
                    <div class="label">Solicitante</div>
                    <div class="label">Data</div>
                </div>
                <div class="bodytable" table="true" *ngFor="let item of list" (click)="open(item.id)">
                    <div class="label">
                        ({{ item.idcliente }}) {{ item.dscliente }}
                    </div>
                    <div class="label">{{ item.talhao }}</div>
                    <div class="label">
                        ({{ item.idsolicitante }}) {{ item.dssolicitante }}
                    </div>
                    <div class="label">
                        {{ item.dtlaudo | date: "dd/MM/yyyy" }}
                    </div>
                </div>
                <div class='vazio' *ngIf="list.length==0">Nenhum Laudo Disponível!</div>
            </div>
        </div>
    </div>
</div>
<spinner *ngIf="loading"></spinner>
