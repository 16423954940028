import { Component, Input, OnInit } from '@angular/core';
import { Cliente } from 'src/app/class/cliente';
import { NovoclienteComponent } from 'src/app/register/novocliente/novocliente.component';
import { ConnectionService } from 'src/app/services/connection.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-listclientes',
  templateUrl: './listclientes.component.html',
  styleUrls: ['./listclientes.component.scss']
})
export class ListclientesComponent implements OnInit {
  public loading: boolean = false
  @Input() public clientes: Cliente[] = []
  constructor(
    private conn: ConnectionService,
    private util: UtilService
  ) {

  }

  ngOnInit(): void {
    this.carregar() 
  }

  async carregar(){
    this.loading = true
    this.clientes = []
    this.clientes = Cliente.fromJsonArray(await this.conn.getCliente().toPromise())
    this.loading = false
  }

  async edit(item){
    await this.util.dialog(NovoclienteComponent,item)
    await this.carregar()
  }

}
