<div id="search">
    <div class="menvazio" *ngIf="data.length == 0">
        <div class="label">Nenhum Proprietário localizado!</div>
    </div>
    <div class="label">
        Pesquisa
    </div>
    <div class="input">
        <input (keyup)="search()" [(ngModel)]="term"/>
    </div>
    <div class="table" *ngIf="data.length > 0">
        <div class="toptable" table="true" style="--cs: 120px 1fr">
            <div class="label">ID</div>
            <div class="label">Proprietário</div>
        </div>
        <div
            class="bodytable"
            table="true"
            style="--cs: 120px 1fr"
            *ngFor="let item of list"
            (click)="select(item)"
        >
            <div class="label">
                {{ item.id }}
            </div>
            <div class="label">
                {{ item.dscliente }}
            </div>
        </div>
    </div>
</div>
