<menu></menu>
<div id="container">
    <div class="form">
        <div class="header">
            <div class="top">BBF PLANT</div>
            <div class="icon" *ngIf="laudo.id && util.getSaldoLaudos() > 0">
                <mat-icon
                    aria-hidden="false"
                    (click)="duplicar()"
                    matTooltip="Duplicar Laudo"
                    matTooltipPosition="below"
                    >content_copy</mat-icon
                >
            </div>
        </div>
        <div class="struct" id="struct" *ngIf="!islaudo">
            <div class="section">Identificação do Laudo</div>
            <div class="fields grid3">
                <div class="label">
                    Proprietário
                    <span *ngIf="!laudo.idcliente" class="errorlabel">
                        (Campo obrigatório!)</span
                    >
                    <div table="true" style="--cs: 120px 1fr; --rs: 1fr">
                        <div class="input">
                            <input
                                #idcliente
                                type="number"
                                id="idcliente"
                                [(ngModel)]="laudo.idcliente"
                                (keyup.f2)="pickCliente()"
                                matTooltip="Pressione F2 para buscar"
                                (focusout)="lostFocusCliente()"
                                [disabled]="laudo.id"
                            />
                        </div>
                        <div class="input" (click)="pickCliente()">
                            <input disabled [(ngModel)]="laudo.dscliente" />
                        </div>
                    </div>
                </div>
                <div class="label">
                    Solicitante
                    <span *ngIf="!laudo.idsolicitante" class="errorlabel">
                        (Campo obrigatório!)</span
                    >
                    <div table="true" style="--cs: 120px 1fr; --rs: 1fr">
                        <div class="input">
                            <input
                                #idcliente
                                type="number"
                                id="idsolicitante"
                                [(ngModel)]="laudo.idsolicitante"
                                (keyup.f2)="pickSolicitante()"
                                matTooltip="Pressione F2 para buscar"
                                (focusout)="lostFocusSolicitante()"
                                [disabled]="laudo.id"
                            />
                        </div>
                        <div class="input">
                            <input disabled [(ngModel)]="laudo.dssolicitante" />
                        </div>
                    </div>
                </div>
                <div class="label">
                    Consultor
                    <span *ngIf="!laudo.idconsultor" class="errorlabel">
                        (Campo obrigatório!)</span
                    >
                    <div table="true" style="--cs: 120px 1fr; --rs: 1fr">
                        <div class="input">
                            <input
                                #idcliente
                                type="number"
                                id="idconsultor"
                                [(ngModel)]="laudo.idconsultor"
                                (keyup.f2)="pickConsultor()"
                                matTooltip="Pressione F2 para buscar"
                                (focusout)="lostFocusConsultor()"
                                [disabled]="laudo.id"
                            />
                        </div>
                        <div class="input">
                            <input disabled [(ngModel)]="laudo.dsconsultor" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="grid3">
                <div class="label">
                    Talhão
                    <span *ngIf="!laudo.talhao" class="errorlabel">
                        (Campo obrigatório!)</span
                    >
                    <div>
                        <div class="input">
                            <input
                                id="talhao"
                                [disabled]="laudo.id"
                                [(ngModel)]="laudo.talhao"
                            />
                        </div>
                    </div>
                </div>
                <div class="label">
                    Área
                    <span *ngIf="!laudo.area" class="errorlabel">
                        (Campo obrigatório!)</span
                    >
                    <div>
                        <div class="input">
                            <input
                                id="area"
                                [disabled]="laudo.id"
                                [(ngModel)]="laudo.area"
                                type="number"
                            />
                        </div>
                    </div>
                </div>
                <div class="label">
                    Dias
                    <span *ngIf="!laudo.dias" class="errorlabel">
                        (Campo obrigatório!)</span
                    >
                    <div>
                        <div class="select">
                            <mat-select
                                #diasctrl
                                [(ngModel)]="laudo.dias"
                                id="area"
                                [disabled]="laudo.id"
                            >
                                <mat-option
                                    *ngFor="let dia of dias"
                                    [value]="dia"
                                >
                                    {{ dia }}
                                </mat-option>
                            </mat-select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section">Detalhamento do Laudo</div>
            <div class="grid3">
                <div class="label">
                    Estado
                    <span *ngIf="!laudo.estado" class="errorlabel">
                        (Campo obrigatório!)</span
                    >
                    <div>
                        <div class="input">
                            <input
                                id="estado"
                                [disabled]="laudo.id"
                                [(ngModel)]="laudo.estado"
                                type="text"
                                [matAutocomplete]="state"
                                (focusout)="checkEstado()"
                                (keyup)="filterEstado()"
                            />
                            <mat-autocomplete
                                autoActiveFirstOption
                                #state="matAutocomplete"
                            >
                                <mat-option
                                    *ngFor="let option of estados"
                                    [value]="option"
                                >
                                    {{ option }}
                                </mat-option>
                            </mat-autocomplete>
                        </div>
                    </div>
                </div>
                <div class="label">
                    Município
                    <span *ngIf="!laudo.municipio" class="errorlabel">
                        (Campo obrigatório!)</span
                    >
                    <div>
                        <div class="input">
                            <input
                                id="municipio"
                                [disabled]="laudo.id"
                                [(ngModel)]="laudo.municipio"
                                type="text"
                                [matAutocomplete]="city"
                                (focusout)="checkCidade()"
                                (keyup)="filterCidades()"
                            />
                            <mat-autocomplete
                                autoActiveFirstOption
                                #city="matAutocomplete"
                            >
                                <mat-option
                                    *ngFor="let option of municipios"
                                    [value]="option"
                                >
                                    {{ option }}
                                </mat-option>
                            </mat-autocomplete>
                        </div>
                    </div>
                </div>
                <div class="label">
                    <div>
                        Cultura
                        <span *ngIf="!laudo.idcultura" class="errorlabel">
                            (Campo obrigatório!)</span
                        >
                    </div>
                    <div class="select">
                        <mat-select
                            #idcultura
                            id="idcultura"
                            [(ngModel)]="laudo.idcultura"
                            (selectionChange)="setCultura($event)"
                            [disabled]="laudo.id"
                        >
                            <mat-option
                                *ngFor="let c of culturas"
                                [value]="c.id"
                            >
                                {{ c.dscultura }}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>
            </div>
            <div class="section">Nutrientes</div>
            <div class="grid2">
                <div class="table">
                    <div class="toptable">
                        <div class="label c">Macronutrientes - g/Kg</div>
                    </div>
                    <div class="grid6">
                        <div *ngFor="let m of macronutrientes | getkeyvalue">
                            <div class="toptable">
                                <div class="label c">
                                    {{ m.value }}
                                </div>
                            </div>
                            <div class="bodytable">
                                <div class="inputtab">
                                    <input
                                        [(ngModel)]="laudo.nutrientes[m.key]"
                                        type="number"
                                        min="0"
                                        [disabled]="laudo.id"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="table">
                    <div class="toptable">
                        <div class="label c">Micronutrientes - mg/Kg</div>
                    </div>
                    <div class="grid6">
                        <div *ngFor="let m of micronutrientes | getkeyvalue">
                            <div class="toptable">
                                <div class="label c">
                                    {{ m.value }}
                                </div>
                            </div>
                            <div class="bodytable">
                                <div class="inputtab">
                                    <input
                                        [(ngModel)]="laudo.nutrientes[m.key]"
                                        type="number"
                                        min="0"
                                        [disabled]="laudo.id"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <br />
            <br />
            <div class="grid3">
                <div></div>
                <div></div>
                <div
                    color="sucess"
                    class="button btgreen"
                    table="true"
                    (click)="salvar()"
                >
                    <mat-icon aria-hidden="false">done</mat-icon>
                    <span>Salvar/Calcular</span>
                </div>
            </div>
        </div>
        <laudofolha *ngIf="islaudo" [calculo]="calculo"></laudofolha>
    </div>
</div>
<spinner *ngIf="loading"></spinner>
