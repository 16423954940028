<menu></menu>
<div id="container">
    <div class="form">
        <div class="header" table=true style="--cs:1fr 50px">
            <span>Listagem de Usuários</span>
            <mat-icon (click)="edit(null)">note_add</mat-icon>
        </div>
        <div class="struct" id="struct">
            <buscausuario *ngIf="!loading" [data]="usuarios" (sign)="edit($event)"></buscausuario>
        </div>
    </div>
</div>
<spinner *ngIf="loading"></spinner>