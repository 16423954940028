import { Component, Input, OnInit } from '@angular/core';
import { Consultor } from 'src/app/class/consultor';
import { NovoconsultorComponent } from 'src/app/register/novoconsultor/novoconsultor.component';
import { NovosolicitanteComponent } from 'src/app/register/novosolicitante/novosolicitante.component';
import { ConnectionService } from 'src/app/services/connection.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-listconsultor',
  templateUrl: './listconsultor.component.html',
  styleUrls: ['./listconsultor.component.scss']
})
export class ListconsultorComponent implements OnInit {
  public loading: boolean = false
  @Input() public consultores: Consultor[] = []
  constructor(
    private conn: ConnectionService,
    public util: UtilService
  ) {

  }

  ngOnInit(): void {
    this.carregar()
  }

  async carregar() {
    this.loading = true
    this.consultores = []
    this.consultores = Consultor.fromJsonArray(await this.conn.getConsultor().toPromise())
    this.loading = false
  }

  async edit(item) {
    this.util.dialog(NovoconsultorComponent, item, "250px").then(() => {
      this.carregar()
    })
  }

}
