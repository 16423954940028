import { Injectable } from '@angular/core';
import { Cultura } from '../class/cultura';
import { Cliente } from '../class/cliente';
import { Solicitante } from '../class/solicitante';
import { User } from '../class/user';
import { Component, Inject } from '@angular/core';
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Laudo } from '../class/laudo';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { OptionpaneComponent } from '../components/optionpane/optionpane.component';
import { Consultor } from '../class/consultor';

@Injectable({
    providedIn: 'root',
})
export class UtilService {
    public user: User = new User();
    public culturas: Cultura[] = [];
    public clientes: Cliente[] = [];
    public solicitantes: Solicitante[] = [];
    public consultores: Consultor[] = [];
    public laudo: Laudo = new Laudo();
    public usinglogo: boolean = true;
    public loading: boolean = false;
    public messagespin: string = 'Carregando...';
    public tplaudo = 'soil';
    public force_novo = false;

    constructor(
        public route: Router,
        public dlg: MatDialog,
        public snackBar: MatSnackBar
    ) {
        // this.user.hascode = "37664a95918e6e6640cec08ac708b5d91b456f63"
    }

    public focus(id) {
        document.getElementById(id).focus();
    }

    public getUseLogoPlanta() {
        let res = localStorage.getItem('use_laudo_planta');
        return res=="1";
    }

    public setUseLogoPlanta(use) {
        localStorage.setItem('use_laudo_planta', use ? "1" : "0");
    }

    public setUser(user: User) {
        if (user.id) {
            this.user = user;
        }
    }

    public getHascode() {
        return this.user.hascode;
    }

    public clearLogin() {
        this.user = new User();
    }

    public getIdUser() {
        return this.user.id;
    }

    public getDsUser() {
        return this.user.dsuser;
    }

    public getSaldoLaudos() {
        return Number(this.user.saldo);
    }

    public setSaldoLaudos(value: any) {
        this.user.saldo = String(value);
    }

    public getImageUser() {
        return this.user.image;
    }

    public setImageUser(image: any) {
        this.user.image = String(image);
    }

    public isMaster() {
        return this.user.ismaster == '1' ? true : false;
    }

    public alertDanger(message: string = '', duration = 4000) {
        this.snackBar.open(message, 'x', {
            panelClass: ['dangersnack'],
            duration: duration,
        });
    }

    public async dialog(component, data = null, h = '500px', w = '800px') {
        let dialogRef = this.dlg.open(component, {
            height: h,
            width: w,
            data: data,
            disableClose: true,
        });

        return await dialogRef.afterClosed().toPromise();
    }

    public async sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    public go(route) {
        this.route.navigate([`/${route}`]);
    }

    async optionPane(
        title: string,
        message: string,
        buttons: any[] = [],
        w = '50vw',
        h = '30vh'
    ) {
        const dialogRef = this.dlg.open(OptionpaneComponent, {
            data: { title: title, message: message, buttons: buttons },
            width: w,
            height: h,
        });
        return await dialogRef.afterClosed().toPromise();
    }

    compareDate(d1, d2, dc) {
        let dcomp = dc.split(' ')[0];
        if (!d1 && !d2) return true;
        else if (d1 && !d2) {
            return new Date(dcomp).getTime() >= new Date(d1).getTime();
        } else {
            return (
                new Date(dcomp).getTime() >= new Date(d1).getTime() &&
                new Date(dcomp).getTime() <= new Date(d2).getTime()
            );
        }
    }

    formatChart(arr: any, keyname, keyvalue) {
        let res = {};
        let labels = [];
        let value = [...arr];
        value = value.sort((a: any, b: any) => {
            return (
                new Date(b.dtupdate).getTime() - new Date(a.dtupdate).getTime()
            );
        });
        for (let v of value) {
            if (!res[v.dtupdate]) {
                res[v.dtupdate] = {};
            }
            res[v.dtupdate][v[keyname]] = v[keyvalue];
            if (labels.indexOf(v[keyname]) == -1) labels.push(v[keyname]);
        }
        let data = [];
        for (let r in res) {
            let v = {};
            v['x'] = r;
            for (let l of labels) {
                if (res[r][l]) {
                    v[l] = Number(res[r][l]);
                }
                // else {
                //   v[l] = 0.00
                // }
            }
            data.push(v);
        }
        return [data.reverse(), labels];
    }

    initSpin(message) {
        try {
            this.messagespin = message;
            this.loading = true;
        } catch (e) {
            console.log(e);
            this.loading = false;
        }
    }

    stopSpin() {
        this.messagespin = 'Carregando...';
        this.loading = false;
    }
}
