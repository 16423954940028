<h2 class="c">Questionário Preparatório (Preenchimento Obrigatório)</h2>
<div class="section">Informações da Área</div>
<div table="true" class="tree_forms">
    <!-- <div table="true" style="--cs: 1fr; --rs: 20px 50px">
        <div>ID</div>
        <div class="input">
            <input disabled [(ngModel)]="laudo.id" />
        </div>
    </div> -->
    <div table="true" style="--cs: 1fr; --rs: 20px 50px">
        <div>
            Proprietário
            <span *ngIf="!laudo.idcliente" class="errorlabel">
                (Campo obrigatório!)</span
            >
        </div>
        <div table="true" style="--cs: 120px 1fr; --rs: 1fr">
            <div class="input">
                <input
                    #idcliente
                    type="number"
                    id="first"
                    [(ngModel)]="laudo.idcliente"
                    (keyup.f2)="pickCliente()"
                    matTooltip="Pressione F2 para buscar"
                    (focusout)="lostFocusCliente()"
                    [disabled]="laudo.id"
                />
            </div>
            <div class="input">
                <input disabled [(ngModel)]="laudo.dscliente" />
            </div>
        </div>
    </div>
    <div table="true" style="--cs: 1fr; --rs: 20px 50px">
        <div>
            Identificação da Amostra
            <span *ngIf="!laudo.idamostra" class="errorlabel">
                (Campo obrigatório!)</span
            >
        </div>
        <div class="input">
            <input
                #idamostra
                [disabled]="laudo.id"
                [(ngModel)]="laudo.idamostra"
            />
        </div>
    </div>
    <div table="true" style="--cs: 1fr; --rs: 20px 50px">
        <div>
            Solicitante
            <span *ngIf="!laudo.idsolicitante" class="errorlabel">
                (Campo obrigatório!)</span
            >
        </div>

        <div table="true" style="--cs: 120px 1fr; --rs: 1fr">
            <div class="input">
                <input
                    #idsolicitante
                    type="number"
                    [(ngModel)]="laudo.idsolicitante"
                    (keyup.f2)="pickSolicitante()"
                    matTooltip="Pressione F2 para buscar"
                    (focusout)="lostFocusSolicitante()"
                    [disabled]="laudo.id"
                />
            </div>
            <div class="input">
                <input disabled [(ngModel)]="laudo.dssolicitante" />
            </div>
        </div>
    </div>
    <div table="true" style="--cs: 1fr; --rs: 20px 50px">
        <div>
            Consultor
            <span *ngIf="!laudo.idconsultor" class="errorlabel">
                (Campo obrigatório!)</span
            >
        </div>

        <div table="true" style="--cs: 120px 1fr; --rs: 1fr">
            <div class="input">
                <input
                    #idconsultor
                    type="number"
                    [(ngModel)]="laudo.idconsultor"
                    (keyup.f2)="pickConsultor()"
                    matTooltip="Pressione F2 para buscar"
                    (focusout)="lostFocusConsultor()"
                />
            </div>
            <div class="input">
                <input disabled [(ngModel)]="laudo.dsconsultor" />
            </div>
        </div>
    </div>
    <div table="true" style="--cs: 1fr; --rs: 20px 50px">
        <div>
            Área (ha)
        </div>
        <div table="true" style="--cs: 1fr; --rs: 1fr">
            <div class="input">
                <input type="number" [(ngModel)]="laudo.area" />
            </div>
        </div>
    </div>
</div>
<br />
<div class="section">Recomendação Inicial</div>
<div table="true" class="two_forms">
    <div table="true" style="--cs: 1fr; --rs: 20px 50px">
        <div>
            Cultura a ser implantada
            <span *ngIf="!laudo.idcultura" class="errorlabel">
                (Campo obrigatório!)</span
            >
        </div>
        <div class="select">
            <mat-select
                #idcultura
                [(ngModel)]="laudo.idcultura"
                [disabled]="laudo.id"
            >
                <mat-option *ngFor="let c of util.culturas" [value]="c.id">
                    {{ c.dscultura }}
                </mat-option>
            </mat-select>
        </div>
    </div>
    <div table="true" style="--cs: 1fr; --rs: 20px 50px">
        <div>
            Produção Estimada <span>{{ undAlgod(laudo.idcultura) }}</span>
            <span *ngIf="!laudo.producaoest" class="errorlabel">
                (Campo obrigatório!)</span
            >
        </div>
        <div class="input">
            <input
                #producaoest
                [(ngModel)]="laudo.producaoest"
                type="number"
                min="0"
                [disabled]="laudo.id"
            />
        </div>
    </div>
</div>
<br />
<div class="section">Novo cálculo para mesma análise</div>
<div table="true" class="novo_calc">
    <div table="true" style="--cs: 1fr; --rs: 20px 50px">
        <div>
            <!-- Cálculo para segunda recomendação? -->
        </div>
        <div class="space">
            <mat-slide-toggle
                [(ngModel)]="issegunda"
                (change)="changeSegunda($event)"
                >Cálculo para segunda recomendação?</mat-slide-toggle
            >
        </div>
    </div>
    <div table="true" style="--cs: 1fr; --rs: 20px 50px">
        <div>
            Cultura Anterior
            <!-- <span *ngIf="!laudo.idculturaant" class="errorlabel">
                (Campo obrigatório!)</span
            > -->
        </div>
        <div class="select">
            <mat-select #idculturaant [(ngModel)]="laudo.idculturaant" [disabled]="!issegunda">
                <mat-option *ngFor="let c of util.culturas" [value]="c.id">
                    {{ c.dscultura }}
                </mat-option>
            </mat-select>
        </div>
    </div>
    <div table="true" style="--cs: 1fr; --rs: 20px 50px">
        <div>
            Produtividade atingida anteriormente
            <span>{{ undAlgod(laudo.idculturaant) }}</span>
            <!-- <span *ngIf="!laudo.produtividadeant" class="errorlabel">
                (Campo obrigatório!)</span
            > -->
        </div>
        <div class="input">
            <input
                #produtividadeant
                type="number"
                [(ngModel)]="laudo.produtividadeant"
                min="0"
                [disabled]="!issegunda"
            />
        </div>
    </div>
</div>
<br />
<div table="true" class='novo_calc'>
    <div table="true" style="--cs: 1fr; --rs: 20px 50px">
        <div>Segunda Cultura a Implantar</div>
        <div class="select">
            <mat-select
                #idsegcultura
                [(ngModel)]="laudo.idsegcultura"
                [disabled]="!issegunda"
            >
                <mat-option *ngFor="let c of util.culturas" [value]="c.id">
                    {{ c.dscultura }}
                </mat-option>
            </mat-select>
        </div>
    </div>
    <div table="true" style="--cs: 1fr; --rs: 20px 50px">
        <div>
            Produção Estimada <span>{{ undAlgod(laudo.idsegcultura) }}</span>
            <span
                *ngIf="laudo.idsegcultura && !laudo.produtividadeest"
                class="errorlabel"
            >
                (Campo obrigatório!)</span
            >
        </div>
        <div class="input">
            <input
                #produtividadeest
                type="number"
                [(ngModel)]="laudo.produtividadeest"
                min="0"
                [disabled]="!issegunda"
            />
        </div>
    </div>
</div>
<br />
<div class="section">Dados de Infiltração</div>
<div table="true" class="novo_calc">
    <div table="true" style="--cs: 1fr; --rs: 20px 50px">
        <div>
        </div>
        <div class="space">
            <mat-slide-toggle
                [(ngModel)]="isinfiltracao"
                (change)="changeInfiltracao($event)"
                [disabled]="laudo.id"
                >Possui dados de infiltração?</mat-slide-toggle
            >
        </div>
    </div>
    <div table="true" style="--cs: 1fr; --rs: 20px 50px">
        <div>
            Taxa de Infiltração Observada (mm/h)
            <span
                *ngIf="isinfiltracao && !laudo.txinfiltracao"
                class="errorlabel"
            >
                (Campo obrigatório!)</span
            >
        </div>
        <div class="input">
            <input
                #txinfiltracao
                [(ngModel)]="laudo.txinfiltracao"
                [disabled]="!isinfiltracao || laudo.id"
                min="0"
                type="number"
            />
        </div>
    </div>
    <div table="true" style="--cs: 1fr; --rs: 20px 50px">
        <div>Infiltração Acumulada (mm)</div>
        <div class="input">
            <input
                #infiltracaoac
                [(ngModel)]="laudo.infiltracaoac"
                [disabled]="!isinfiltracao || laudo.id"
                min="0"
                type="number"
            />
        </div>
    </div>
</div>
<br />
<div class="section">Correção do Solo</div>
<div table="true" class="four_forms">
    <div table="true" style="--cs: 1fr; --rs: 20px 50px">
        <div>
            Ca desejado (%)
            <span *ngIf="!laudo.cadesejado" class="errorlabel">
                (Campo obrigatório!)</span
            >
        </div>
        <div class="input">
            <input
                #cadesejado
                type="number"
                [(ngModel)]="laudo.cadesejado"
                min="0"
                [disabled]="laudo.id"
            />
        </div>
    </div>
    <div table="true" style="--cs: 1fr; --rs: 20px 50px">
        <div>
            Mg desejado (%)
            <span *ngIf="!laudo.mgdesejado" class="errorlabel">
                (Campo obrigatório!)</span
            >
        </div>
        <div class="input">
            <input
                #mgdesejado
                type="number"
                [(ngModel)]="laudo.mgdesejado"
                min="0"
                [disabled]="laudo.id"
            />
        </div>
    </div>
    <div table="true" style="--cs: 1fr; --rs: 20px 50px">
        <div>
            K desejado (%)
            <span *ngIf="!laudo.kdesejado" class="errorlabel">
                (Campo obrigatório!)</span
            >
        </div>
        <div class="input">
            <input
                #kdesejado
                type="number"
                [(ngModel)]="laudo.kdesejado"
                min="0"
                [disabled]="laudo.id"
            />
        </div>
    </div>
    <div table="true" style="--cs: 1fr; --rs: 20px 50px">
        <div>
            VR desejado
            <span *ngIf="!laudo.vrdesejado" class="errorlabel">
                (Campo obrigatório!)</span
            >
        </div>
        <div class="input">
            <input
                #vrdesejado
                type="number"
                [(ngModel)]="laudo.vrdesejado"
                min="0"
                [disabled]="laudo.id"
                (keyup.enter)="next()"
            />
        </div>
    </div>
</div>

<br />
<div table="true" style="--cs: 1fr 1fr 1fr 1fr">
    <div></div>
    <div></div>
    <div color="sucess" class="button btred" table="true">
        <mat-icon aria-hidden="false">close</mat-icon>
        <span>Cancelar</span>
    </div>
    <div color="sucess" class="button btgreen" table="true" (click)="next()">
        <mat-icon aria-hidden="false">done</mat-icon>
        <span>Prosseguir</span>
    </div>
</div>
<br />
<br />
<spinner *ngIf="loading"></spinner>
