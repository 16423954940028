import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'fabbutton',
    templateUrl: './fabbutton.component.html',
    styleUrls: ['./fabbutton.component.scss'],
})
export class FabbuttonComponent implements OnInit {
    @Input() icon: any;
    @Input() bottom: any = "12px";
    @Input() right: any = "12px";
    @Input() color:any = "var(--color-primary)";

    constructor() {}

    ngOnInit(): void {}
}
