<div id="picker">
    <div class="header" table="true" style="--cs: 1fr 50px">
        <span>Novo Usuário</span>
        <mat-icon (click)="close()">close</mat-icon>
    </div>
    <div class="struct">
        <div table="true" style="--cs: 120px 1fr">
            <div table="true" style="--cs: 1fr; --rs: 20px 50px">
                <div>ID</div>
                <div class="input">
                    <input disabled [(ngModel)]="usuario.id" />
                </div>
            </div>
            <div table="true" style="--cs: 1fr; --rs: 20px 50px">
                <div>
                    Usuário
                    <span *ngIf="!usuario.dsuser" class="errorlabel">
                        (Usuário é campo obrigatório!)</span
                    >
                </div>
                <div class="input">
                    <input #dsuser [(ngModel)]="usuario.dsuser" />
                </div>
            </div>
        </div>
        <br />
        <div table="true" style="--cs: 120px 1fr">
            <div table="true" style="--cs: 1fr; --rs: 20px 50px">
                <div>Cód. Solicitante</div>
                <div class="input">
                    <input [(ngModel)]="usuario.idsolicitante" (keyup.f2)="buscasolicitante()"/>
                </div>
            </div>
            <div table="true" style="--cs: 1fr; --rs: 20px 50px">
                <div>
                    Solicitante
                </div>
                <div class="input">
                    <input disabled [(ngModel)]="dssolicitante" />
                </div>
            </div>
        </div>
        <br />
        <div table="true" style="--cs: 1fr 1fr 1fr">
            <div table="true" style="--cs: 1fr; --rs: 20px 50px">
                <div>
                    Login
                    <span *ngIf="!usuario.login" class="errorlabel">
                        (Login é campo obrigatório!)</span
                    >
                </div>
                <div class="input">
                    <input #login [(ngModel)]="usuario.login" autocomplete="nope"/>
                </div>
            </div>
            <div table="true" style="--cs: 1fr; --rs: 20px 50px">
                <div>
                    Senha
                    <span
                        *ngIf="!usuario.id && !usuario.senha"
                        class="errorlabel"
                    >
                        (Senha é campo obrigatório!)</span
                    >
                </div>
                <div class="input">
                    <input
                        #senha
                        [(ngModel)]="usuario.senha"
                        type="password"
                        autocomplete="new-password"
                    />
                </div>
            </div>
            <div table="true" style="--cs: 1fr; --rs: 20px 50px">
                <div>
                    Repetir senha
                    <span
                        *ngIf="senhac != usuario.senha && usuario.senha"
                        class="errorlabel"
                    >
                        (Senhas não correspondem)</span
                    >
                </div>
                <div class="input">
                    <input
                        #senha2
                        [(ngModel)]="senhac"
                        type="password"
                        autocomplete="new-password"
                    />
                </div>
            </div>
        </div>
        <br />
        <div table="true" style="--cs: 1fr 1fr 1fr">
            <div table="true" style="--cs: 1fr; --rs: 1fr">
                <div class="space">
                    <mat-slide-toggle [(ngModel)]="isadm"
                        >Administrador</mat-slide-toggle
                    >
                </div>
            </div>
            <div table="true" style="--cs: 1fr; --rs: 1fr">
                <div class="space">
                    <mat-slide-toggle [(ngModel)]="isativo"
                        >Ativo</mat-slide-toggle
                    >
                </div>
            </div>
            <div table="true" style="--cs: 1fr; --rs: 20px 50px">
                <div>Saldo de Laudos</div>
                <div class="input">
                    <input
                        #saldo
                        [(ngModel)]="usuario.saldo"
                        type="number"
                        min="0"
                    />
                </div>
            </div>
        </div>
        <br />
        <br />
        <div table="true" style="--cs: 1fr 1fr 1fr">
            <div></div>
            <div
                color="sucess"
                class="button btred"
                table="true"
                (click)="close()"
            >
                <mat-icon aria-hidden="false">close</mat-icon>
                <span>Cancelar</span>
            </div>
            <div
                color="sucess"
                class="button btgreen"
                table="true"
                (click)="salvar()"
            >
                <mat-icon aria-hidden="false">done</mat-icon>
                <span>Salvar</span>
            </div>
        </div>
    </div>
</div>
<spinner *ngIf="loading"></spinner>
