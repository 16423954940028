<div id="search">
    <div *ngIf="data.length > 0">
        <div class="label">Pesquisa</div>
        <div class="input">
            <input (keyup)="search()" [(ngModel)]="term" />
        </div>
    </div>
    <div class="table">
        <div class="toptable" table="true" style="--cs: 120px 1fr 1fr">
            <div class="label">ID</div>
            <div class="label">Usuário</div>
            <div class="label">Login</div>
        </div>
        <div
            class="bodytable"
            table="true"
            style="--cs: 120px 1fr 1fr"
            *ngFor="let item of list"
            (click)="select(item)"
        >
            <div class="label">
                {{ item.id }}
            </div>
            <div class="label">
                {{ item.dsuser }}
            </div>
            <div class="label">
                {{ item.login }}
            </div>
        </div>
    </div>
</div>
