
import { Generic } from "./generic";

export class User extends Generic {
    id: string;
    dsuser: string;
    login: string;
    senha: string;
    hascode: string;
    ismaster: string;
    isativo: string;
    saldo: string;
    image: string;
    idsolicitante: string;
    dtatualizacao: string;
}