import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getkeyvalue',
})
export class KeyvaluePipe implements PipeTransform {
    transform(value: unknown, ...args: unknown[]): unknown {
        return Object.keys(value).map((key) => {
            return { key: key, value: value[key] };
        });
    }
}
