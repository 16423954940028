import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { bbf_theme } from '../theme/theme_graph';
import * as moment from 'moment';

am4core.useTheme(am4themes_animated);

@Component({
    selector: 'percentchart',
    templateUrl: './percentchart1.component.html',
    styleUrls: ['./percentchart1.component.scss'],
})
export class Percentchart1Component implements OnInit {
    @Input() data: any = [
        {
            year: '1995',
            cars: 1567,
            motorcycles: 683,
            bicycles: 146,
        },
        {
            year: '1996',
            cars: 1617,
            motorcycles: 691,
            bicycles: 138,
        },
        {
            year: '1997',
            cars: 1630,
            motorcycles: 642,
            bicycles: 127,
        },
        {
            year: '1998',
            cars: 1660,
            motorcycles: 699,
            bicycles: 105,
        },
        {
            year: '1999',
            cars: 1683,
            motorcycles: 721,
            bicycles: 109,
        },
        {
            year: '2000',
            cars: 1691,
            motorcycles: 737,
            bicycles: 112,
        },
        {
            year: '2001',
            cars: 1298,
            motorcycles: 680,
            bicycles: 101,
        },
        {
            year: '2002',
            cars: 1275,
            motorcycles: 664,
            bicycles: 97,
        },
        {
            year: '2003',
            cars: 1246,
            motorcycles: 648,
            bicycles: 93,
        },
        {
            year: '2004',
            cars: 1218,
            motorcycles: 637,
            bicycles: 101,
        },
        {
            year: '2005',
            cars: 1213,
            motorcycles: 633,
            bicycles: 87,
        },
        {
            year: '2006',
            cars: 1199,
            motorcycles: 621,
            bicycles: 79,
        },
        {
            year: '2007',
            cars: 1110,
            motorcycles: 210,
            bicycles: 81,
        },
        {
            year: '2008',
            cars: 1165,
            motorcycles: 232,
            bicycles: 75,
        },
        {
            year: '2009',
            cars: 1145,
            motorcycles: 219,
            bicycles: 88,
        },
        {
            year: '2010',
            cars: 1163,
            motorcycles: 201,
            bicycles: 82,
        },
        {
            year: '2011',
            cars: 1180,
            motorcycles: 285,
            bicycles: 87,
        },
        {
            year: '2012',
            cars: 1159,
            motorcycles: 277,
            bicycles: 71,
        },
    ];

    @Input() id: any = 'linechart' + moment().valueOf();
    @Input() labels: any = [];
    @Input() title: string;
    @Input() titlex: string;
    @Input() titley: string;
    @Input() tension: number = 1;
    @Input() legendpos: am4charts.LegendPosition = 'bottom';
    @Output() output: EventEmitter<any> = new EventEmitter();
    @Input() steped: boolean = false;
    private chart: any;

    constructor() {}

    ngOnDestroy() {
        this.chart.dispose();
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.create();
        }, 300);
    }

    create() {
        this.chart = am4core.create('chartdiv', am4charts.XYChart);
        this.chart.colors.step = 2;

        this.chart.data = this.data;

        let categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = 'year';
        categoryAxis.title.text = 'Year';
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 20;

        categoryAxis.startLocation = 0.5;
        categoryAxis.endLocation = 0.5;

        let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.title.text = 'Percent';
        valueAxis.calculateTotals = true;
        valueAxis.min = 0;
        valueAxis.max = 100;
        valueAxis.strictMinMax = true;
        valueAxis.renderer.labels.template.adapter.add('text', function (text) {
            return text + '%';
        });
        let i = 0;
        for (let l of this.labels.reverse()) {
            this.generateSerie(this.chart, 'x', l, i);
            i++;
        }
        // Legend
        this.chart.legend = new am4charts.Legend();
        this.chart.legend.fontSize = 12;
        this.chart.legend.position = this.legendpos;
        this.chart.legend.marginBottom = 20;
        this.chart.legend.valueLabels.template.fontSize = 12;
        this.chart.cursor = new am4charts.XYCursor();
        this.chart.scrollbarY = new am4core.Scrollbar();
        this.chart.scrollbarX = new am4core.Scrollbar();
        this.chart.numberFormatter.numberFormat = '#.###,##';
        this.chart.exporting.menu = new am4core.ExportMenu();
        this.chart.exporting.menu.align = 'left';
        this.chart.exporting.menu.verticalAlign = 'top';
        this.chart.exporting.title = this.title;
        this.chart.exporting.menu.items = [
            {
                label: '...',
                menu: [
                    { type: 'png', label: 'PNG' },
                    { type: 'print', label: 'Imprimir' },
                ],
            },
        ];
        if (this.title) {
            let title = this.chart.titles.create();
            title.text = this.title;
            title.fontSize = 16;
            title.marginTop = 12;
            title.marginBottom = 12;
        }
        // this.chart.events.on("ready", () => {
        //   axisX.zoomToCategories(
        //     this.data[this.data.length - 50]['x'],
        //     this.data[this.data.length - 1]['x']
        //   );
        // });
        this.chart.scrollbarX.exportable = false;
        this.chart.scrollbarY.exportable = false;
        this.chart.events.on('ready', () => {
            setTimeout(() => {
                this.chart.exporting.getImage('jpg').then((res) => {
                    this.output.emit(res);
                });
            }, 2000);
        });
    }

    generateSerie(chart, x, y, color) {
        let series;
        series = this.chart.series.push(new am4charts.LineSeries());
        series.name = y;
        series.dataFields.valueY = y;
        series.dataFields.valueYShow = "totalPercent";
        series.dataFields.categoryX = x;
        series.tooltipText = '{value} %';
        series.tooltip.pointerOrientation = 'vertical';
        series.stacked = true;
        series.fillOpacity = 0.85;
    }
}
