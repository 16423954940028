import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'buscaconsultor',
  templateUrl: './buscaconsultor.component.html',
  styleUrls: ['./buscaconsultor.component.scss']
})
export class BuscaconsultorComponent implements OnInit {
  @Input() data: any = [];
  @Output() sign = new EventEmitter();
  public term: string;
  public list: any = [];

  constructor() {}

  ngOnInit(): void {
      this.list = this.data;
  }

  public select(item) {
      this.sign.emit(item);
  }

  public search() {
      if (this.term) {
          this.list = this.data.filter(
              (item) =>
                  String(item.dsconsultor)
                      .toUpperCase()
                      .indexOf(String(this.term).toUpperCase()) > -1
          );
      } else {
          this.list = this.data;
      }
  }
}
