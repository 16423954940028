import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/class/user';
import { NovousuarioComponent } from 'src/app/register/novousuario/novousuario.component';
import { ConnectionService } from 'src/app/services/connection.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-listusuarios',
  templateUrl: './listusuarios.component.html',
  styleUrls: ['./listusuarios.component.scss']
})
export class ListusuariosComponent implements OnInit {
  public loading: boolean = false
  public usuarios: User[] = []
  constructor(
    private conn: ConnectionService,
    private util: UtilService
  ) {

  }

  ngOnInit(): void {
    this.carregar() 
  }

  async carregar(){
    this.loading = true
    this.usuarios = []
    this.usuarios = User.fromJsonArray(await this.conn.getUsers().toPromise())
    this.loading = false
  }

  async edit(item){
    this.util.dialog(NovousuarioComponent,item).then(()=>{
      this.carregar()
    })
  }

}
