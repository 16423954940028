<h2 class="c">
    Inserção de dados das análises de extrato de saturação e análise química
    convencional (Preenchimento Obrigatório)
</h2>
<div class="section">Dados da Análise (Química Convencional Completa)</div>
<br />
<div class="table">
    <div class="toptable">
        <div class="label c">cmolc/dm3</div>
    </div>
    <div class="table_items">
        <div class="item">
            <div class="toptable">
                <div class="label c">Ca</div>
            </div>
            <div class="bodytable">
                <div class="inputtab">
                    <input
                        #ca1
                        [(ngModel)]="laudo.ca1"
                        type="number"
                        min="0"
                        [disabled]="laudo.id"
                    />
                </div>
            </div>
        </div>
        <div class="item">
            <div class="toptable">
                <div class="label c">Mg</div>
            </div>
            <div class="bodytable">
                <div class="inputtab">
                    <input
                        #mg1
                        [(ngModel)]="laudo.mg1"
                        type="number"
                        min="0"
                        [disabled]="laudo.id"
                    />
                </div>
            </div>
        </div>
        <div class="item">
            <div class="toptable">
                <div class="label c">K</div>
            </div>
            <div class="bodytable">
                <div class="inputtab">
                    <input
                        #k1
                        [(ngModel)]="laudo.k1"
                        type="number"
                        min="0"
                        [disabled]="laudo.id"
                    />
                </div>
            </div>
        </div>
        <div class="item">
            <div class="toptable">
                <div class="label c">Al</div>
            </div>
            <div class="bodytable">
                <div class="inputtab">
                    <input
                        #al1
                        [(ngModel)]="laudo.al1"
                        type="number"
                        min="0"
                        [disabled]="laudo.id"
                    />
                </div>
            </div>
        </div>
        <div class="item">
            <div class="toptable">
                <div class="label c">CTC</div>
            </div>
            <div class="bodytable">
                <div class="inputtab">
                    <input
                        #ctc1
                        [(ngModel)]="laudo.ctc1"
                        type="number"
                        min="0"
                        [disabled]="laudo.id"
                    />
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="toptable" table="true" style="--cs: repeat(5, 1fr)">
        <div class="label c">Mg</div>
        <div class="label c">K</div>
        <div class="label c">Al</div>
        <div class="label c">CTC</div>
    </div> -->
    <!-- <div class="bodytable" table="true" style="--cs: repeat(5, 1fr)">
        <div class="inputtab">
            <input
                #mg1
                [(ngModel)]="laudo.mg1"
                type="number"
                min="0"
                [disabled]="laudo.id"
            />
        </div>
        <div class="inputtab">
            <input
                #k1
                [(ngModel)]="laudo.k1"
                type="number"
                min="0"
                [disabled]="laudo.id"
            />
        </div>
        <div class="inputtab">
            <input
                #al1
                [(ngModel)]="laudo.al1"
                type="number"
                min="0"
                [disabled]="laudo.id"
            />
        </div>
        <div class="inputtab">
            <input
                #ctc1
                [(ngModel)]="laudo.ctc1"
                type="number"
                min="0"
                [disabled]="laudo.id"
            />
        </div>
    </div> -->
    <br />
    <br />

    <div class="toptable">
        <div class="label c">mg/dm3</div>
    </div>

    <div class="table_items">
        <div class="item">
            <div class="toptable">
                <div class="label c">P (mehlich)</div>
            </div>
            <div class="bodytable">
                <div class="inputtab">
                    <input
                        #p2
                        [(ngModel)]="laudo.p2"
                        type="number"
                        min="0"
                        [disabled]="laudo.id"
                    />
                </div>
            </div>
        </div>
        <div class="item">
            <div class="toptable">
                <div class="label c">P-rem</div>
            </div>
            <div class="bodytable">
                <div class="inputtab">
                    <input
                        #prem2
                        [(ngModel)]="laudo.prem2"
                        type="number"
                        min="0"
                        [disabled]="laudo.id"
                    />
                </div>
            </div>
        </div>
        <div class="item">
            <div class="toptable">
                <div class="label c">S</div>
            </div>
            <div class="bodytable">
                <div class="inputtab">
                    <input
                        #s2
                        [(ngModel)]="laudo.s2"
                        type="number"
                        min="0"
                        [disabled]="laudo.id"
                    />
                </div>
            </div>
        </div>
        <div class="item">
            <div class="toptable">
                <div class="label c">Fe</div>
            </div>
            <div class="bodytable">
                <div class="inputtab">
                    <input
                        #fe2
                        [(ngModel)]="laudo.fe2"
                        type="number"
                        min="0"
                        [disabled]="laudo.id"
                    />
                </div>
            </div>
        </div>
        <div class="item">
            <div class="toptable">
                <div class="label c">Mn</div>
            </div>
            <div class="bodytable">
                <div class="inputtab">
                    <input
                        #mn2
                        [(ngModel)]="laudo.mn2"
                        type="number"
                        min="0"
                        [disabled]="laudo.id"
                    />
                </div>
            </div>
        </div>
        <div class="item">
            <div class="toptable">
                <div class="label c">Cu</div>
            </div>
            <div class="bodytable">
                <div class="inputtab">
                    <input
                        #cu2
                        [(ngModel)]="laudo.cu2"
                        type="number"
                        min="0"
                        [disabled]="laudo.id"
                    />
                </div>
            </div>
        </div>
        <div class="item">
            <div class="toptable">
                <div class="label c">Zn</div>
            </div>
            <div class="bodytable">
                <div class="inputtab">
                    <input
                        #zn2
                        [(ngModel)]="laudo.zn2"
                        type="number"
                        min="0"
                        [disabled]="laudo.id"
                    />
                </div>
            </div>
        </div>
        <div class="item">
            <div class="toptable">
                <div class="label c">B</div>
            </div>
            <div class="bodytable">
                <div class="inputtab">
                    <input
                        #b2
                        [(ngModel)]="laudo.b2"
                        type="number"
                        min="0"
                        [disabled]="laudo.id"
                    />
                </div>
            </div>
        </div>
    </div>


    <!-- <div class="toptable" table="true" style="--cs: repeat(8, 1fr)">
        <div class="label c">P (mehlich)</div>
        <div class="label c">P-rem</div>
        <div class="label c">S</div>
        <div class="label c">Fe</div>
        <div class="label c">Mn</div>
        <div class="label c">Cu</div>
        <div class="label c">Zn</div>
        <div class="label c">B</div>
    </div> -->
    <!-- <div class="bodytable" table="true" style="--cs: repeat(8, 1fr)">
        <div class="inputtab">
            <input
                #p2
                [(ngModel)]="laudo.p2"
                type="number"
                min="0"
                [disabled]="laudo.id"
            />
        </div>
        <div class="inputtab">
            <input
                #prem2
                [(ngModel)]="laudo.prem2"
                type="number"
                min="0"
                [disabled]="laudo.id"
            />
        </div>
        <div class="inputtab">
            <input
                #s2
                [(ngModel)]="laudo.s2"
                type="number"
                min="0"
                [disabled]="laudo.id"
            />
        </div>
        <div class="inputtab">
            <input
                #fe2
                [(ngModel)]="laudo.fe2"
                type="number"
                min="0"
                [disabled]="laudo.id"
            />
        </div>
        <div class="inputtab">
            <input
                #mn2
                [(ngModel)]="laudo.mn2"
                type="number"
                min="0"
                [disabled]="laudo.id"
            />
        </div>
        <div class="inputtab">
            <input
                #cu2
                [(ngModel)]="laudo.cu2"
                type="number"
                min="0"
                [disabled]="laudo.id"
            />
        </div>
        <div class="inputtab">
            <input
                #zn2
                [(ngModel)]="laudo.zn2"
                type="number"
                min="0"
                [disabled]="laudo.id"
            />
        </div>
        <div class="inputtab">
            <input
                #b2
                [(ngModel)]="laudo.b2"
                type="number"
                min="0"
                [disabled]="laudo.id"
            />
        </div>
    </div> -->

    <br />
    <br />
    <div class="section">Dados da Análise (Extrato de Saturação)</div>
    <div class="toptable">
        <div class="label c">mg/L</div>
    </div>

    <div class="table_items">
        <div class="item">
            <div class="toptable">
                <div class="label c">Ca</div>
            </div>
            <div class="bodytable">
                <div class="inputtab">
                    <input
                        #ca3
                        [(ngModel)]="laudo.ca3"
                        type="number"
                        min="0"
                        [disabled]="laudo.id"
                    />
                </div>
            </div>
        </div>
        <div class="item">
            <div class="toptable">
                <div class="label c">Mg</div>
            </div>
            <div class="bodytable">
                <div class="inputtab">
                    <input
                        #mg3
                        [(ngModel)]="laudo.mg3"
                        type="number"
                        min="0"
                        [disabled]="laudo.id"
                    />
                </div>
            </div>
        </div>
        <div class="item">
            <div class="toptable">
                <div class="label c">K</div>
            </div>
            <div class="bodytable">
                <div class="inputtab">
                    <input
                        #k3
                        [(ngModel)]="laudo.k3"
                        type="number"
                        min="0"
                        [disabled]="laudo.id"
                    />
                </div>
            </div>
        </div>
        <div class="item">
            <div class="toptable">
                <div class="label c">P</div>
            </div>
            <div class="bodytable">
                <div class="inputtab">
                    <input
                        #p3
                        [(ngModel)]="laudo.p3"
                        type="number"
                        min="0"
                        [disabled]="laudo.id"
                    />
                </div>
            </div>
        </div>
        <div class="item">
            <div class="toptable">
                <div class="label c">S</div>
            </div>
            <div class="bodytable">
                <div class="inputtab">
                    <input
                        #s3
                        [(ngModel)]="laudo.s3"
                        type="number"
                        min="0"
                        [disabled]="laudo.id"
                    />
                </div>
            </div>
        </div>
        <div class="item">
            <div class="toptable">
                <div class="label c">Fe</div>
            </div>
            <div class="bodytable">
                <div class="inputtab">
                    <input
                        #fe3
                        [(ngModel)]="laudo.fe3"
                        type="number"
                        min="0"
                        [disabled]="laudo.id"
                    />
                </div>
            </div>
        </div>
        <div class="item">
            <div class="toptable">
                <div class="label c">Mn</div>
            </div>
            <div class="bodytable">
                <div class="inputtab">
                    <input
                        #mn3
                        [(ngModel)]="laudo.mn3"
                        type="number"
                        min="0"
                        [disabled]="laudo.id"
                    />
                </div>
            </div>
        </div>
        <div class="item">
            <div class="toptable">
                <div class="label c">Cu</div>
            </div>
            <div class="bodytable">
                <div class="inputtab">
                    <input
                        #cu3
                        [(ngModel)]="laudo.cu3"
                        type="number"
                        min="0"
                        [disabled]="laudo.id"
                    />
                </div>
            </div>
        </div>
        <div class="item">
            <div class="toptable">
                <div class="label c">Zn</div>
            </div>
            <div class="bodytable">
                <div class="inputtab">
                    <input
                        #zn3
                        [(ngModel)]="laudo.zn3"
                        type="number"
                        min="0"
                        [disabled]="laudo.id"
                    />
                </div>
            </div>
        </div>
        <div class="item">
            <div class="toptable">
                <div class="label c">B</div>
            </div>
            <div class="bodytable">
                <div class="inputtab">
                    <input
                        #b3
                        [(ngModel)]="laudo.b3"
                        type="number"
                        min="0"
                        [disabled]="laudo.id"
                    />
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="toptable" table="true" style="--cs: repeat(10, 1fr)">
        <div class="label c">Ca</div>
        <div class="label c">Mg</div>
        <div class="label c">K</div>
        <div class="label c">P</div>
        <div class="label c">S</div>
        <div class="label c">Fe</div>
        <div class="label c">Mn</div>
        <div class="label c">Cu</div>
        <div class="label c">Zn</div>
        <div class="label c">B</div>
    </div>
    <div class="bodytable" table="true" style="--cs: repeat(10, 1fr)">
        <div class="inputtab">
            <input
                #ca3
                [(ngModel)]="laudo.ca3"
                type="number"
                min="0"
                [disabled]="laudo.id"
            />
        </div>
        <div class="inputtab">
            <input
                #mg3
                [(ngModel)]="laudo.mg3"
                type="number"
                min="0"
                [disabled]="laudo.id"
            />
        </div>
        <div class="inputtab">
            <input
                #k3
                [(ngModel)]="laudo.k3"
                type="number"
                min="0"
                [disabled]="laudo.id"
            />
        </div>
        <div class="inputtab">
            <input
                #p3
                [(ngModel)]="laudo.p3"
                type="number"
                min="0"
                [disabled]="laudo.id"
            />
        </div>
        <div class="inputtab">
            <input
                #s3
                [(ngModel)]="laudo.s3"
                type="number"
                min="0"
                [disabled]="laudo.id"
            />
        </div>
        <div class="inputtab">
            <input
                #fe3
                [(ngModel)]="laudo.fe3"
                type="number"
                min="0"
                [disabled]="laudo.id"
            />
        </div>
        <div class="inputtab">
            <input
                #mn3
                [(ngModel)]="laudo.mn3"
                type="number"
                min="0"
                [disabled]="laudo.id"
            />
        </div>
        <div class="inputtab">
            <input
                #cu3
                [(ngModel)]="laudo.cu3"
                type="number"
                min="0"
                [disabled]="laudo.id"
            />
        </div>
        <div class="inputtab">
            <input
                #zn3
                [(ngModel)]="laudo.zn3"
                type="number"
                min="0"
                [disabled]="laudo.id"
            />
        </div>
        <div class="inputtab">
            <input
                #b3
                [(ngModel)]="laudo.b3"
                type="number"
                min="0"
                [disabled]="laudo.id"
            />
        </div>
    </div> -->
</div>
<br />
<div table="true" style="--cs: 1fr">
    <div table="true" style="--cs: 1fr 1fr 1fr 1fr; --rs: 1fr; column-gap: 4px">
        <!-- <div color="sucess" class="button btred" table=true>
            <mat-icon aria-hidden="false">close</mat-icon>
            <span>Cancelar</span>
        </div> -->
        <!-- <div color="sucess" class="button btdark" table=true (click)="calc()">
            <mat-icon aria-hidden="false">calculate</mat-icon>
            <span>Calcular</span>
        </div> -->
        <div></div>
        <div></div>
        <!-- <div color="sucess" class="button btdark" table=true (click)="go()" *ngIf="util.isMaster()">
            <mat-icon aria-hidden="false">calculate</mat-icon>
            <span>Calcular</span>
        </div> -->
        <div color="sucess" class="button btblue" table="true" (click)="back()">
            <mat-icon aria-hidden="false">replay</mat-icon>
            <span>Voltar</span>
        </div>
        <div
            color="sucess"
            class="button btgreen"
            table="true"
            (click)="calc()"
        >
            <mat-icon aria-hidden="false">save</mat-icon>
            <span>Gerar laudo</span>
        </div>
    </div>
</div>
<br />
<br />
<spinner *ngIf="waiting"></spinner>
