import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilService } from './util.service';
import { Laudo } from '../class/laudo';
import { Cliente } from '../class/cliente';
import { Solicitante } from '../class/solicitante';
import { Cultura } from '../class/cultura';
import * as moment from 'moment';
import { User } from '../class/user';
import { environment } from 'src/environments/environment';
import { Consultor } from '../class/consultor';
import { LaudoPlanta } from '../class/laudoplanta';

@Injectable({
    providedIn: 'root',
})
export class ConnectionService {
    public _URL = environment.URL_BACK_END;

    constructor(private http: HttpClient, private util: UtilService) {}

    public login(login, senha) {
        let body = {
            login: login,
            senha: senha,
        };
        return this.http.post(this._URL + `login`, body);
    }

    public getCultura() {
        return this.http.get(
            this._URL + `cultura?hascode=${this.util.getHascode()}`
        );
    }

    public setCultura(cultura: Cultura) {
        if (cultura.id) {
            return this.http.put(
                this._URL + `cultura?hascode=${this.util.getHascode()}`,
                cultura
            );
        }
        return this.http.post(
            this._URL + `cultura?hascode=${this.util.getHascode()}`,
            cultura
        );
    }

    public getCliente() {
        return this.http.get(
            this._URL + `cliente?hascode=${this.util.getHascode()}`
        );
    }

    public setCliente(cliente: Cliente) {
        if (cliente.id) {
            return this.http.put(
                this._URL + `cliente?hascode=${this.util.getHascode()}`,
                cliente
            );
        }
        return this.http.post(
            this._URL + `cliente?hascode=${this.util.getHascode()}`,
            cliente
        );
    }

    public getSolicitante() {
        return this.http.get(
            this._URL + `solicitante?hascode=${this.util.getHascode()}`
        );
    }

    public setSolicitante(solicitante: Solicitante) {
        if (solicitante.id) {
            return this.http.put(
                this._URL + `solicitante?hascode=${this.util.getHascode()}`,
                solicitante
            );
        }
        return this.http.post(
            this._URL + `solicitante?hascode=${this.util.getHascode()}`,
            solicitante
        );
    }

    public getConsultor() {
        return this.http.get(
            this._URL + `consultor?hascode=${this.util.getHascode()}`
        );
    }

    public setConsultor(consultor: Consultor) {
        if (consultor.id) {
            return this.http.put(
                this._URL + `consultor?hascode=${this.util.getHascode()}`,
                consultor
            );
        }
        return this.http.post(
            this._URL + `consultor?hascode=${this.util.getHascode()}`,
            consultor
        );
    }

    public getLaudos(params = null) {
        if (params)
            return this.http.get(
                this._URL + `laudo?hascode=${this.util.getHascode()}&${params}`
            );
        return this.http.get(
            this._URL + `laudo?hascode=${this.util.getHascode()}`
        );
    }

    public inactivateLaudo(id) {
        return this.http.delete(
            this._URL + `laudo?hascode=${this.util.getHascode()}&id=${id}`
        );
    }

    public sendLaudo(laudo: Laudo) {
        if (laudo.id && !this.util.force_novo) {
            return this.http.put(
                this._URL + `laudo?hascode=${this.util.getHascode()}`,
                laudo.toJson()
            );
        }
        this.util.force_novo = false;
        laudo.id = null;
        return this.http.post(
            this._URL + `laudo?hascode=${this.util.getHascode()}`,
            laudo.toJson()
        );
    }

    public calcular(laudo: Laudo) {
        return this.http.post(
            this._URL + `calcular?hascode=${this.util.getHascode()}`,
            laudo.toJson()
        );
    }

    public getSaldo() {
        return this.http.get(
            this._URL + `laudo/saldo?hascode=${this.util.getHascode()}`
        );
    }

    public getImage() {
        return this.http.get(
            this._URL + `user/image?hascode=${this.util.getHascode()}`
        );
    }

    public setImage(base64) {
        let body = {
            image: base64,
        };
        return this.http.post(
            this._URL + `user/image?hascode=${this.util.getHascode()}`,
            body
        );
    }

    public async saveLaudo(laudooriginal: Laudo | any) {
        try {
            let laudo = laudooriginal;
            laudo.dtlaudo = moment().format('YYYY-MM-DD HH:mm:ss');
            laudo.hascode = this.util.getHascode();
            laudo.iduser = this.util.getIdUser();
            console.log(laudo);
            console.log(JSON.stringify(laudo.toJson()));

            let res: any = await this.sendLaudo(laudo).toPromise();
            let sld = await this.getSaldo().toPromise();
            // console.log('sld:');
            // console.log(sld);

            if (Object.keys(sld).indexOf('saldo') > -1) {
                this.util.setSaldoLaudos(sld['saldo']);
            }
            console.log(res);
            return res;
            // if (res ? res.length > 0 : false) {
            //     if (res[0].id) {
            //         laudo.id = res[0].id;
            //         return laudo;
            //     }
            // }
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    public async calcLaudo(laudooriginal) {
        try {
            let laudo = laudooriginal;
            // laudo.isinfiltracao = laudooriginal.isinfiltracao ? "1" : "0"
            // laudo.issegunda = laudooriginal.issegunda ? "1" : "0"
            laudo.dtlaudo = moment().format('YYYY-MM-DD HH:mm:ss');
            laudo.hascode = this.util.getHascode();
            console.log(JSON.stringify(laudo));
            let res = await this.calcular(laudo).toPromise();
            console.table(res);
            if (res ? res['error'] : false) {
                this.util.alertDanger(
                    'Erro de cálculo, verifique os dados e tente novamente!'
                );
                return null;
            }
            return res;
        } catch {
            return null;
        }
    }

    public getUsers() {
        return this.http.get(
            this._URL + `user?hascode=${this.util.getHascode()}`
        );
    }

    public setUser(user: User) {
        if (user.id) {
            return this.http.put(
                this._URL + `user?hascode=${this.util.getHascode()}`,
                user
            );
        }
        return this.http.post(
            this._URL + `user?hascode=${this.util.getHascode()}`,
            user
        );
    }

    /** FOLHA */

    public getLaudoPlanta() {
        return this.http
            .get(`${this._URL}laudofolha?hascode=${this.util.getHascode()}`)
            .toPromise();
    }

    public getLaudoPlantaSearch(form) {
        return this.http
            .post(
                `${
                    this._URL
                }laudofolha/search?hascode=${this.util.getHascode()}`,
                form
            )
            .toPromise();
    }

    public getLaudoPlantaById(id) {
        return this.http
            .get(
                `${
                    this._URL
                }laudofolha?hascode=${this.util.getHascode()}&idlaudo=${id}`
            )
            .toPromise();
    }

    public saveLaudoPlanta(laudo: LaudoPlanta) {
        return this.http
            .post(
                `${this._URL}laudofolha?hascode=${this.util.getHascode()}`,
                laudo
            )
            .toPromise();
    }

    public saveObservaçãoLaudoPlanta(idlaudo, observacao) {
        console.log(
            `${
                this._URL
            }/laudofolha/observacoes?hascode=${this.util.getHascode()}&idlaudo=${idlaudo}`
        );

        return this.http
            .post(
                `${
                    this._URL
                }/laudofolha/observacoes?hascode=${this.util.getHascode()}&idlaudo=${idlaudo}`,
                { observacoes: observacao }
            )
            .toPromise();
    }

    public getVersion() {
        return this.http
            .get("/assets/version.json")
            .toPromise();
    }
}

//teste