import { Injectable, Input } from '@angular/core';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;
import { top, topplant } from './images';

type Alignment = 'left' | 'rigth' | 'center';

@Injectable({
    providedIn: 'root',
})
export class PdfgenerateService {
    @Input() values: any = {};
    @Input() image: any = '';
    @Input() margin: any = 20;
    public tam = 500;
    public hg = 160;
    public wg = 260;

    constructor() {}

    async generatePdfFolha(calculo) {
        let img = await this.mergeHeaderPlanta();
        const documentDefinition = {
            pageSize: 'A4',
            pageMargins: [this.margin, 40, this.margin, 20],
            header: (currentPage, pageCount, pageSize) => {
                return [
                    {
                        image: img,
                        width: this.tam,
                    },
                ];
            },
            footer: {
                text: 'BBF AGROSCIENCE | Rua Eloi Maciel, 384 | Parque Industrial 2 | Campo Mourão - PR | CEP.: 87306-640',
                fontSize: 9,
                bold: false,
                alignment: 'center',
                margin: [0, 2, 0, 2],
            },
            content: [
                this.linebreak(),
                this.linebreak(),
                this.T('Detalhes da Análise', 'header2'),
                this.separator(),
                this.Tform4Folha(
                    'Proprietário:',
                    calculo.laudo.dscliente,
                    'Talhão:',
                    calculo.laudo.talhao
                ),
                this.Tform4Folha(
                    'Solicitante:',
                    calculo.laudo.dssolicitante,
                    'Data:',
                    calculo.laudo.dtlaudo
                ),
                this.Tform4Folha(
                    'Cultura:',
                    calculo.laudo.dscultura,
                    'Dias:',
                    calculo.laudo.dias
                ),
                this.Tform4Folha(
                    'Município:',
                    calculo.laudo.municipio,
                    'Estado:',
                    calculo.laudo.estado
                ),
                this.breaklinemin(),
                this.T('Tecido Vegetal', 'header2'),
                this.separator(),
                {
                    columns: [
                        {
                            width: '*',
                            stack: [
                                this.T(
                                    'Nutriente/Matéria Seca (%)',
                                    'subheader'
                                ),
                                {
                                    image: calculo.charts
                                        .chartmateriaorganicanutriente,
                                    width: this.wg + 19,
                                    height: this.hg + 30,
                                    style: 'imgstyle',
                                },
                            ],
                        },
                        {
                            width: '*',
                            stack: [
                                this.T('Equilíbrio Nutricional', 'subheader'),
                                {
                                    image: calculo.charts
                                        .chartsuficiencianutricional,
                                    width: this.wg + 19,
                                    height: this.hg + 30,
                                    style: 'imgstyle',
                                },
                            ],
                        },
                    ],
                },
                this.T('Dados Nutricionais', 'header2'),
                {
                    table: {
                        headerRows: 0,
                        widths: ['52%', '48%'],
                        style: 'tableBody',
                        fontSize: 9,
                        body: [
                            [
                                this.Th('Macronutrientes kg/ha'),
                                this.Th('Micronutrientes g/ha'),
                            ],
                        ],
                    },
                    layout: this.customTable([0], [0, 1, 2]),
                },
                {
                    table: {
                        headerRows: 0,
                        widths: [
                            '8%',
                            '10%',
                            '10%',
                            '8%',
                            '8%',
                            '8%',
                            '8%',
                            '8%',
                            '8%',
                            '8%',
                            '8%',
                            '8%',
                        ],
                        style: 'tableBody',
                        fontSize: 9,
                        body: [
                            [
                                this.Th('N'),
                                this.Tt([
                                    this.Th('P'),
                                    this.Ts('2'),
                                    this.Th('O'),
                                    this.Ts('5'),
                                ]),
                                this.Tt([
                                    this.Th('K'),
                                    this.Ts('2'),
                                    this.Th('O'),
                                ]),
                                this.Th('Ca'),
                                this.Th('Mg'),
                                this.Th('S'),
                                this.Th('Fe'),
                                this.Th('B'),
                                this.Th('Mn'),
                                this.Th('Zn'),
                                this.Th('Cu'),
                                this.Th('Mo'),
                            ],
                            [
                                this.Tc(
                                    calculo.resultado_final_soma_nutrientes.n
                                ),
                                this.Tc(
                                    calculo.resultado_final_soma_nutrientes.p
                                ),
                                this.Tc(
                                    calculo.resultado_final_soma_nutrientes.k
                                ),
                                this.Tc(
                                    calculo.resultado_final_soma_nutrientes.ca
                                ),
                                this.Tc(
                                    calculo.resultado_final_soma_nutrientes.mg
                                ),
                                this.Tc(
                                    calculo.resultado_final_soma_nutrientes.s
                                ),
                                this.Tc(
                                    calculo.resultado_final_soma_nutrientes.fe
                                ),
                                this.Tc(
                                    calculo.resultado_final_soma_nutrientes.b
                                ),
                                this.Tc(
                                    calculo.resultado_final_soma_nutrientes.mn
                                ),
                                this.Tc(
                                    calculo.resultado_final_soma_nutrientes.zn
                                ),
                                this.Tc(
                                    calculo.resultado_final_soma_nutrientes.cu
                                ),
                                this.Tc(
                                    calculo.resultado_final_soma_nutrientes.mo
                                ),
                            ],
                        ],
                    },
                    layout: this.customTable([0, 1, 2], [0, 6, 12]),
                },
                this.breaklinemin(),
                {
                    columns: [
                        {
                            width: '*',
                            stack: [
                                this.T(
                                    'Lei de Liebig - Lei do Barril',
                                    'subheader'
                                ),
                                {
                                    image: calculo.charts
                                        .chartequilibrionutricional,
                                    width: this.wg * 2,
                                    height: this.hg + 24,
                                    style: 'imgstyle',
                                },
                            ],
                        },
                    ],
                },
                // this.breaklinemin(),
                calculo.laudo.observacoes
                    ? {
                          table: {
                              headerRows: 0,
                              widths: ['100%'],
                              style: 'tableBody',
                              fontSize: 9,
                              body: [[this.Th('Recomendações')]],
                          },
                          layout: this.topTable(),
                      }
                    : null,
                this.getObservacoes(calculo.laudo.observacoes, 5),
            ],
            styles: {
                header: {
                    fontSize: 12,
                    bold: true,
                    alignment: 'center',
                    margin: [0, 0, 0, 10],
                },
                tableHeader: {
                    fontSize: 10,
                },
                tableHeaderSub: {
                    fontSize: 6,
                },
                header2: {
                    fontSize: 10,
                    bold: true,
                    alignment: 'center',
                    margin: [0, 2, 0, 2],
                },
                imgstyle: {
                    alignment: 'center',
                },
                breakline: {
                    margin: [4, 4, 4, 4],
                },
                breaklinemin: {
                    fontSize: 8,
                    margin: [1, 1, 1, 1],
                },
                footstl: {
                    fontSize: 10,
                    bold: false,
                    fillColor: '#fefefe',
                    color: '#eeeee',
                    alignment: 'center',
                    margin: [0, 2, 0, 2],
                },
                observacoes: {
                    fontSize: 9,
                    bold: false,
                    color: '#0c0c0c',
                    alignment: 'left',
                    margin: [4, 2, 0, 2],
                },
                subheader: {
                    fontSize: 10,
                    bold: true,
                    alignment: 'center',
                    color: '#3f3f3f',
                    margin: [0, 8, 0, 5],
                },
                tableExample: {
                    margin: [0, 5, 0, 15],
                },
                tableBody: {
                    bold: true,
                    fontSize: 9,
                    color: 'black',
                },
                bodytext: {
                    fontSize: 9,
                    paddingTop: 8,
                    color: 'black',
                },
                bodytextfolha: {
                    fontSize: 11,
                    paddingTop: 4,
                    color: 'black',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                },
                bodytextsmall: {
                    fontSize: 9,
                    color: 'black',
                },
            },
            defaultStyle: {
                // alignment: 'justify'
            },
        };
        (<any>pdfMake).tableLayouts = {
            exampleLayout: {
                hLineWidth: (i, node) => {
                    if (i === 0 || i === node.table.body.length) {
                        return 0;
                    }
                    return i === node.table.headerRows ? 2 : 1;
                },
                vLineWidth: function (i) {
                    return 0;
                },
                hLineColor: function (i) {
                    return i === 1 ? 'black' : '#aaa';
                },
                paddingLeft: function (i) {
                    return i === 0 ? 0 : 8;
                },
                paddingRight: (i, node) => {
                    return i === node.table.widths.length - 1 ? 0 : 8;
                },
            },
        };
        (<any>pdfMake)
            .createPdf(documentDefinition)
            .download(
                `LaudoFolha_${calculo.laudo.dscliente}_${calculo.laudo.dssolicitante}_${calculo.laudo.dias}.pdf`
            );
    }

    async generatePdf() {
        let img = await this.mergeHeader();
        const documentDefinition = {
            pageSize: 'A4',
            pageMargins: [this.margin, 40, this.margin, 20],
            header: (currentPage, pageCount, pageSize) => {
                return [
                    {
                        image: img,
                        width: this.tam,
                    },
                ];
            },
            footer: {
                text: 'BBF AGROSCIENCE | Rua Eloi Maciel, 384 | Parque Industrial 2 | Campo Mourão - PR | CEP.: 87306-640',
                fontSize: 9,
                bold: false,
                alignment: 'center',
                margin: [0, 2, 0, 2],
            },
            content: [
                this.linebreak(),
                this.linebreak(),
                this.T('Detalhes da Análise', 'header2'),
                this.separator(),
                this.Tform4(
                    'Solicitante:',
                    this.values.dssolicitante,
                    'Identificação:',
                    this.values.idamostra
                ),
                this.Tform4(
                    'Proprietário:',
                    this.values.dscliente,
                    'Data:',
                    this.values.data
                ),
                this.Tform4(
                    'Cultivo:',
                    this.values.dscultura,
                    'Produt. Estimada:',
                    this.values.prodestimada + this.unMed(this.values.dscultura)
                ),
                this.values.issegunda == '1'
                    ? this.Tform4(
                          'Cultivo Anterior:',
                          this.values.dsculturaant,
                          'Produt. Anterior:',
                          this.values.produtividadeant +
                              this.unMed(this.values.dsculturaant)
                      )
                    : null,
                this.breaklinemin(),
                this.T('Correção de Solo', 'header2'),
                {
                    table: {
                        headerRows: 0,
                        widths: ['*'],
                        style: 'tableBody',
                        fontSize: 9,
                        body: [[this.Th('kg/ha')]],
                    },
                    layout: this.customTable([0], [0, 1]),
                },
                {
                    table: {
                        headerRows: 1,
                        widths: ['*', '*', '*', '*'],
                        style: 'tableBody',
                        body: [
                            [
                                this.Th('CaO'),
                                this.Th('MgO'),
                                this.Tt([
                                    this.Th('P'),
                                    this.Ts('2'),
                                    this.Th('O'),
                                    this.Ts('5'),
                                ]),
                                this.Tt([
                                    this.Th('K'),
                                    this.Ts('2'),
                                    this.Th('O'),
                                ]),
                            ],
                            [
                                this.Tc(this.values.t16.cao_ha),
                                this.Tc(this.values.t16.mgo_ha),
                                this.Tc(this.values.t16.p2o5_ha),
                                this.Tc(this.values.t16.k2o_ha),
                            ],
                        ],
                    },
                    layout: this.customTable([0, 1, 2], [0, 4]),
                },
                {
                    columns: [
                        {
                            width: '*',
                            stack: [
                                this.T('Correção de Solo', 'subheader'),
                                {
                                    image: this.values.imgcorsolo,
                                    width: this.wg,
                                    height: this.hg,
                                    style: 'imgstyle',
                                },
                            ],
                        },
                        {
                            width: '*',
                            stack: [
                                this.T('Potencial Produtivo', 'subheader'),
                                {
                                    image: this.values.imgpotprod,
                                    width: this.wg - 8,
                                    height: this.hg,
                                    style: 'imgstyle',
                                },
                            ],
                        },
                    ],
                },
                this.T('Limitação e Restituição', 'header2'),
                // {
                //     table: {
                //         headerRows: 0,
                //         widths: ['*', '*'],
                //         style: 'tableBody',
                //         fontSize: 9,
                //         body: [[this.Th('Kg / ha'), this.Th('g / ha')]],
                //     },
                //     layout: this.topTable(),
                // },
                {
                    table: {
                        headerRows: 0,
                        widths: ['*', '*'],
                        style: 'tableBody',
                        fontSize: 9,
                        body: [[this.Th('kg/ha'), this.Th('g/ha')]],
                    },
                    layout: this.customTable([0], [0, 1, 2]),
                },
                {
                    table: {
                        headerRows: 0,
                        widths: [
                            '*',
                            '*',
                            '*',
                            '*',
                            '*',
                            '*',
                            '*',
                            '*',
                            '*',
                            '*',
                        ],
                        style: 'tableBody',
                        fontSize: 9,
                        body: [
                            [
                                this.Th('Ca'),
                                this.Th('Mg'),
                                this.Tt([
                                    this.Th('K'),
                                    this.Ts('2'),
                                    this.Th('O'),
                                ]),
                                this.Tt([
                                    this.Th('P'),
                                    this.Ts('2'),
                                    this.Th('O'),
                                    this.Ts('5'),
                                ]),
                                this.Th('S'),
                                this.Th('Fe'),
                                this.Th('Mn'),
                                this.Th('Cu'),
                                this.Th('Zn'),
                                this.Th('B'),
                            ],
                            [
                                this.Tc(this.values.rr.ca1),
                                this.Tc(this.values.rr.mg1),
                                this.Tc(this.values.rr.k1),
                                this.Tc(this.values.rr.p1),
                                this.Tc(this.values.rr.s1),
                                this.Tc(this.values.rr.fe1),
                                this.Tc(this.values.rr.mn1),
                                this.Tc(this.values.rr.cu1),
                                this.Tc(this.values.rr.zn1),
                                this.Tc(this.values.rr.b1),
                            ],
                        ],
                    },
                    layout: this.customTable([0, 1, 2], [0, 5, 10]),
                },
                {
                    columns: [
                        {
                            width: '*',
                            stack: [
                                this.T('Macronutrientes', 'subheader'),
                                {
                                    image: this.values.imgmacro,
                                    width: this.wg,
                                    height: this.hg,
                                    style: 'imgstyle',
                                },
                            ],
                        },
                        {
                            width: '*',
                            stack: [
                                this.T('Micronutrientes', 'subheader'),
                                {
                                    image: this.values.imgmicro,
                                    width: this.wg,
                                    height: this.hg,
                                    style: 'imgstyle',
                                },
                            ],
                        },
                    ],
                },
                // this.breaklinemin(),
                this.values.observacoes
                    ? {
                          table: {
                              headerRows: 0,
                              widths: ['100%'],
                              style: 'tableBody',
                              fontSize: 9,
                              body: [[this.Th('Recomendações')]],
                          },
                          layout: this.topTable(),
                      }
                    : null,
                this.getObservacoes(this.values.observacoes),
            ],
            styles: {
                header: {
                    fontSize: 12,
                    bold: true,
                    alignment: 'center',
                    margin: [0, 0, 0, 10],
                },
                tableHeader: {
                    fontSize: 10,
                },
                tableHeaderSub: {
                    fontSize: 6,
                },
                header2: {
                    fontSize: 10,
                    bold: true,
                    alignment: 'center',
                    margin: [0, 2, 0, 2],
                },
                imgstyle: {
                    alignment: 'center',
                },
                breakline: {
                    margin: [4, 4, 4, 4],
                },
                breaklinemin: {
                    margin: [1, 1, 1, 1],
                },
                footstl: {
                    fontSize: 10,
                    bold: false,
                    fillColor: '#fefefe',
                    color: '#eeeee',
                    alignment: 'center',
                    margin: [0, 2, 0, 2],
                },
                observacoes: {
                    fontSize: 9,
                    bold: false,
                    color: '#0c0c0c',
                    alignment: 'left',
                    margin: [4, 2, 0, 2],
                },
                subheader: {
                    fontSize: 10,
                    bold: true,
                    alignment: 'center',
                    color: '#3f3f3f',
                    margin: [0, 8, 0, 5],
                },
                tableExample: {
                    margin: [0, 5, 0, 15],
                },
                tableBody: {
                    bold: true,
                    fontSize: 9,
                    color: 'black',
                },
                bodytext: {
                    fontSize: 9,
                    color: 'black',
                },
                bodytextsmall: {
                    fontSize: 9,
                    color: 'black',
                },
            },
            defaultStyle: {
                // alignment: 'justify'
            },
        };
        (<any>pdfMake).tableLayouts = {
            exampleLayout: {
                hLineWidth: (i, node) => {
                    if (i === 0 || i === node.table.body.length) {
                        return 0;
                    }
                    return i === node.table.headerRows ? 2 : 1;
                },
                vLineWidth: function (i) {
                    return 0;
                },
                hLineColor: function (i) {
                    return i === 1 ? 'black' : '#aaa';
                },
                paddingLeft: function (i) {
                    return i === 0 ? 0 : 8;
                },
                paddingRight: (i, node) => {
                    return i === node.table.widths.length - 1 ? 0 : 8;
                },
            },
        };
        (<any>pdfMake)
            .createPdf(documentDefinition)
            .download(
                this.values.dssolicitante +
                    '_' +
                    this.values.dscliente +
                    '_' +
                    this.values.idamostra +
                    '.pdf'
            );
    }

    getObservacoes(observacoes, maxlines = 7) {
        let ob = String(observacoes).split('\n');

        let table = {
            headerRows: 0,
            widths: ['*', '*'],
            body: [],
        };
        const el = (v) => {
            try {
                if (ob[v]) return ob[v];
                else return '';
            } catch (error) {
                return '';
            }
        };
        for (let i = 0; i < maxlines; i += 1) {
            table.body.push([el(i), el(i + maxlines)]);
        }
        console.log(table);

        return observacoes
            ? {
                  table: table,
                  style: 'tableBody',
                  fontSize: 9,
                  layout: {
                      hLineColor: (i, node) => {
                          return '#000000';
                      },
                      vLineColor: (i, node) => {
                          return '#000000';
                      },
                      hLineWidth: (i, node) => {
                          if (i === table.body.length) {
                              return 1;
                          } else {
                              return 0;
                          }
                      },
                      vLineWidth: (i, node) => {
                          if (i === 0 || i === 2) {
                              return 1;
                          } else {
                              return 1;
                          }
                      },
                      fontSize: (i, node) => {
                          return 9;
                      },
                  },
              }
            : null;
    }

    unMed(item) {
        try {
            return String(item).toUpperCase().indexOf('ALGOD') > -1
                ? ' @/ha'
                : ' sc/ha';
        } catch (error) {
            return ' sc/ha';
        }
    }

    wrapT(text: string, size: number) {
        try {
            if (text.length > size) {
                return text.substring(0, size) + '...';
            } else {
                return text;
            }
        } catch (error) {
            return text;
        }
    }

    Ts(
        text: string,
        colSpan: number = 1,
        color: string = '#ffffff',
        alignment: Alignment = 'center',
        style = 'tableHeaderSub',
        fillColor = '#312783',
        width = 'auto'
    ) {
        return {
            text: text,
            style: style,
            alignment: alignment,
            colSpan: colSpan,
            color: color,
            fillColor: fillColor,
            width: width,
            margin: [0, 4, 0, 0],
        };
    }

    Th(
        text: string,
        colSpan: number = 1,
        color: string = '#ffffff',
        alignment: Alignment = 'center',
        style = 'tableHeader',
        fillColor = '#312783',
        width = 'auto'
    ) {
        return {
            text: text,
            style: style,
            alignment: alignment,
            colSpan: colSpan,
            color: color,
            fillColor: fillColor,
            width: width,
        };
    }

    Tb(
        text: string,
        color: string = '#1e1e1e',
        alignment: Alignment = 'left',
        style = 'tableBody',
        colSpan: number = 1
    ) {
        return {
            text: text,
            style: style,
            alignment: alignment,
            colSpan: colSpan,
            color: color,
        };
    }

    Tt(
        text: any,
        style = 'bodytext',
        bold: boolean = false,
        fillColor = '#312783',
        color = 'white'
    ) {
        return {
            text: text,
            style: style,
            bold: bold,
            fillColor: fillColor,
            color: color,
        };
    }

    T(
        text: any,
        style = 'bodytext',
        bold: boolean = false,
        fillColor = 'white',
        color = 'black'
    ) {
        return {
            text: text,
            style: style,
            bold: bold,
            fillColor: fillColor,
            color: color,
        };
    }

    Tc(
        text,
        style = 'bodytextsmall',
        bold: boolean = false,
        fillColor: string = '#f2f2f2'
    ) {
        return {
            text: Math.abs(Number(text)).toFixed(2),
            style: style,
            bold: bold,
            fillColor: fillColor,
            alignment: 'center',
            color: Number(text) >= 0 ? '#1e1e1e' : 'red',
        };
    }

    Tform2(field: string, value: string, c1 = '#ffffff', c2 = '#ffffff') {
        return {
            table: {
                headerRows: 0,
                widths: [150, '*'],
                body: [
                    [
                        this.T(String(field), 'bodytext', true, c1),
                        this.T(String(value), 'bodytext', false, c2),
                    ],
                ],
            },
            layout: {
                hLineColor: (i, node) => {
                    return 'white';
                },
                vLineColor: (i, node) => {
                    return 'white';
                },
            },
        };
    }

    Tform4(field1, value1, field2, value2, c1 = '#ffffff', c2 = '#ffffff') {
        return {
            table: {
                headerRows: 0,
                widths: [74, '*', 74, '*'],
                body: [
                    [
                        this.T(String(field1), 'bodytext', true, c2),
                        this.T(this.wrapT(value1, 35), 'bodytext', false, c1),
                        this.T(String(field2), 'bodytext', true, c2),
                        this.T(this.wrapT(value2, 35), 'bodytext', false, c1),
                    ],
                ],
            },
            layout: {
                hLineColor: (i, node) => {
                    return 'white';
                },
                vLineColor: (i, node) => {
                    return 'white';
                },
            },
        };
    }

    Tform4Folha(
        field1,
        value1,
        field2,
        value2,
        c1 = '#ffffff',
        c2 = '#ffffff'
    ) {
        return {
            table: {
                headerRows: 0,
                widths: [65, 200, 55, 200],
                body: [
                    [
                        this.T(
                            this.wrapT(field1, 999),
                            'bodytextfolha',
                            true,
                            c2
                        ),
                        this.T(
                            this.wrapT(value1, 35),
                            'bodytextfolha',
                            false,
                            c1
                        ),
                        this.T(
                            this.wrapT(field2, 999),
                            'bodytextfolha',
                            true,
                            c2
                        ),
                        this.T(
                            this.wrapT(value2, 35),
                            'bodytextfolha',
                            false,
                            c1
                        ),
                    ],
                ],
            },
            layout: {
                hLineColor: (i, node) => {
                    return 'white';
                },
                vLineColor: (i, node) => {
                    return 'white';
                },
            },
        };
    }

    linebreak() {
        // { canvas: [{ type: 'line', x1: 10, y1: 10, x2: 520-10, y2: 10, lineWidth: 0.5 }] }
        return { text: ' ', style: 'breakline' };
    }

    breaklinemin() {
        // { canvas: [{ type: 'line', x1: 10, y1: 10, x2: 520-10, y2: 10, lineWidth: 0.5 }] }
        return { text: ' ', style: 'breaklinemin' };
    }

    pagebreak() {
        // { canvas: [{ type: 'line', x1: 10, y1: 10, x2: 520-10, y2: 10, lineWidth: 0.5 }] }
        return { text: ' ', pageBreak: 'before' };
    }

    customTable(
        borderh = [],
        borderv = [],
        colorh = '#000000',
        colorv = '#000000'
    ) {
        return {
            // fillColor: (rowIndex, node, columnIndex) => {
            //     if (rowIndex == 0) {
            //         return 'white';
            //     } else {
            //         return 'white';
            //     }
            // },
            hLineColor: (i, node) => {
                return colorh;
            },
            vLineColor: (i, node) => {
                return colorv;
            },
            hLineWidth: (i, node) => {
                if (borderh.indexOf(i) > -1) {
                    return 1;
                } else {
                    return 0;
                }
            },
            vLineWidth: (i, node) => {
                if (borderv.indexOf(i) > -1) {
                    return 1;
                } else {
                    return 0;
                }
            },
            fontSize: (i, node) => {
                return 9;
            },
        };
    }

    topTable(vl = 0, hl = 1) {
        return {
            // fillColor: (rowIndex, node, columnIndex) => {
            //     if (rowIndex == 0) {
            //         return 'white';
            //     } else {
            //         return 'white';
            //     }
            // },
            hLineColor: (i, node) => {
                return '#000000';
            },
            vLineColor: (i, node) => {
                return '#ffffff';
            },
            hLineWidth: (i, node) => {
                return hl;
            },
            vLineWidth: (i, node) => {
                return vl;
            },
            fontSize: (i, node) => {
                return 9;
            },
        };
    }

    bodyTable(vl = 0, hl = 1) {
        return {
            // fillColor: (rowIndex, node, columnIndex) => {
            //     if (rowIndex == 0) {
            //         return 'white';
            //     } else {
            //         return 'white';
            //     }
            // },
            hLineColor: (i, node) => {
                return '#000000';
            },
            vLineColor: (i, node) => {
                return '#ffffff';
            },
            hLineWidth: (i, node) => {
                return hl;
            },
            vLineWidth: (i, node) => {
                return vl;
            },
            fontSize: (i, node) => {
                return 9;
            },
        };
    }

    mergeHeaderPlanta() {
        return new Promise((resolve, reject) => {
            if (!this.image) {
                var c = document.createElement('canvas');
                this.tam = 588;
                c.width = 1800;
                c.height = 300;
                var ctx = c.getContext('2d');
                var imageObj1 = new Image();
                imageObj1.src = topplant;
                imageObj1.onload = () => {
                    ctx.drawImage(imageObj1, 12, 12, 1300, 280);
                    var img = c.toDataURL('image/png');
                    console.log(img);
                    resolve(img);
                };
            } else {
                var c = document.createElement('canvas');
                this.tam = 588;
                c.width = 1800;
                c.height = 300;
                var ctx = c.getContext('2d');
                var imageObj1 = new Image();
                var imageObj2 = new Image();
                imageObj1.src = topplant;
                imageObj1.onload = () => {
                    ctx.drawImage(imageObj1, 12, 12, 1300, 250);
                    imageObj2.src = this.image;
                    imageObj2.onload = function () {
                        ctx.drawImage(imageObj2, 1400, 12, 350, 250);
                        var img = c.toDataURL('image/png');
                        resolve(img);
                    };
                };
            }
        });
    }

    mergeHeader() {
        return new Promise((resolve, reject) => {
            if (!this.image) {
                var c = document.createElement('canvas');
                this.tam = 500;
                c.width = 1800;
                c.height = 300;
                var ctx = c.getContext('2d');
                var imageObj1 = new Image();
                imageObj1.src = top;
                imageObj1.onload = () => {
                    ctx.drawImage(imageObj1, 12, 12, 1770, 280);
                    var img = c.toDataURL('image/png');
                    resolve(img);
                };
            } else {
                var c = document.createElement('canvas');
                this.tam = 588;
                c.width = 1800;
                c.height = 300;
                var ctx = c.getContext('2d');
                var imageObj1 = new Image();
                var imageObj2 = new Image();
                imageObj1.src = top;
                imageObj1.onload = () => {
                    ctx.drawImage(imageObj1, 12, 12, 1300, 250);
                    imageObj2.src = this.image;
                    imageObj2.onload = function () {
                        ctx.drawImage(imageObj2, 1400, 12, 350, 250);
                        var img = c.toDataURL('image/png');
                        resolve(img);
                    };
                };
            }
        });
    }

    separator() {
        return {
            canvas: [
                {
                    type: 'line',
                    x1: 0,
                    y1: 5,
                    x2: 595 - 2 * this.margin,
                    y2: 5,
                    lineWidth: 2,
                    color: '#cccccc',
                    fillColor: '#cccccc',
                },
            ],
        };
    }
}
