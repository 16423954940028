import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { bbf_theme, colors_list } from 'src/app/components/theme/theme_graph';

import * as moment from 'moment';

am4core.useTheme(am4themes_animated);

type LegendPosition = 'left' | 'right' | 'top' | 'bottom' | 'absolute';

@Component({
    selector: 'piechart',
    templateUrl: './piechart.component.html',
    styleUrls: ['./piechart.component.scss'],
})
export class PiechartComponent implements OnInit {
    @Input() data: any = [];
    @Input() dsfull;
    @Input() dsvalue;
    @Input() id: any = 'linechart' + moment().valueOf();
    @ViewChild('chartdiv') chartdiv: ElementRef;

    private chart: any;

    constructor() {}

    ngOnDestroy() {
        this.chart.dispose();
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.create();
        }, 300);
    }
    

    async getImage() {
        let options = this.chart.exporting.getFormatOptions('png');
        options.minWidth = 2600;
        options.minHeight = 1700;
        options.maxWidth = 2600;
        options.maxHeight = 1700;
        // let el:HTMLElement = this.chartdiv.nativeElement
        // el.style.width = "647px"
        // el.style.height = "450px"
        // this.chart.reinit();
        // const  sleep = async (ms)=>{
        //     return new Promise((resolve, reject)=>{
        //         setTimeout(() => {
        //             resolve("");
        //         }, ms);
        //     })
        // }
        // await sleep(2000);
        return await this.chart.exporting.getImage('png')
    }

    create() {
        this.chart = am4core.create(this.id, am4charts.RadarChart);
        this.chart.colors.list = colors_list;
        this.chart.data = this.data;
        this.chart.logo.height = -15000;
        // Make chart not full circle
        this.chart.startAngle = -90;
        this.chart.endAngle = 270;
        this.chart.innerRadius = am4core.percent(70);

        // Set number format
        this.chart.numberFormatter.numberFormat = "#.#'%'";

        // Create axes
        let categoryAxis = this.chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = 'category';
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.grid.template.strokeOpacity = 0;
        categoryAxis.renderer.labels.template.disabled = true;
        // categoryAxis.renderer.labels.template.horizontalCenter = 'right';
        // categoryAxis.renderer.labels.template.fontWeight = 500;

        categoryAxis.renderer.minGridDistance = 10;

        let valueAxis = this.chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.grid.template.strokeOpacity = 0;
        valueAxis.min = 0;
        valueAxis.max = 100;
        valueAxis.strictMinMax = true;
        valueAxis.renderer.labels.template.disabled = true;

        //legend
        this.chart.legend = new am4charts.Legend();
        this.chart.legend.fontSize = 13;
        this.chart.legend.position = 'bottom';
        this.chart.legend.marginBottom = 20;
        this.chart.legend.valueLabels.template.fontSize = 12;
        this.chart.exporting.menu = new am4core.ExportMenu();
        this.chart.exporting.menu.align = 'left';
        this.chart.exporting.menu.verticalAlign = 'top';
        this.chart.exporting.title = 'gráfico';
        this.chart.exporting.menu.items = [
            {
                label: '...',
                menu: [
                    { type: 'png', label: 'PNG' },
                    { type: 'print', label: 'Imprimir' },
                ],
            },
        ];

        
        let series2:am4charts.RadarColumnSeries = this.chart.series.push(new am4charts.RadarColumnSeries());
        series2.dataFields.valueX = 'value';
        series2.dataFields.categoryY = 'category';
        series2.name = this.dsvalue;
        series2.clustered = false;
        series2.columns.template.strokeWidth = 0;
        series2.fill = am4core.color('#312783');
        series2.columns.template.tooltipText = '{category}: [bold]{value}[/]';
        series2.columns.template.radarColumn.cornerRadius = 20;
        series2.zIndex = 89;

        // Create series
        let series1 = this.chart.series.push(new am4charts.RadarColumnSeries());
        series1.dataFields.valueX = 'full';
        series1.dataFields.categoryY = 'category';
        series1.name = this.dsfull;
        series1.clustered = false;
        series1.fill = am4core.color('#3AAA35');
        // series1.columns.template.fill = new am4core.InterfaceColorSet().getFor(
        //     'alternativeBackground'
        // );
        series1.columns.template.fillOpacity = 1;
        series1.columns.template.cornerRadiusTopLeft = 0;
        series1.columns.template.strokeWidth = 0;
        series1.columns.template.radarColumn.cornerRadius = 0;
        series2.zIndex = 59;


        let label = this.chart.seriesContainer.createChild(am4core.Label);
        label.text = Number(this.data[0].value).toFixed(2)+"%";
        label.horizontalCenter = 'middle';
        label.verticalCenter = 'middle';
        label.fontSize = 35;
        label.fill = colors_list[0];
        // Add cursor
        // this.chart.cursor = new am4charts.RadarCursor();
        // this.chart.cursor.showOnInit = true;
        // this.chart.cursor.showTooltipOn = 'always';

        // this.chart.cursor.show();

        // this.chart.cursor.events.on('cursorpositionchanged', (ev) => {
        //     let point = valueAxis.valueToPoint(84);
        //     this.chart.cursor.triggerMove(point, 'soft');
        // });

        // this.chart.events.on('datavalidated', (ev) => {
        //     let point = valueAxis.valueToPoint(84);
        //     this.chart.cursor.triggerMove(point, 'soft');
        // this.chart.cursor.alwaysShowTooltip = true

        // });
    }
}
