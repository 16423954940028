import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Consultor } from 'src/app/class/consultor';
import { PickerComponent } from 'src/app/components/picker/picker.component';
import { ConnectionService } from 'src/app/services/connection.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'novoconsultor',
  templateUrl: './novoconsultor.component.html',
  styleUrls: ['./novoconsultor.component.scss']
})
export class NovoconsultorComponent implements OnInit {
  public consultor: Consultor = new Consultor();
  public loading: boolean = false;

  constructor(
      public dialogRef: MatDialogRef<PickerComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      public util: UtilService,
      private conn: ConnectionService
  ) {}

  ngOnInit(): void {
      if (this.data) {
          this.consultor = this.data;
      }
  }


  async close() {
      this.loading = true;
      this.util.consultores = Consultor.fromJsonArray(
          await this.conn.getConsultor().toPromise()
      );
      this.loading = false;
      this.dialogRef.close(this.util.consultores);
  }

  validar(): boolean {
      if (!this.consultor.dsconsultor) {
          this.util.alertDanger('Consultor é campo obrigatório!');
          return false;
      }
      return true;
  }

  async salvar() {
      if (!this.validar()) return;
      this.loading = true;
      try {
          let res: Consultor[] = Consultor.fromJsonArray(
              await this.conn.setConsultor(this.consultor).toPromise()
          );
          console.log(res);
          if (res ? res.length > 0 : false) {
              if (res[0].id) {
                  this.consultor = res[0];
              } else {
                  this.util.alertDanger(
                      'Erro ao adicionar/editar Consultor!'
                  );
              }
          } else {
              this.util.alertDanger('Erro ao adicionar/editar Consultor!');
          }
      } catch (e) {
          console.log(e);

          this.util.alertDanger('Erro ao adicionar/editar Consultor!');
      }
      this.loading = false;
  }
}
