import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { bbf_theme, colors_list } from 'src/app/components/theme/theme_graph';

import * as moment from 'moment';

am4core.useTheme(am4themes_animated);

type LegendPosition = 'left' | 'right' | 'top' | 'bottom' | 'absolute';

@Component({
    selector: 'linechart',
    templateUrl: './linechart.component.html',
    styleUrls: ['./linechart.component.scss'],
})
export class LinechartComponent implements OnInit {
    @Input() data: any = [];
    @Input() id: any = 'linechart' + moment().valueOf();
    @Input() labels: any = [];
    @Input() colors: any = [];
    @Input() title: string;
    @Input() titlex: string;
    @Input() titley: string;
    @Input() stacked: boolean = true;
    @Input() calculate: boolean = true;
    @Input() stricted: boolean = true;
    @Input() min: number = 0;
    @Input() max: number = 100;
    @Input() legendpos: LegendPosition = 'bottom';
    @Input() steped: boolean = false;
    @Input() legx: boolean = true;
    @Input() legy: boolean = true;

    private chart: am4charts.XYChart;

    constructor() {}

    ngOnDestroy() {
        this.chart.dispose();
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.create();
        }, 300);
    }

    async getImage() {
        let options = this.chart.exporting.getFormatOptions('jpg');
        options.minWidth = 2600;
        options.minHeight = 1700;
        options.maxWidth = 2600;
        options.maxHeight = 1700;
        return await this.chart.exporting.getImage('jpg');
    }

    create() {
        this.chart = am4core.create(this.id, am4charts.XYChart);
        this.chart.colors.list = colors_list;
        this.chart.colors.step = 1;
        if (this.colors) {
            this.chart.colors.list = this.colors.map((color) =>
                am4core.color(color)
            );
        }
        this.chart.data = this.data;
        this.chart.logo.height = -15000;
        console.log(this.data);

        // Create axes
        let axisX = this.chart.xAxes.push(new am4charts.CategoryAxis());
        axisX.dataFields.category = 'x';
        axisX.renderer.minGridDistance = 30;
        axisX.renderer.labels.template.disabled = !this.legx;
        axisX.renderer.labels.template.fontSize = 15;
        let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = this.min;
        valueAxis.max = this.max;
        // valueAxis.calculateTotals = this.calculate;
        valueAxis.strictMinMax = this.stricted;
        valueAxis.renderer.labels.template.adapter.add('text', function (text) {
            return text + '%';
        });
        valueAxis.renderer.labels.template.fontSize = 15;


        if (this.titlex) axisX.title.text = this.titlex;
        if (this.titley) valueAxis.title.text = this.titley;
        for (let l of this.labels) {
            this.generateSerie('x', l);
        }
        // Legend
        this.chart.legend = new am4charts.Legend();
        this.chart.legend.fontSize = 13;
        this.chart.legend.position = this.legendpos;
        this.chart.legend.marginBottom = 20;
        this.chart.legend.valueLabels.template.fontSize = 12;
        this.chart.scrollbarY = new am4core.Scrollbar();
        this.chart.scrollbarX = new am4core.Scrollbar();
        this.chart.numberFormatter.numberFormat = '#.###,##';
        this.chart.exporting.menu = new am4core.ExportMenu();
        this.chart.exporting.menu.align = 'left';
        this.chart.exporting.menu.verticalAlign = 'top';
        this.chart.exporting.title = this.title;
        this.chart.exporting.menu.items = [
            {
                label: '...',
                menu: [
                    { type: 'png', label: 'PNG' },
                    { type: 'print', label: 'Imprimir' },
                ],
            },
        ];
        if (this.title) {
            let title = this.chart.titles.create();
            title.text = this.title;
            title.fontSize = 16;
            title.marginTop = 12;
            title.marginBottom = 12;
        }
        // this.chart.events.on("ready", () => {
        //   axisX.zoomToCategories(
        //     this.data[this.data.length - 50]['x'],
        //     this.data[this.data.length - 1]['x']
        //   );
        // });
        this.chart.scrollbarX.exportable = false;
        this.chart.scrollbarY.exportable = false;
        // this.chart.events.on('ready', () => {
        //     setTimeout(() => {
        //         this.chart.exporting.getImage('jpg').then((res) => {
        //             this.output.emit(res);
        //         });
        //     }, 2000);
        // });
        this.chart.cursor = new am4charts.XYCursor();
    }

    generateSerie(x, y) {
        let series;
        series = this.chart.series.push(new am4charts.LineSeries());
        series.name = y;
        series.dataFields.valueY = y;
        series.dataFields.categoryX = x;
        series.tooltipText = '{valueY}';
        series.tooltip.pointerOrientation = 'vertical';
        series.stacked = this.stacked;
        series.fillOpacity = 1.5;
        series.tooltip.exportable = false;
    }
}
