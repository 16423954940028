import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FichainicialComponent } from './pages/fichainicial/fichainicial.component';
import { ListlaudosComponent } from './pages/listlaudos/listlaudos.component';
import { ListclientesComponent } from './pages/listclientes/listclientes.component';
import { LoginComponent } from './pages/login/login.component';
import { ListsolicitanteComponent } from './pages/listsolicitante/listsolicitante.component';
import {ListculturaComponent} from './pages/listcultura/listcultura.component';
import { ListusuariosComponent } from './pages/listusuarios/listusuarios.component';
import { LogolaudosComponent } from './register/logolaudos/logolaudos.component';
import { SelectionComponent } from './pages/selection/selection.component';
import { ListconsultorComponent } from './pages/listconsultor/listconsultor.component';
import { ListlaudosfolhaComponent } from './pages/listlaudosfolha/listlaudosfolha.component';
import { CadfolhaComponent } from './pages/cadfolha/cadfolha.component';

const routes: Routes = [
  {path: "fichainicial", component: FichainicialComponent},
  {path: "cadfolha", component: CadfolhaComponent},
  {path: "cadfolha/:id", component: CadfolhaComponent},
  {path: "listlaudos", component: ListlaudosComponent},
  {path: "listlaudosfolha", component: ListlaudosfolhaComponent},
  {path: "listclientes", component: ListclientesComponent},
  {path: "listsolicitante", component: ListsolicitanteComponent},
  {path: "listconsultor", component: ListconsultorComponent},
  {path: "listcultura", component: ListculturaComponent},
  {path: "listusers", component: ListusuariosComponent},
  {path: "config", component: LogolaudosComponent},
  {path: "selection", component: SelectionComponent},
  {path: "", component: LoginComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
