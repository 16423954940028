import { Component, Input, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Color, Label } from 'ng2-charts';

@Component({
  selector: 'grafico',
  templateUrl: './grafico.component.html',
  styleUrls: ['./grafico.component.scss']
})
export class GraficoComponent implements OnInit {
  @Input() group: boolean = false
  @Input() step: number;

  public barChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: "bottom"
    },
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      xAxes: [{
        stacked: false,
        gridLines: {
          display: false
        }
      }], yAxes: [{
        ticks: {
          stepSize: null
        },
        stacked: false,
        gridLines: {
          display: false
        }
      }]
    },
    plugins: {
      datalabels: {
        display: false,
        anchor: 'start',
        align: 'end',
      }
    }
  };
  @Input() public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [pluginDataLabels];

  // @Input() public barChartLabels: Label[] = ["Fóforo", "Potássio", "Magnésio", "Cálcio"];
  @Input() public barChartLabels: Label[] = [];

  @Input() public barChartData: ChartDataSets[] = []
  // @Input() public barChartData: ChartDataSets[] = [
  //   { data: [100, 60.13, 4.42, 100], label: 'Sit. Atual' },
  //   { data: [0, 39.87, 95.58, 0], label: 'Défict' }
  // ];

  public chartColors: Color[] = [
    {
      backgroundColor: 'rgba(46, 49, 146,1)',
      borderColor: 'rgba(46, 49, 146,1)',
      pointBackgroundColor: 'rgba(46, 49, 146,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(46, 49, 146,0.8)'
    },
    {
      backgroundColor: 'rgba(175, 33, 33, 1)',
      borderColor: 'rgba(175, 33, 33, 1)',
      pointBackgroundColor: 'rgba(175, 33, 33, 1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(175, 33, 33, 1)'
    }
  ]

  constructor() { }

  ngOnInit(): void {
    this.barChartOptions.scales.xAxes[0].stacked = this.group
    this.barChartOptions.scales.yAxes[0].stacked = this.group
    if (this.step)
      this.barChartOptions.scales.yAxes[0].ticks.stepSize = this.step
  }


  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

}