<menu></menu>
<div id="container">
    <div class="form">
        <div class="header" table="true" style="--cs: 1fr 130px">
            <span>Configurações</span>
        </div>
        <div class="struct" id="struct">
            <div>
                Selecionar Imagem para o laudo:
            </div>
            <fileupload #fileupload (uploaded)="image = $event"></fileupload>
            <br>
            <div class="button" (click)="enviar()">
                Enviar Imagem
            </div>
        </div>
    </div>
</div>
<spinner *ngIf="loading"></spinner>