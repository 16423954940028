<main id="modal">
    <div class="header" style="background-color: red;">
        <div class="text">{{ data.title }}</div>
        <mat-icon
            aria-hidden="false"
            aria-label="Clique para fechar"
            mat-dialog-close
        >
            close
        </mat-icon>
    </div>
    <div class="form">
        <div class="message">
            {{data.message}}
        </div>
        <div class="hl"></div>
        <div class="buttons">
            <div class='button' *ngFor="let b of data.buttons" (click)="close(b.value)">{{b.name}}</div>
        </div>
    </div>
    <spinner *ngIf="loading" [message]="'recarregando...'"></spinner>
</main>
