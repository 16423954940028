import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilService } from 'src/app/services/util.service';

@Component({
    selector: 'menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
    hd = {};
    public items;
    constructor(public route: Router, public util: UtilService) {
        this.items = [
            {
                label: this.util.tplaudo == 'solo' ? 'BBF SOIL' : 'BBF PLANT',
                image: this.util.tplaudo == 'solo' ? 'solo.svg' : 'folha.svg',
                imageselected:
                    this.util.tplaudo == 'solo' ? 'solob.svg' : 'folhab.svg',
                selected: false,
                admin: false,
                route:
                    this.util.tplaudo == 'solo' ? 'fichainicial' : 'cadfolha',
            },
            {
                label: 'Listar Laudos',
                icon: 'article',
                selected: false,
                admin: false,
                route:
                    this.util.tplaudo == 'solo'
                        ? 'listlaudos'
                        : 'listlaudosfolha',
            },
            {
                label: 'Proprietários',
                icon: 'people_alt',
                selected: false,
                admin: false,
                route: 'listclientes',
            },
            {
                label: 'Consultores',
                icon: 'work',
                selected: false,
                admin: false,
                route: 'listconsultor',
            },
            {
                label: 'Configurações',
                icon: 'settings',
                selected: false,
                admin: false,
                route: 'config',
            },
            // {
            //   label: "Culturas",
            //   icon: "eco",
            //   selected: false,
            //   admin: false,
            //   route: "listcultura"
            // }
        ];
    }

    ngOnInit(): void {
        if (this.util.isMaster()) {
            this.items.push({
                label: 'Solicitantes',
                icon: 'connect_without_contact',
                selected: false,
                admin: false,
                route: 'listsolicitante',
            });
            this.items.push({
                label: 'Usuários',
                icon: 'account_circle',
                selected: false,
                admin: true,
                route: 'listusers',
            });
        }
        this.check();
    }

    close() {
        this.util.clearLogin();
        this.go('');
    }

    public go(route) {
        this.route.navigate([`/${route}`]);
    }

    private check() {
        for (let item of this.items) {
            if (item.route == this.route.url.replace('/', '')) {
                item.selected = true;
            } else item.selected = false;
        }
    }

    acess() {
        window.open('https://www.malbizer.com.br');
    }

    switch() {
        this.go('selection');
    }
}
