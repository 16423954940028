import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'novolaudocalcs',
  templateUrl: './novolaudocalcs.component.html',
  styleUrls: ['./novolaudocalcs.component.scss']
})
export class NovolaudocalcsComponent implements OnInit {
  @Input() public calculo: any;
  @Output() event: EventEmitter<any> = new EventEmitter();
  public keys = [
    {
      title: "Resultados - Disponível no Solo",
      kgha: ['Ca', 'Mg', 'K', 'P', 'S'],
      gha: ['Fe', 'Mn', 'Cu', 'Zn', 'B'],
      id: 't2'
    },
    {
      title: "Necessidade da cultura (Extração)",
      kgha: ['Ca', 'Mg', 'K', 'P', 'S'],
      gha: ['Fe', 'Mn', 'Cu', 'Zn', 'B'],
      id: 't3'
    },
    {
      title: "Restituição do Equilíbrio",
      kgha: ['Ca', 'Mg', 'K2O', 'P2O5', 'S'],
      gha: ['Fe', 'Mn', 'Cu', 'Zn', 'B'],
      id: 't4'
    },
    {
      title: "Exportação da cultura anterior realizada",
      kgha: ['Ca', 'Mg', 'K', 'P', 'S'],
      gha: ['Fe', 'Mn', 'Cu', 'Zn', 'B'],
      id: 't5'
    },
    {
      title: "Extração da cultura anterior realizada",
      kgha: ['Ca', 'Mg', 'K2O', 'P2O5', 'S'],
      gha: ['Fe', 'Mn', 'Cu', 'Zn', 'B'],
      id: 't6'
    },
    {
      title: "Diferença (Extraçao Estimada x Extração Realizada x Extração Segunda Recomendação)",
      kgha: ['Ca', 'Mg', 'K2O', 'P2O5', 'S'],
      gha: ['Fe', 'Mn', 'Cu', 'Zn', 'B'],
      id: 't7'
    },
    {
      title: "Necessidade da cultura atual",
      kgha: ['Ca', 'Mg', 'K', 'P', 'S'],
      gha: ['Fe', 'Mn', 'Cu', 'Zn', 'B'],
      id: 't8'
    },
    {
      title: "Resultados - Disponível no Solo",
      kgha: ['Ca', 'Mg', 'K', 'P', 'S'],
      gha: ['Fe', 'Mn', 'Cu', 'Zn', 'B'],
      id: 't9'
    },
    {
      title: "Resultados - Reserva de Solo",
      kgha: ['Ca', 'Mg', 'K', 'P', 'S'],
      gha: ['Fe', 'Mn', 'Cu', 'Zn', 'B'],
      id: 't10'
    },
    {
      title: "Restituição do Equilíbrio",
      kgha: ['Ca', 'Mg', 'K2O', 'P2O5', 'S'],
      gha: ['Fe', 'Mn', 'Cu', 'Zn', 'B'],
      id: 't11'
    },
  ]
  constructor() { }

  ngOnInit(): void {
    document.getElementById("struct").scrollTo(0, 0)
  }

  public next(){
    this.event.emit(4)
  }

  public back(){
    this.event.emit(2)
  }

}
