<!-- <menu></menu>
<div id="container">
    <div class="form">
        <div class="header">
            Gráfico
        </div>
        <div class="struct" id="struct"> -->
<div style="position: relative; width:40vw">
    <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
        [plugins]="barChartPlugins" [legend]="barChartLegend" [chartType]="barChartType" [colors]="chartColors">
    </canvas>
</div>
<!-- </div>
    </div> -->