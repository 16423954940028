import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';

@Component({
  selector: 'novolaudodtgraph',
  templateUrl: './novolaudodtgraph.component.html',
  styleUrls: ['./novolaudodtgraph.component.scss']
})
export class NovolaudodtgraphComponent implements OnInit {
  @Input() public calculo: any;
  @Output() event: EventEmitter<any> = new EventEmitter();
  

  public table17 = {
    keys: ['ca1','mg1','k1','p1','s1'],
    labels: ['Ca','Mg','K','P','S'],
    items:['ideal','atual','crivo1','sitatual','deficit'],
    columns: ['Elemento','Ideal','Atual','Crivo 1','Sit. Atual','Déficit']
  }
  public table18 = {
    keys: ['fe1','mn1','cu1','zn1','b1'],
    labels: ['Fe','Mn','Cu','Zn','B'],
    items:['ideal','atual','crivo1','sitatual','deficit'],
    columns: ['Elemento','Ideal','Atual','Crivo 1','Sit. Atual','Déficit']
  }
  public table19 = {
    keys: ['ca1','mg1','k1','p1'],
    labels: ['Cálcio','Magnésio','Potássio','Fósforo'],
    items:['atual','ideal','crivo1','sitatual','deficit'],
    columns: ['Elemento','Atual','Ideal','Crivo 1','Sit. Atual','Déficit']
  }
  public table20 = {
    keys: ['ca1','mg1','k1','p1','s1','fe1','mn1','cu1','zn1','b1'],
    labels: ['Ca','Mg','K','P','S','Fe','Mn','Cu','Zn','B'],
  }
  public table22 = {
    keys: ['dscult','0','30','60','90','120','media','0m','30m','60m','90m','120m'],
    labels: ['Cultura','0','30','60','90','120','Média','0','30','60','90','120'],
  }
  
  public table23 = {
    keys: ['0','30','60','90','120'],
  }
  constructor() { }

  ngOnInit(): void {
    document.getElementById("struct").scrollTo(0, 0)
  }

  public next(){
    console.log("Gerando gráficos...");
    this.event.emit(5)
    
  }

  public back(){
    this.event.emit(3)
  }


}
