import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-picker',
  templateUrl: './picker.component.html',
  styleUrls: ['./picker.component.scss']
})
export class PickerComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<PickerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public util: UtilService
  ) { }

  ngOnInit(): void {
  }

  close(){
    this.dialogRef.close();
  }

  select(item){
    this.dialogRef.close(item);
  }

  async add(){
    this.data.items = await this.util.dialog(this.data.component)
    console.log(this.data.items);
    let table = this.data.table;
    this.data.table="";
    setTimeout(() => {
      this.data.table = table; 
    }, 100);
    
  }

}
