import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'buscasolicitante',
    templateUrl: './buscasolicitante.component.html',
    styleUrls: ['./buscasolicitante.component.scss'],
})
export class BuscasolicitanteComponent implements OnInit {
    @Input() data: any = [];
    @Output() sign = new EventEmitter();
    public term: string;
    public list: any = [];

    constructor() {}

    ngOnInit(): void {
        this.list = this.data;
    }

    public select(item) {
        this.sign.emit(item);
    }

    public search() {
        if (this.term) {
            this.list = this.data.filter(
                (item) =>
                    String(item.dssolicitante)
                        .toUpperCase()
                        .indexOf(String(this.term).toUpperCase()) > -1
            );
        } else {
            this.list = this.data;
        }
    }
}
