<div id="menu">
    <div class="header"></div>
    <div class="items" *ngFor="let item of items" (click)="go(item.route)">
        <div
            [ngClass]="{ selected: item.selected }"
            [matTooltip]="item.label"
            matTooltipPosition="after"
        >
            <mat-icon aria-hidden="false" *ngIf="item.icon">{{ item.icon }}</mat-icon>
            <div class="img" *ngIf="item.image && !item.selected" [style.background]="'url(/assets/icons/'+item.image+')'"></div>
            <div class="img" *ngIf="item.image && item.selected" [style.background]="'url(/assets/icons/'+item.imageselected+')'"></div>
            <div class="label">{{ item.label }}</div>
        </div>
    </div>
</div>

<div id="bottom" table="true" style="--cs: 50px 1fr 1fr 150px">
    <mat-icon (click)="switch()" class="switchbutton">sync_alt</mat-icon>
    <div>
        Usuário: {{ util.getDsUser() }} (<span *ngIf="util.isMaster()"
            >Administrador</span
        ><span *ngIf="!util.isMaster()">Usuário Comum</span>) - Saldo de Laudos:
        <span
            [ngStyle]="{ color: util.getSaldoLaudos() <= 0 ? 'red' : 'blue' }"
            >{{ util.user.saldo }}</span
        >
    </div>
    <span (click)="acess()" class='malbizer'>Malbizer Soluções</span>
    <div (click)="close()" class="close">(X) Sair</div>
</div>
