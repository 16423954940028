import { Component, Input, OnInit } from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { bbf_theme_inverse, colors_list } from '../theme/theme_graph';
import { flecha } from './image';

am4core.useTheme(bbf_theme_inverse);

@Component({
    selector: 'stackedcolumchart',
    templateUrl: './stackedcolumchart.component.html',
    styleUrls: ['./stackedcolumchart.component.scss'],
})
export class StackedcolumchartComponent implements OnInit {
    @Input() id_chart;
    @Input() data = [];
    @Input() minY = 0;
    @Input() maxY = 100;
    @Input() wcolumn = 80;
    @Input() rangeY;
    @Input() width = '647px';
    @Input() inirangeY;
    @Input() rangetext;
    @Input() calculate = true;
    @Input() showAxisY = true;
    @Input() showLabels = true;
    @Input() inversed = false;
    @Input() legend = true;
    @Input() colorbullet = '#ffffff';
    @Input() centerbullet = true;
    @Input() color = colors_list.reverse();

    public chart: am4charts.XYChart;

    constructor() {}

    ngOnInit(): void {}

    ngAfterViewInit() {
        this.init();
    }

    async exportChart(size = '647px') {
        return new Promise((resolve, reject) => {
            let options = this.chart.exporting.getFormatOptions('png');
            options.minWidth = 2600;
            options.minHeight = 1700;
            options.maxWidth = 2600;
            options.maxHeight = 1700;
            let width = this.width;
            this.width = size;
            this.chart.reinit();
            setTimeout(async () => {
                let img = await this.chart.exporting.getImage('png');
                resolve(img);
                this.width = width;
                this.chart.reinit();
            }, 1200);
        });
    }

    init() {
        this.chart = am4core.create(this.id_chart, am4charts.XYChart);
        this.chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
        this.chart.logo.height = -15000;
        this.chart.data = this.data;

        this.chart.colors.list = this.color;
        this.chart.padding(12, 12, 12, 12);
        if (this.legend) this.chart.legend = new am4charts.Legend();

        let categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = 'category';
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minWidth = 10;
        categoryAxis.renderer.minGridDistance = 20;
        categoryAxis.renderer.inversed = this.inversed;
        categoryAxis.renderer.labels.template.fontWeight = '600';
        categoryAxis.renderer.labels.template.fontSize = 18;


        let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = this.minY;
        valueAxis.max = this.maxY;
        valueAxis.strictMinMax = true;
        valueAxis.calculateTotals = this.calculate;
        valueAxis.renderer.minWidth = 10;
        valueAxis.renderer.labels.template.disabled = !this.showAxisY;

        if (this.rangeY) {
            var range = valueAxis.axisRanges.create();
            range.value = this.rangeY;
            if (this.inirangeY) {
                range.value = this.inirangeY;
                range.endValue = this.rangeY;
            }

            let bullet_range: am4charts.CircleBullet =
                new am4charts.CircleBullet();
            let image_bullet = bullet_range.createChild(am4core.Image);
            image_bullet.width = 71;
            image_bullet.height = 80;
            image_bullet.href = flecha;
            image_bullet.dx = -130;
            image_bullet.dy = -this.rangeY;
            range.bullet = image_bullet;

            this.chart.padding(12, 12, 12, 120);
            range.grid.stroke = am4core.color('#409bfc');
            range.grid.location = 1;
            range.grid.strokeWidth = 2;
            range.grid.strokeOpacity = 1;
            range.grid.above = true;
            range.axisFill.fillOpacity = 0.5;
            range.axisFill.fill = am4core.color('#46cecb');
            range.grid.strokeOpacity = 1;
        }
        // var maleRange: any = valueAxis.axisRanges.create();
        // maleRange.value = 10;
        // maleRange.endValue = 0;
        // maleRange.label.text = 'Male';
        // maleRange.label.fill = this.chart.colors.list[0];
        // maleRange.label.dy = 20;
        // maleRange.label.fontWeight = '600';
        // maleRange.grid.strokeOpacity = 1;

        for (let s in this.data[0]) {
            if (s != 'category') {
                this.generateSeries(s, s);
            }
        }
    }

    generateSeries(name, valueY, category = 'category') {
        let series = this.chart.series.push(new am4charts.ColumnSeries());
        series.columns.template.width = am4core.percent(this.wcolumn);
        series.columns.template.column.stroke = am4core.color('#000');
        series.name = name;
        series.dataFields.categoryX = category;
        series.dataFields.valueY = valueY;
        if (this.calculate) {
            series.dataFields.valueYShow = 'totalPercent';
            series.stacked = true;
            series.columns.template.tooltipText =
                "{name}: {valueY.totalPercent.formatNumber('#.00')}%";
        } else {
            series.columns.template.tooltipText =
                "{name}: {valueY.formatNumber('#.00')}";
        }
        series.dataItems.template.locations.categoryX = 0.5;
        series.tooltip.pointerOrientation = 'vertical';

        if (this.showLabels) {
            let bullet = series.bullets.push(new am4charts.LabelBullet());
            bullet.interactionsEnabled = false;
            if (this.calculate) {
                bullet.label.text =
                    "{valueY.totalPercent.formatNumber('#.00')}%";
            } else {
                bullet.label.text = "{valueY.formatNumber('#.00')}";
            }
            bullet.label.fill = am4core.color(this.colorbullet);
            bullet.label.fontSize = 12;
            bullet.label.fontWeight = 'bold';
            if (this.centerbullet) {
                bullet.locationY = 0.5;
            } else {
                bullet.label.dy = 12;
            }
        }
    }
}
