import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { bbf_theme, colors_list } from 'src/app/components/theme/theme_graph';
import * as moment from 'moment';

am4core.useTheme(am4themes_animated);

type LegendPosition = 'left' | 'right' | 'top' | 'bottom' | 'absolute';

@Component({
    selector: 'barchart',
    templateUrl: './barchart.component.html',
    styleUrls: ['./barchart.component.scss'],
})
export class BarchartComponent implements OnInit {
    @Input() id: any = 'chartdiv' + moment().valueOf();
    @Input() data: any = [];
    @Input() labels: any = [];
    @Input() colors: any = [];
    @Input() title: string;
    @Input() titlex: string;
    @Input() titley: string;
    @Input() tension: number = 0.77;
    @Input() legendpos: LegendPosition = 'bottom';
    @Input() steped: boolean = false;
    @Input() legx: boolean = true;
    @Input() legy: boolean = true;
    @Input() min: number = 0;
    @Input() max: number = 120;

    private chart: am4charts.XYChart3D;
    public image;
    @ViewChild('chartdiv') chartdiv: ElementRef;

    constructor() {}

    ngOnInit(): void {
        setTimeout(() => {
            this.create();
        }, 100);
    }

    ngOnDestroy() {
        this.chart.dispose();
    }

    async getImage() {
        let options = this.chart.exporting.getFormatOptions('jpg');
        options.minWidth = 2600;
        options.minHeight = 1700;
        options.maxWidth = 2600;
        options.maxHeight = 1700;
        return await this.chart.exporting.getImage('jpg');
    }

    create() {
        this.chart = am4core.create(this.id, am4charts.XYChart3D);
        this.chart.data = this.data;
        this.chart.colors.list = colors_list;
        if (this.colors) {
            this.chart.colors.list = this.colors.map((color) =>
                am4core.color(color)
            );
        }
        this.chart.colors.step = 1;
        this.chart.logo.height = -15000;

        // Create axes
        let categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = 'x';
        // categoryAxis.renderer.grid.template.opacity = 0;
        categoryAxis.renderer.grid.template.location = 100 / this.data.length;
        categoryAxis.renderer.minGridDistance = 20;
        categoryAxis.renderer.inside = false;
        categoryAxis.renderer.padding(12, 0, 8, 0);
        categoryAxis.renderer.labels.template.disabled = !this.legx;
        categoryAxis.renderer.labels.template.fontSize = 14;
        // categoryAxis.fontSize = 8;

        let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        // valueAxis.renderer.grid.template.opacity = 0;
        valueAxis.renderer.ticks.template.strokeOpacity = 0.5;
        valueAxis.renderer.ticks.template.stroke = am4core.color('#495C43');
        valueAxis.renderer.ticks.template.length = 20;
        valueAxis.renderer.line.strokeOpacity = 0.5;
        valueAxis.renderer.baseGrid.disabled = true;
        valueAxis.renderer.labels.template.disabled = false;
        valueAxis.renderer.minGridDistance = 25;
        valueAxis.renderer.inside = false;
        valueAxis.min = this.min;
        valueAxis.max = this.max;
        valueAxis.renderer.labels.template.disabled = !this.legy;

        if (this.titlex) categoryAxis.title.text = this.titlex;
        if (this.titley) valueAxis.title.text = this.titley;

        for (let l of this.labels) {
            this.generateSerie('x', l);
        }
        // Legend
        this.chart.legend = new am4charts.Legend();
        this.chart.legend.fontSize = 12;
        this.chart.legend.position = this.legendpos;
        this.chart.legend.marginBottom = 20;
        this.chart.legend.valueLabels.template.fontSize = 12;
        this.chart.scrollbarY = new am4core.Scrollbar();
        // this.chart.scrollbarX = new am4core.Scrollbar();
        this.chart.numberFormatter.numberFormat = '#.###,##';
        this.chart.exporting.menu = new am4core.ExportMenu();
        this.chart.exporting.menu.align = 'left';
        this.chart.exporting.menu.verticalAlign = 'top';
        this.chart.exporting.title = this.title;
        this.chart.exporting.menu.items = [
            {
                label: '...',
                menu: [
                    { type: 'png', label: 'PNG' },
                    { type: 'print', label: 'Imprimir' },
                ],
            },
        ];
        if (this.title) {
            let title = this.chart.titles.create();
            title.text = this.title;
            title.fontSize = 16;
            title.marginTop = 12;
            title.marginBottom = 12;
        }
        // this.chart.events.on("ready", () => {
        //   axisX.zoomToCategories(
        //     this.data[this.data.length - 50]['x'],
        //     this.data[this.data.length - 1]['x']
        //   );
        // });
        this.chart.scrollbarY.exportable = false;
        // this.chart.events.on('ready', () => {
        //     setTimeout(() => {
        //         this.chart.exporting.getImage('jpg').then((res) => {
        //             this.output.emit(res);
        //         });
        //     }, 2000);
        // });
    }

    generateSerie(x, y) {
        let series;
        series = new am4charts.ColumnSeries3D();
        this.chart.series.push(series);
        series.name = y;
        series.dataFields.valueY = y;
        series.dataFields.categoryX = x;
        series.showOnInit = false;
        series.fontSize = 8;
        // series.tooltipText = "{value}"
        // series.tooltip.pointerOrientation = "vertical";
        series.sequencedInterpolation = true;
        series.stacked = true;
        series.columns.template.width = am4core.percent(60);
        series.columns.template.tooltipText = `[bold]{name}[/bold]\n[font-size:14px]{categoryX}: {valueY}`;

        if (y == 'Déficit') {
            let bullet1 = series.bullets.push(new am4charts.LabelBullet());
            bullet1.interactionsEnabled = false;
            bullet1.label.text = "{valueY.formatNumber('#.0')}%";
            bullet1.label.fill = am4core.color('#40b93c');
            // bullet1.locationY = 0.5;
            // bullet1.label.verticalCenter = "bottom";
            bullet1.label.dy = -27;
            bullet1.label.dx = 15;
            bullet1.label.fontSize = 14;
            bullet1.label.fontWeight = 'bold';
        }
    }
}
