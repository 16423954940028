<div id="search">
    <div table=true style="--cs: 1fr 200px">
        <div table=true style="--cs: 1fr 1fr">
            <div class="item">
                <span>ID. Amostra</span>
                <div class="input">
                    <input [(ngModel)]="term.idamostra" (keyup)="search()" />
                </div>
            </div>
            <div class="item">
                <span>Produtor</span>
                <div table=true style="--cs: 90px 1fr">
                    <div class="input">
                        <input matTooltip="Pressione F2 para buscar" [(ngModel)]="term.idcliente"
                            (blur)="searchcli(); search()" (keyup.f2)="searchcli(true)" />
                    </div>
                    <div class="input">
                        <input [(ngModel)]="term.cliente" disabled />
                    </div>
                </div>
            </div>
            <div class="item">
                <span>Solicitante</span>
                <div table=true style="--cs: 90px 1fr">
                    <div class="input">
                        <input matTooltip="Pressione F2 para buscar" [(ngModel)]="term.idsolicitante"
                            (blur)="searchsc(); search()" (keyup.f2)="searchsc(true)" />
                    </div>
                    <div class="input">
                        <input [(ngModel)]="term.solicitante" disabled />
                    </div>
                </div>
            </div>
            <div table=true style="--cs: 1fr 1fr">
                <div class="item">
                    <span>Data Inicial</span>
                    <div class="input">
                        <input type="date" [(ngModel)]="term.dtini" (blur)="search()" />
                    </div>
                </div>
                <div class="item">
                    <span>Data Final</span>
                    <div class="input">
                        <input type="date" [(ngModel)]="term.dtfim" (blur)="search()" />
                    </div>
                </div>
            </div>
        </div>
        <div color="sucess" class="button" table=true (click)="searchdb()">
            <mat-icon aria-hidden="false">search</mat-icon>
            <span>Buscar</span>
        </div>
    </div>
    <br>
    <div class="menvazio" *ngIf="data.length==0">
        <div class="label">
            Nenhum laudo localizado!
        </div>
    </div>
    <div class="table" *ngIf="data.length>0">
        <div class="toptable" table=true>
            <!-- <div class="label">
                ID
            </div> -->
            <div class="label">
                Produtor
            </div>
            <div class="label">
                ID Amostra
            </div>
            
            <div class="label">
                Solicitante
            </div>
            <div class="label">
                Data
            </div>
            <div class="label c">
                Status
            </div>
        </div>
        <div class="bodytable" table=true *ngFor="let item of data" (click)="select(item)">
            <!-- <div class="label">
                {{item.id}}
            </div> -->
            <div class="label">
                {{item.dscliente}}
            </div>
            <div class="label">
                {{item.idamostra}} 
            </div>
            <div class="label">
                {{item.dssolicitante}}
            </div>
            <div class="label">
                {{item.dtlaudo | date: 'dd/MM/yyyy'}}
            </div>
            <div class="label nopading jc">
                <icon icon="/assets/icons/primeira.svg" *ngIf="item.issegunda!='1'" width="32px" height="32px" margin="-2px"></icon>
                <icon icon="/assets/icons/segunda.svg" *ngIf="item.issegunda=='1'" width="32px" height="32px" margin="-2px"></icon>
            </div>
        </div>
    </div>
</div>