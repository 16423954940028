import { Component, OnInit } from '@angular/core';
import { Solicitante } from 'src/app/class/solicitante';
import { NovosolicitanteComponent } from 'src/app/register/novosolicitante/novosolicitante.component';
import { ConnectionService } from 'src/app/services/connection.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-listsolicitante',
  templateUrl: './listsolicitante.component.html',
  styleUrls: ['./listsolicitante.component.scss']
})
export class ListsolicitanteComponent implements OnInit {
  public loading: boolean = false
  public solicitantes: Solicitante[] = []
  constructor(
    private conn: ConnectionService,
    public util: UtilService
  ) {

  }

  ngOnInit(): void {
    this.carregar()
  }

  async carregar() {
    this.loading = true
    this.solicitantes = []
    this.solicitantes = Solicitante.fromJsonArray(await this.conn.getSolicitante().toPromise())
    this.loading = false
  }

  async edit(item) {
    this.util.dialog(NovosolicitanteComponent, item, "250px").then(() => {
      this.carregar()
    })
  }

}
