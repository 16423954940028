import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';

import { FichainicialComponent } from './pages/fichainicial/fichainicial.component';
import { MenuComponent } from './components/menu/menu.component';
import { IconComponent } from './components/icon/icon.component';
import { ListlaudosComponent } from './pages/listlaudos/listlaudos.component';
import { NovolaudoComponent } from './register/novolaudo/novolaudo.component';
import { NovolaudodetailComponent } from './register/novolaudodetail/novolaudodetail.component';
import { PickerComponent } from './components/picker/picker.component';
import { BuscaclienteComponent } from './search/buscacliente/buscacliente.component';
import { BuscasolicitanteComponent } from './search/buscasolicitante/buscasolicitante.component';
import { NovoclienteComponent } from './register/novocliente/novocliente.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { LoginComponent } from './pages/login/login.component';
import { ListclientesComponent } from './pages/listclientes/listclientes.component';
import { ListsolicitanteComponent } from './pages/listsolicitante/listsolicitante.component';
import { NovosolicitanteComponent } from './register/novosolicitante/novosolicitante.component';
import { NovaculturaComponent } from './register/novacultura/novacultura.component';
import { ListculturaComponent } from './pages/listcultura/listcultura.component';
import { BuscaculturaComponent } from './search/buscacultura/buscacultura.component';
import { NovolaudocalcsComponent } from './register/novolaudocalcs/novolaudocalcs.component';
import { NovolaudodtgraphComponent } from './register/novolaudodtgraph/novolaudodtgraph.component';
import { GraficoComponent } from './components/grafico/grafico.component';
import { GeralaudoComponent } from './register/geralaudo/geralaudo.component';
import { BuscausuarioComponent } from './search/buscausuario/buscausuario.component';
import { ListusuariosComponent } from './pages/listusuarios/listusuarios.component';
import { NovousuarioComponent } from './register/novousuario/novousuario.component';
import { BuscalaudoComponent } from './search/buscalaudo/buscalaudo.component';
import { LinechartComponent } from './components/linechart/linechart.component';
import { BarchartComponent } from './components/barchart/barchart.component';
import { OptionpaneComponent } from './components/optionpane/optionpane.component';
import { InterchartComponent } from './components/interchart/interchart.component';
import { LogolaudosComponent } from './register/logolaudos/logolaudos.component';
import { FileuploadComponent } from './components/fileupload/fileupload.component';
import { Percentchart1Component } from './components/percentchart1/percentchart1.component';
import { FabbuttonComponent } from './components/fabbutton/fabbutton.component';
import { SelectionComponent } from './pages/selection/selection.component';
import { PiechartComponent } from './components/piechart/piechart.component';
import { ListconsultorComponent } from './pages/listconsultor/listconsultor.component';
import { BuscaconsultorComponent } from './search/buscaconsultor/buscaconsultor.component';
import { NovoconsultorComponent } from './register/novoconsultor/novoconsultor.component';
import { ListlaudosfolhaComponent } from './pages/listlaudosfolha/listlaudosfolha.component';
import { CadfolhaComponent } from './pages/cadfolha/cadfolha.component';
import { KeyvaluePipe } from './pipes/keyvalue.pipe';
import { LaudofolhaComponent } from './register/laudofolha/laudofolha.component';
import { StackedcolumchartComponent } from './components/stackedcolumchart/stackedcolumchart.component';
import { NegativecolumchartComponent } from './components/negativecolumchart/negativecolumchart.component';
import { StackedbarchartComponent } from './components/stackedbarchart/stackedbarchart.component';

@NgModule({
    declarations: [
        AppComponent,
        LinechartComponent,
        FichainicialComponent,
        MenuComponent,
        ListlaudosComponent,
        FileuploadComponent,
        NovolaudoComponent,
        OptionpaneComponent,
        NovolaudodetailComponent,
        IconComponent,
        PickerComponent,
        BuscaclienteComponent,
        BuscasolicitanteComponent,
        NovoclienteComponent,
        SpinnerComponent,
        LoginComponent,
        ListclientesComponent,
        ListsolicitanteComponent,
        NovosolicitanteComponent,
        NovaculturaComponent,
        ListculturaComponent,
        BuscaculturaComponent,
        NovolaudocalcsComponent,
        NovolaudodtgraphComponent,
        GraficoComponent,
        GeralaudoComponent,
        BuscausuarioComponent,
        ListusuariosComponent,
        NovousuarioComponent,
        BuscalaudoComponent,
        BarchartComponent,
        InterchartComponent,
        LogolaudosComponent,
        Percentchart1Component,
        FabbuttonComponent,
        SelectionComponent,
        PiechartComponent,
        ListconsultorComponent,
        BuscaconsultorComponent,
        NovoconsultorComponent,
        ListlaudosfolhaComponent,
        CadfolhaComponent,
        KeyvaluePipe,
        LaudofolhaComponent,
        StackedcolumchartComponent,
        NegativecolumchartComponent,
        StackedbarchartComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        MatSlideToggleModule,
        HttpClientModule,
        FormsModule,
        MatAutocompleteModule,
        ChartsModule,
        MatIconModule,
        MatSnackBarModule,
        MatDatepickerModule,
        MatButtonModule,
        MatDialogModule,
        MatSelectModule,
        MatTooltipModule,
        BrowserAnimationsModule,
    ],
    providers: [],
    bootstrap: [AppComponent],
})
export class AppModule {}
