<h2 class='c'>Resultados Diagnósticos</h2>
<div class="section">
    Gráfigo de Macros e Micros
</div>
<br />
<div class="table">
    <br />
    <div class="toptable c" table=true style="--cs: 1fr">
        <div class="label">
            Macronutrientes
        </div>
    </div>
    <div class="toptable c" table=true style="--cs: 100px repeat(5,1fr)">
        <div class="label" *ngFor="let column of table17.columns">
            {{column}}
        </div>
    </div>
    <div table=true style="--rs: 1fr; --cs: 100px repeat(5,1fr) ">
        <div class="bodytable c" table=true style="--cs: 1fr ">
            <!-- <div class="label" *ngFor="let label of table17.labels">
                {{label}}
            </div> -->
            <div class="bodytable c" table=true style="--cs: 1fr ">
                <div class="inputtab" *ngFor="let label of table17.labels">
                    <input disabled [value]="label" />
                </div>
            </div>
        </div>
        <div class="bodytable c" table=true style="--cs: 1fr " *ngFor="let item of table17.items">
            <div class="inputtab" *ngFor="let k of table17.keys">
                <input type="number" disabled [(ngModel)]="calculo.t17[item][k]" />
            </div>
        </div>
    </div>
    <br />
    <div class="toptable c" table=true style="--cs: 1fr">
        <div class="label">
            Micronutrientes
        </div>
    </div>
    <div class="toptable c" table=true style="--cs: 100px repeat(5,1fr)">
        <div class="label" *ngFor="let column of table18.columns">
            {{column}}
        </div>
    </div>
    <div table=true style="--rs: 1fr; --cs: 100px repeat(5,1fr) ">
        <div class="bodytable c" table=true style="--cs: 1fr ">
            <!-- <div class="label" *ngFor="let label of table18.labels">
                {{label}}
            </div> -->
            <div class="bodytable c" table=true style="--cs: 1fr ">
                <div class="inputtab" *ngFor="let label of table18.labels">
                    <input disabled [value]="label" />
                </div>
            </div>
        </div>
        <div class="bodytable c" table=true style="--cs: 1fr " *ngFor="let item of table18.items">
            <div class="inputtab" *ngFor="let k of table18.keys">
                <input type="number" disabled [(ngModel)]="calculo.t18[item][k]" />
            </div>
        </div>
    </div>
    <br />
    <div class="toptable c" table=true style="--cs: 1fr">
        <div class="label">
            Correção de Solo
        </div>
    </div>
    <div class="toptable c" table=true style="--cs: 100px repeat(5,1fr)">
        <div class="label" *ngFor="let column of table19.columns">
            {{column}}
        </div>
    </div>
    <div table=true style="--cs: 100px repeat(5,1fr) ">
        <div class="bodytable c" table=true style="--cs: 1fr ">
            <div class="inputtab" *ngFor="let label of table19.labels">
                <input disabled [value]="label" />
            </div>
        </div>
        <div class="bodytable c" table=true style="--cs: 1fr " *ngFor="let item of table19.items">
            <div class="inputtab" *ngFor="let k of table19.keys">
                <input type="number" disabled [(ngModel)]="calculo.t19[item][k]" />
            </div>
        </div>
    </div>
    <br />
    <div class="section">
        Gráfico de Potencial Produtivo
    </div>
    <br />
    <div class="toptable c" table=true style="--cs: 1fr">
        <div class="label">
            Déficits
        </div>
    </div>
    <div class="toptable c" table=true style="--cs: repeat(10,1fr)">
        <div class="label" *ngFor="let column of table20.labels">
            {{column}}
        </div>
    </div>
    <div class="bodytable c" table=true style="--cs: repeat(10,1fr) ">
        <div class="inputtab" *ngFor="let key of table20.keys">
            <input type="number" disabled [(ngModel)]="calculo.t20[key]" />
        </div>
    </div>
    <br />
    <div class="section">
        Coeficientes de correção da matéria seca
    </div>
    <br />
    <div class="toptable c" table=true style="--cs: repeat(12,1fr)">
        <div class="label" *ngFor="let column of table22.labels">
            {{column}}
        </div>
    </div>
    <div class="bodytable c" table=true style="--cs: repeat(12,1fr) ">
        <div class="inputtab" *ngFor="let key of table22.keys">
            <input disabled [(ngModel)]="calculo.t22[key]" />
        </div>
    </div>
    <br />
    <div class="section">
        Potencial Produtivo ({{calculo.t22.dscult}})
    </div>
    <br />
    <div class="toptable c" table=true style="--cs: repeat(6,1fr)">
        <div class="label"></div>
        <div class="label" *ngFor="let column of table23.keys">
            {{column}}
        </div>
    </div>
    <div class="bodytable c" table=true style="--cs: repeat(6,1fr) ">
        <div class="inputtab">
            <input disabled value="Ideal" />
        </div>
        <div class="inputtab" *ngFor="let key of table23.keys">
            <input disabled [(ngModel)]="calculo.t23.ideal[key]" />
        </div>
    </div>
    <div class="bodytable c" table=true style="--cs: repeat(6,1fr) ">
        <div class="inputtab">
            <input disabled value="Atual" />
        </div>
        <div class="inputtab" *ngFor="let key of table23.keys">
            <input disabled [(ngModel)]="calculo.t23.atual[key]" />
        </div>
    </div>
    <br />
    <br />
    <div table=true style="--cs: 1fr 1fr 1fr 1fr; --rs: 1fr; column-gap: 4px;">
        <div></div>
        <div></div>
        <div color="sucess" class="button btblue" table=true (click)="back()">
            <mat-icon aria-hidden="false">replay</mat-icon>
            <span>Voltar</span>
        </div>
        <div color="sucess" class="button btgreen" table=true (click)="next()">
            <mat-icon aria-hidden="false">save</mat-icon>
            <span>Gerar Laudo</span>
        </div>
    </div>
</div>