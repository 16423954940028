<main id="selection">
    <div class="items">
        <div class="logo"></div>
        <h2>Bem-vindo ao BBF Laudos! Escolha uma opção para iniciar:</h2>
        <div class="menu">
            <div class="item" (click)="go('solo')">
                <div class="img" [style.background]="'url(/assets/icons/solo.svg)'"></div>
                <div class="label">BBF SOIL</div>
            </div>
            <div class="item" (click)="go('folha')">
                <div class="img" [style.background]="'url(/assets/icons/folha.svg)'"></div>
                <div class="label">BBF PLANT</div>
            </div>
        </div>
    </div>
</main>
