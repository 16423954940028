<div id="picker">
    <div class="header" table="true" style="--cs: 1fr 50px 50px">
        <span>{{ data.title }}</span>
        <mat-icon *ngIf="data.component" (click)="add()">note_add</mat-icon>
        <div *ngIf="!data.component"></div>
        <mat-icon (click)="close()">close</mat-icon>
    </div>
    <div class="struct">
        <buscacliente
        
            [data]="data.items"
            (sign)="select($event)"
            *ngIf="data.table == 'cliente'"
        ></buscacliente>
        <buscasolicitante
            [data]="data.items"
            (sign)="select($event)"
            *ngIf="data.table == 'solicitante'"
        >
        </buscasolicitante>
        <buscaconsultor
            [data]="data.items"
            (sign)="select($event)"
            *ngIf="data.table == 'consultor'"
        >
        </buscaconsultor>
    </div>
</div>
