import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Cliente } from 'src/app/class/cliente';
import { PickerComponent } from 'src/app/components/picker/picker.component';
import { ConnectionService } from 'src/app/services/connection.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
    selector: 'app-novocliente',
    templateUrl: './novocliente.component.html',
    styleUrls: ['./novocliente.component.scss'],
})
export class NovoclienteComponent implements OnInit {
    public cliente: Cliente = new Cliente();
    public loading: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<PickerComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public util: UtilService,
        private conn: ConnectionService
    ) {}

    ngOnInit(): void {
        if (this.data) {
            this.cliente = this.data;
        }
    }

    async close() {
        this.loading = true;
        this.util.clientes = Cliente.fromJsonArray(await this.conn.getCliente().toPromise());
        this.loading = false;
        this.dialogRef.close(this.util.clientes);
    }

    validar(): boolean {
        if (!this.cliente.dscliente) {
            this.util.alertDanger('Cliente é campo obrigatório!');
            return false;
        }
        return true;
    }

    async salvar() {
        if (!this.validar()) return;
        this.loading = true;
        try {
            let res: Cliente[] = Cliente.fromJsonArray(
                await this.conn.setCliente(this.cliente).toPromise()
            );
            console.log(res);
            if (res ? res.length > 0 : false) {
                if (res[0].id) {
                    this.cliente = res[0];
                } else {
                    this.util.alertDanger('Erro ao adicionar/editar cliente!');
                }
            } else {
                this.util.alertDanger('Erro ao adicionar/editar cliente!');
            }
        } catch {
            this.util.alertDanger('Erro ao adicionar/editar cliente!');
        }
        this.loading = false;
    }
}
