import { Component, Input, OnInit } from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { bbf_theme_inverse, colors_list } from '../theme/theme_graph';

am4core.useTheme(bbf_theme_inverse);

@Component({
    selector: 'stackedbarchart',
    templateUrl: './stackedbarchart.component.html',
    styleUrls: ['./stackedbarchart.component.scss'],
})
export class StackedbarchartComponent implements OnInit {
    @Input() id_chart;
    @Input() data = [];
    @Input() minY = 0;
    @Input() maxY = 100;
    @Input() rangeX = null;
    @Input() width = '647px';
    @Input() calculate = true;
    @Input() showAxisY = true;
    @Input() showLabels = true;
    @Input() inversed = false;
    @Input() legend = true;
    @Input() colorbullet = '#fff';
    @Input() centerbullet = true;
    @Input() adapterX;
    @Input() adapterY;
    @Input() color = colors_list.reverse();
    public chart: am4charts.XYChart;

    constructor() {}

    ngOnInit(): void {}

    ngAfterViewInit() {
        this.init();
    }

    async exportChart(size = '647px') {
        return new Promise((resolve, reject) => {
            let options = this.chart.exporting.getFormatOptions('png');
            options.minWidth = 2600;
            options.minHeight = 1700;
            options.maxWidth = 2600;
            options.maxHeight = 1700;
            let width = this.width;
            this.width = size;
            this.chart.reinit();
            setTimeout(async () => {
                let img = await this.chart.exporting.getImage('png');
                resolve(img);
                this.width = width;
                this.chart.reinit();
            }, 1200);
        });
    }

    init() {
        this.chart = am4core.create(this.id_chart, am4charts.XYChart);
        this.chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
        this.chart.logo.height = -15000;
        this.chart.data = this.data;

        this.chart.colors.list = this.color;
        this.chart.padding(12, 12, 12, 12);
        if (this.legend) this.chart.legend = new am4charts.Legend();

        let categoryAxis = this.chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = 'category';
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minWidth = 10;
        categoryAxis.renderer.minGridDistance = 10;
        categoryAxis.renderer.inversed = this.inversed;
        categoryAxis.renderer.labels.template.fontSize = 16;

        if (this.adapterY) {
            categoryAxis.renderer.labels.template.adapter.add(
                'text',
                this.adapterY
            );
        }

        let valueAxis = this.chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.min = this.minY;
        valueAxis.max = this.maxY;
        valueAxis.strictMinMax = true;
        valueAxis.calculateTotals = this.calculate;
        valueAxis.renderer.minWidth = 10;
        valueAxis.renderer.labels.template.disabled = !this.showAxisY;
        valueAxis.renderer.labels.template.fontWeight = '400';

        if (this.adapterX) {
            valueAxis.renderer.labels.template.adapter.add(
                'text',
                this.adapterX
            );
        }
        valueAxis.renderer.labels.template.fontSize = 16;

        for (let s in this.data[0]) {
            if (s != 'category') {
                this.generateSeries(s, s);
            }
        }

        if (this.rangeX != null) {
            var range = valueAxis.axisRanges.create();
            range.endValue = this.rangeX;
            range.grid.stroke = am4core.color('#91fc73');
            range.grid.location = 1;
            range.grid.strokeWidth = 2;
            range.grid.strokeOpacity = 1;
            range.grid.above = true;
            range.axisFill.fillOpacity = 0.2;
            range.grid.strokeOpacity = 1;
        }
    }

    generateSeries(name, valueY, category = 'category') {
        let series = this.chart.series.push(new am4charts.ColumnSeries());
        series.columns.template.width = am4core.percent(80);

        series.name = name;
        series.dataFields.categoryY = category;
        series.dataFields.valueX = valueY;
        if (this.calculate) {
            series.dataFields.valueXShow = 'totalPercent';
            series.stacked = true;
            series.columns.template.tooltipText =
                "{name}: {valueX.totalPercent.formatNumber('#.00')}%";
        } else {
            series.columns.template.tooltipText =
                "{name}: {valueX.formatNumber('#.00')}";
        }
        series.dataItems.template.locations.categoryY = 0.5;
        series.tooltip.pointerOrientation = 'vertical';

        if (this.showLabels) {
            let bullet = series.bullets.push(new am4charts.LabelBullet());
            bullet.interactionsEnabled = false;
            if (this.calculate) {
                bullet.label.text = "{valueX.totalPercent.formatNumber('#.')}";
            } else {
                bullet.label.text = "{valueX.formatNumber('#.')}";
            }
            bullet.label.fill = am4core.color(this.colorbullet);
            bullet.label.fontSize = 14;
            bullet.label.fontWeight = '600';
            if (this.centerbullet) {
                bullet.locationX = 0.5;
                // bullet.label.dx = 12;
            } else {
                bullet.label.dx = 12;
            }
        }
    }
}
