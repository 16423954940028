<div>
    <div class="labelupload"><ng-content></ng-content></div>
    <div class="uploaditems">
        <div class="uploadwarning">
            <span *ngIf="!image">Nenhum arquivo preaparado ainda...</span>
            <div class="image" *ngIf="image">
                <img [src]='image' />
            </div>
        </div>
    </div>
    <div class="buttonupload" (click)="openFile()">
        <span>Selecionar Imagem</span>
    </div>
</div>
