import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { Laudo } from 'src/app/class/laudo';
import { UtilService } from 'src/app/services/util.service';
import { PdfgenerateService } from 'src/app/services/pdfgenerate.service';
import * as moment from 'moment';
import { LinechartComponent } from 'src/app/components/linechart/linechart.component';
import { BarchartComponent } from 'src/app/components/barchart/barchart.component';
import { ConnectionService } from 'src/app/services/connection.service';
import { PiechartComponent } from 'src/app/components/piechart/piechart.component';

@Component({
    selector: 'geralaudo',
    templateUrl: './geralaudo.component.html',
    styleUrls: ['./geralaudo.component.scss'],
})
export class GeralaudoComponent implements OnInit {
    public labels1: any = [];
    public labels2: Label[] = [];
    public labels3: Label[] = [];
    public labels4: Label[] = [];
    public labelsmacro: any = {};
    public labelsmicro: any = {};
    public macro: any = ['ca1', 'mg1', 'k1', 'p1', 's1'];
    public micro: any = ['fe1', 'mn1', 'cu1', 'zn1', 'b1'];
    public datapotprod: any[] = [];
    public datacorsolo: any[] = [];
    public datamacronutri: any[] = [];
    public datamicronutri: any[] = [];
    public loadingb = false;
    public obs = '';

    @Input() public calculo: any;
    @Input() public laudo: Laudo = new Laudo();
    @Output() event: EventEmitter<any> = new EventEmitter();
    @Output() save: EventEmitter<any> = new EventEmitter();

    @ViewChild('gcorsolo') gcorsolo: LinechartComponent;
    @ViewChild('gpotprod') gpotprod: PiechartComponent;
    @ViewChild('gmicronutri') gmicronutri: BarchartComponent;
    @ViewChild('gmacronutri') gmacronutri: BarchartComponent;

    public loading = false;

    constructor(
        public util: UtilService,
        private conn: ConnectionService,
        private pdf: PdfgenerateService
    ) {}

    ngOnInit(): void {
        this.getData();
        document.getElementById('struct').scrollTo(0, 0);
    }

    async getData() {
        this.loading = true;

        this.obs = this.laudo.observacoes;

        this.labels2 = ['0', '30', '60', '90', '120', '150'];
        this.labels3 = ['Ca', 'K', 'Mg', 'P', 'S'];
        this.labelsmacro = {
            ca1: 'Cálcio',
            k1: 'Potássio',
            mg1: 'Magnésio',
            p1: 'Fósforo',
            s1: 'Enxofre',
        };
        this.labelsmicro = {
            fe1: 'Ferro',
            mn1: 'Manganês',
            cu1: 'Cobre',
            zn1: 'Zinco',
            b1: 'Boro',
        };
        this.labels4 = ['B', 'Cu', 'Fe', 'Mn', 'Zn'];
        let values: any = [
            ...Object.values(this.calculo.t23.atual),
            ...Object.values(this.calculo.t23.ideal),
        ];
        // let maxv23 = Math.max(...values);
        // for (let k in this.calculo.t23.ideal) {
        //     this.datapotprod.push({
        //         x: k,
        //         Ideal: (this.calculo.t23.ideal[k] * 100) / maxv23,
        //         Atual: (this.calculo.t23.atual[k] * 100) / maxv23,
        //     });
        // }

        // for (let k in this.calculo.t23.ideal) {
        //teste debgus 2
        const getMedia = (values, category = 'Médias', ponderada = false) => {
            let item = {
                category: category,
                value: 0,
                full: 100,
            };

            let atual = 0;
            let ideal = 0;

            for (let v in values.atual) {
                try {
                    if (ponderada) {
                        atual += Number(v) * Number(values.atual[v]);
                        ideal += Number(v) * Number(values.ideal[v]);
                    }else{
                        atual += Number(values.atual[v]);
                        ideal += Number(values.ideal[v]);
                    }
                } catch (error) {}
            }
            item.value = (atual / ideal) * 100;
            return item;
        };
        this.datapotprod.push(
            getMedia(this.calculo.t23, 'Potencial Produtivo')
        );
        // this.datapotprod.push({
        //     category: '120',
        //     value:
        //         (this.calculo.t23.atual['120'] /
        //             this.calculo.t23.ideal['120']) *
        //         100,
        //     full: 100,
        // });
        // }

        let i = 0;
        this.labels1 = {
            ca1: 'Cálcio',
            mg1: 'Magnésio',
            p1: 'Fósforo',
            k1: 'Potássio',
        };
        for (let k of Object.keys(this.labels1)) {
            this.datacorsolo.push({
                x: this.labels1[k],
                Déficit: this.calculo.t19.deficit[k],
                Atual: this.calculo.t19.sitatual[k],
            });
            i++;
        }

        for (let k of this.macro) {
            this.datamacronutri.push({
                x: this.labelsmacro[k],
                Déficit: this.calculo.t17.deficit[k],
                Atual: this.calculo.t17.sitatual[k],
            });
        }

        for (let k of this.micro) {
            this.datamicronutri.push({
                x: this.labelsmicro[k],
                Déficit: this.calculo.t18.deficit[k],
                Atual: this.calculo.t18.sitatual[k],
            });
        }

        this.getPlIdeal();
        this.getProdAnt();
        this.getProdEst();

        this.calculo.data = moment().format('DD/MM/YYYY HH:mm:ss');
        this.calculo.idamostra = this.laudo.idamostra;
        this.calculo.dscliente = this.laudo.dscliente;
        this.calculo.issegunda = this.laudo.issegunda;
        this.calculo.dssolicitante = this.laudo.dssolicitante;
        this.calculo.dsculturaant = this.getCult(this.laudo.idculturaant);
        this.calculo.produtividadeant = this.laudo.produtividadeant;
        this.calculo.observacoes = this.laudo.observacoes;
        this.calculo.dscultura = this.getCultLaudo();
        this.calculo.rr = {};
        await this.util.sleep(3000);
        this.loading = false;
    }

    public getPlIdeal() {
        if (this.laudo.issegunda == '0') {
            this.calculo.pluvideal = this.calculo.t12.indplucorr;
            return this.calculo.t12.indplucorr;
        } else {
            this.calculo.pluvideal = this.calculo.t14.indplucorr;
            return this.calculo.t14.indplucorr;
        }
    }

    public getProdAnt() {
        if (this.laudo.issegunda == '0') {
            this.calculo.producaoant = 'Nenhuma';
            return 'Nenhuma';
        } else {
            this.calculo.producaoant = this.laudo.produtividadeant;
            return this.laudo.produtividadeant;
        }
    }

    public getProdEst() {
        if (this.laudo.issegunda == '0') {
            this.calculo.producaoest = this.laudo.producaoest;
            return this.laudo.producaoest;
        } else {
            this.calculo.producaoest = this.laudo.produtividadeest;
            return this.laudo.produtividadeest;
        }
    }

    public getLinesObs() {
        try {
            return this.laudo.observacoes.split('\n').length;
        } catch (error) {
            return 0;
        }
    }

    round(value) {
        return Number(value).toFixed(2);
    }

    abs(value) {
        return Math.abs(Number(this.getValue(value))).toFixed(2);
    }

    public clearLines() {
        try {
            this.laudo.observacoes = this.laudo.observacoes
                .split('\n')
                .filter((item, idx) => {
                    return idx < 14;
                })
                .join('\n');
        } catch (error) {}
    }

    async salvar() {
        this.loadingb = true;
        try {
            this.laudo.isativo = '1';
            this.laudo.isblock = '1';
            if (!this.laudo.observacoes) this.laudo.observacoes = '';
            let l = await this.conn.saveLaudo(this.laudo);
            console.log(l);

            if ('error' in l) {
                this.util.alertDanger(l['error']);
                this.laudo.observacoes = this.obs;
                this.calculo.observacoes = this.laudo.observacoes;
            } else {
                this.laudo.dtatualizacao = l[0].dtatualizacao;
                this.calculo.observacoes = this.laudo.observacoes;
            }
        } catch (error) {}
        this.loadingb = false;
    }

    back() {
        this.event.emit(4);
    }

    async print() {
        this.util.initSpin('Gerando PDF...');
        try {
            await this.salvar();
            this.calculo.observacoes = this.laudo.observacoes;
            this.calculo.imgmacro = await this.gmacronutri.getImage();
            this.calculo.imgmicro = await this.gmicronutri.getImage();
            this.calculo.imgpotprod = await this.gpotprod.getImage();
            this.calculo.imgcorsolo = await this.gcorsolo.getImage();
            console.log(this.calculo.imgpotprod);
            
            console.log('Imprimindo...');
            await this.util.sleep(2000);
            this.pdf.values = this.calculo;
            this.pdf.image = this.util.usinglogo
                ? this.util.getImageUser()
                : null;
            await this.pdf.generatePdf();
        } catch (e) {}
        this.util.stopSpin();
        if (this.util.getSaldoLaudos() > 0) this.save.emit(1);
        else this.util.go('listlaudos');
    }

    getCult(id) {
        try {
            return this.util.culturas.find((item) => item.id == id).dscultura;
        } catch {
            return 'Nenhuma';
        }
    }

    getCultLaudo() {
        try {
            this.calculo.prodestimada =
                this.laudo.issegunda == '1'
                    ? this.laudo.produtividadeest
                    : this.laudo.producaoest;
            return this.laudo.issegunda == '1'
                ? this.getCult(this.laudo.idsegcultura)
                : this.getCult(this.laudo.idcultura);
        } catch {
            return 'Nenhuma';
        }
    }

    verAlg(item) {
        try {
            return String(item).toUpperCase().indexOf('ALGOD') > -1;
        } catch (error) {
            return false;
        }
    }

    getValue(label) {
        if (this.laudo.issegunda == '0' || !this.laudo.issegunda) {
            this.calculo.rr[label] = this.calculo.t4[label];
            return this.calculo.t4[label];
        } else this.calculo.rr[label] = this.calculo.t11[label];
        return this.calculo.t11[label];
    }
}
