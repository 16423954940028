import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Cliente } from 'src/app/class/cliente';
import { Laudo } from 'src/app/class/laudo';
import { Solicitante } from 'src/app/class/solicitante';
import { PickerComponent } from 'src/app/components/picker/picker.component';
import { NovoclienteComponent } from 'src/app/register/novocliente/novocliente.component';
import { NovosolicitanteComponent } from 'src/app/register/novosolicitante/novosolicitante.component';
import { ConnectionService } from 'src/app/services/connection.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'buscalaudo',
  templateUrl: './buscalaudo.component.html',
  styleUrls: ['./buscalaudo.component.scss']
})
export class BuscalaudoComponent implements OnInit {
  @Input() data: Laudo[] = []
  @Output() sign = new EventEmitter();
  private list: Laudo[] = []

  public term = {
    idamostra: null,
    idcliente: null,
    cliente: null,
    idsolicitante: null,
    solicitante: null,
    dtini: null,
    dtfim: null
  };

  constructor(
    private util: UtilService,
    private conn: ConnectionService
  ) { }

  ngOnInit(): void {
    this.list = this.data
    document.addEventListener('keydown', (evt) => {
      if (evt.key == 'Enter') {
        this.search()
      }
    })
    this.valueInit()
  }

  async valueInit() {
    this.util.initSpin("Buscando dados...")
    this.util.solicitantes = Solicitante.fromJsonArray(await this.conn.getSolicitante().toPromise())
    this.util.clientes = Cliente.fromJsonArray(await this.conn.getCliente().toPromise())
    this.util.stopSpin()
  }

  public select(item) {
    this.sign.emit(item)
  }

  

  search() {
    this.data = this.list.filter((item) => {
      return this.compare(item)
    });
  }

  async searchdb() {
    this.util.initSpin("Consultando Laudos...")
    try {
      let value = []
      for (let k in this.term) {
        if (this.term[k])
          value.push(`${k}=${this.term[k]}`)
      }
      this.data = Laudo.fromJsonArray(await this.conn.getLaudos(encodeURI(value.join("&"))).toPromise()).reverse()
      console.log(this.data);
    }
    catch(_){
      console.log(_);
      console.log("Erro ao buscar laudos no banco de dados...");
    }
    this.util.stopSpin()
  }

  async searchcli(form = false) {
    this.util.initSpin("Consultando clientes...")
    try {
      if (form) {
        let d: Cliente = await this.util.dialog(PickerComponent,
          {
            title: "Selecionar Cliente",
            items: this.util.clientes,
            table: "cliente",
            component: NovoclienteComponent
          }
        )
        if (d) {
          this.term.idcliente = d.id
          this.term.cliente = d.dscliente
        }
      } else {
        try {
          this.term.cliente = this.util.clientes.find(item => item.id == this.term.idcliente).dscliente
          if (!this.term.cliente) {
            this.term.idcliente = null
            this.term.cliente = null
          }
        } catch {
          this.term.idcliente = null
          this.term.cliente = null
        }
      }
    }
    catch (_) {

    }
    this.util.stopSpin()
  }

  async searchsc(form = false) {
    this.util.initSpin("Consultando Solicitantes...")
    try {
      if (form) {
        let d: Solicitante = await this.util.dialog(PickerComponent,
          {
            title: "Selecionar Solicitantes",
            items: this.util.solicitantes,
            table: "solicitante",
            component: NovosolicitanteComponent
          }
        )
        if (d) {
          this.term.idsolicitante = d.id
          this.term.solicitante = d.dssolicitante
        }
      } else {
        try {
          this.term.solicitante = this.util.solicitantes.find(item => item.id == this.term.idsolicitante).dssolicitante
          if (!this.term.solicitante) {
            this.term.idsolicitante = null
            this.term.solicitante = null
          }
        } catch {
          this.term.idsolicitante = null
          this.term.solicitante = null
        }
      }
    }
    catch (_) {

    }
    this.util.stopSpin()
  }



  compare(item: Laudo) {
    try {
      return (
        (this.term.idamostra ? item.idamostra.toUpperCase().indexOf(this.term.idamostra.toUpperCase()) > -1 : true) &&
        (this.term.cliente ? item.dscliente.toUpperCase().indexOf(this.term.cliente.toUpperCase()) > -1 : true) &&
        (this.term.solicitante ? item.dssolicitante.toUpperCase().indexOf(this.term.solicitante.toUpperCase()) > -1 : true) &&
        this.util.compareDate(this.term.dtini, this.term.dtfim, item.dtlaudo)
      )
    }
    catch (_) {
      return false
    }
  }


}
