import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConnectionService } from 'src/app/services/connection.service';

@Component({
  selector: 'app-optionpane',
  templateUrl: './optionpane.component.html',
  styleUrls: ['./optionpane.component.scss']
})
export class OptionpaneComponent implements OnInit {
  public loading = false;

  constructor(
      public dialogRef: MatDialogRef<OptionpaneComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private conn: ConnectionService
  ) {}

  ngOnInit(): void {
  }

  close(value){
    this.dialogRef.close(value)
  }

}
