import { Component, OnInit } from '@angular/core';
import { LaudoPlanta } from 'src/app/class/laudoplanta';
import { ESTADOS, getCidades } from 'src/app/class/constants';
import { UtilService } from 'src/app/services/util.service';
import { Cliente } from 'src/app/class/cliente';
import { PickerComponent } from 'src/app/components/picker/picker.component';
import { NovoclienteComponent } from 'src/app/register/novocliente/novocliente.component';
import { Solicitante } from 'src/app/class/solicitante';
import { NovosolicitanteComponent } from 'src/app/register/novosolicitante/novosolicitante.component';
import { Consultor } from 'src/app/class/consultor';
import { NovoconsultorComponent } from 'src/app/register/novoconsultor/novoconsultor.component';
import { ConnectionService } from 'src/app/services/connection.service';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-cadfolha',
    templateUrl: './cadfolha.component.html',
    styleUrls: ['./cadfolha.component.scss'],
})
export class CadfolhaComponent implements OnInit {
    public loading: boolean = false;
    public laudo: LaudoPlanta = { nutrientes: {} };
    public municipios: any[] = [];
    public municipiosaux: any[] = [];
    public estados: any[] = ESTADOS;
    public estadosaux: any[] = ESTADOS;
    public culturas: any[] = [];
    public dias = ['15', '30', '45', '60', '75', '90', '105', '120'];
    public macronutrientes = {
        n: 'N',
        p: 'P',
        k: 'K',
        ca: 'Ca',
        mg: 'Mg',
        s: 'S',
    };
    public micronutrientes = { fe: 'Fe', b: 'B', mn: 'Mn', zn: 'Zn', cu: 'Cu', mo: 'Mo' };
    public islaudo: boolean = false;
    public calculo: any;

    constructor(
        public util: UtilService,
        private conn: ConnectionService,
        private route: ActivatedRoute
    ) {
        this.culturas = this.util.culturas;
        Object.keys(this.macronutrientes).forEach(
            (item) => (this.laudo.nutrientes[item] = 0)
        );
        Object.keys(this.micronutrientes).forEach(
            (item) => (this.laudo.nutrientes[item] = 0)
        );
        this.laudo.dtlaudo = moment().format('YYYY-MM-DD');
        this.preencher();
    }

    ngAfterViewInit() {
        this.util.focus('idcliente');
    }

    ngOnInit(): void {}

    async preencher() {
        this.loading = true;
        try {
            const id = this.route.snapshot.paramMap.get('id');
            console.log(id);
            if (id) {
                let res: any = await this.conn.getLaudoPlantaById(id);
                console.log(res);
                this.laudo = res.laudo;
                this.laudo.dscultura = this.util.culturas.find(
                    (item) => item.id == this.laudo.idcultura
                ).dscultura;
                res.laudo.dscultura = this.laudo.dscultura;
                this.calculo = res;
                console.log(this.laudo);
                this.islaudo = true;
            } else {
                this.loading = false;
                if (this.util.getSaldoLaudos() <= 0 && !this.laudo.id) {
                    console.log('Não há saldo de laudos!');
                    this.util.go('listlaudosfolha');
                    this.util
                        .optionPane(
                            'Atenção!',
                            'Você não possui saldo de laudos, entre em contato com o administrador para adquirir novos laudos!',
                            [{ value: 'ok', name: 'OK' }]
                        )
                        .then((_) => {});
                }
            }
        } catch (error) {}
        this.loading = false;
    }

    duplicar() {
        this.laudo.dtlaudo = moment().format('YYYY-MM-DD');
        delete this.laudo.id;
        delete this.laudo.observacoes;
        this.islaudo = false;
    }

    async pickCliente() {
        let d: Cliente = await this.util.dialog(PickerComponent, {
            title: 'Selecionar Cliente',
            items: this.util.clientes,
            table: 'cliente',
            component: NovoclienteComponent,
        });
        if (d) {
            this.laudo.idcliente = d.id;
            this.laudo.dscliente = d.dscliente;
        }
    }

    async lostFocusCliente() {
        try {
            this.laudo.dscliente = this.util.clientes.find(
                (item) => item.id == this.laudo.idcliente
            ).dscliente;
            if (!this.laudo.dscliente) {
                this.laudo.idcliente = null;
                this.laudo.dscliente = null;
            }
        } catch {
            this.laudo.idcliente = null;
            this.laudo.dscliente = null;
        }
    }

    async pickSolicitante() {
        let d: Solicitante = await this.util.dialog(PickerComponent, {
            title: 'Selecionar Solicitante',
            items: this.util.solicitantes,
            table: 'solicitante',
            component: NovosolicitanteComponent,
        });
        if (d) {
            this.laudo.idsolicitante = d.id;
            this.laudo.dssolicitante = d.dssolicitante;
        }
    }

    async lostFocusSolicitante() {
        try {
            this.laudo.dssolicitante = this.util.solicitantes.find(
                (item) => item.id == this.laudo.idsolicitante
            ).dssolicitante;
            if (!this.laudo.dssolicitante) {
                this.laudo.idsolicitante = null;
                this.laudo.dssolicitante = null;
            }
        } catch {
            this.laudo.idsolicitante = null;
            this.laudo.dssolicitante = null;
        }
    }

    async pickConsultor() {
        let d: Consultor = await this.util.dialog(PickerComponent, {
            title: 'Selecionar Consultor',
            items: this.util.consultores,
            table: 'consultor',
            component: NovoconsultorComponent,
        });
        if (d) {
            this.laudo.idconsultor = d.id;
            this.laudo.dsconsultor = d.dsconsultor;
        }
    }

    async lostFocusConsultor() {
        try {
            this.laudo.dsconsultor = this.util.consultores.find(
                (item) => item.id == this.laudo.idconsultor
            ).dsconsultor;
            if (!this.laudo.dsconsultor) {
                this.laudo.idconsultor = null;
                this.laudo.dsconsultor = null;
            }
        } catch {
            this.laudo.idconsultor = null;
            this.laudo.dsconsultor = null;
        }
    }

    async checkCidade() {
        this.laudo.municipio = this.municipios.find((item) =>
            String(item)
                .toUpperCase()
                .includes(String(this.laudo.municipio).toUpperCase())
        );
    }

    filterEstado() {
        this.estados = this.estadosaux.filter((item) =>
            String(item)
                .toUpperCase()
                .includes(String(this.laudo.estado).toUpperCase())
        );
    }

    filterCidades() {
        this.municipios = this.municipiosaux.filter((item) =>
            String(item)
                .toUpperCase()
                .includes(String(this.laudo.municipio).toUpperCase())
        );
    }

    setCultura(evt) {
        try {
            this.laudo.cdcultura = this.culturas.find(
                (item) => item.id == this.laudo.idcultura
            ).cdcultura;
            console.log(this.laudo.cdcultura);
        } catch (error) {}
    }

    async checkEstado() {
        this.laudo.estado = this.estados.find((item) =>
            String(item)
                .toUpperCase()
                .includes(String(this.laudo.estado).toUpperCase())
        );
        setTimeout(() => {
            if (this.laudo.estado) {
                this.municipios = getCidades(this.laudo.estado);
                this.municipiosaux = this.municipios;
            }
        }, 200);
    }

    validar() {
        if (!this.laudo.idcliente) {
            this.util.focus('idcliente');
            this.util.alertDanger('Proprietário é obrigatório!');
            return false;
        } else if (!this.laudo.idsolicitante) {
            this.util.focus('idsolicitante');
            this.util.alertDanger('Solicitante é obrigatório!');
            return false;
        } else if (!this.laudo.idconsultor) {
            this.util.focus('idconsultor');
            this.util.alertDanger('Consultor é obrigatório!');
            return false;
        } else if (!this.laudo.talhao) {
            this.util.focus('talhao');
            this.util.alertDanger('Talhão é obrigatório!');
            return false;
        } else if (!this.laudo.area) {
            this.util.focus('area');
            this.util.alertDanger('Área é obrigatório!');
            return false;
        } else if (!this.laudo.dias) {
            this.util.focus('dias');
            this.util.alertDanger('Dias é obrigatório!');
            return false;
        } else if (!this.laudo.estado) {
            this.util.focus('estado');
            this.util.alertDanger('Estado é obrigatório!');
            return false;
        } else if (!this.laudo.municipio) {
            this.util.focus('municipio');
            this.util.alertDanger('Município é obrigatório!');
            return false;
        } else if (!this.laudo.idcultura) {
            this.util.focus('idcultura');
            this.util.alertDanger('Cultura é obrigatório!');
            return false;
        }

        return true;
    }

    async salvar() {
        this.loading = true;
        try {
            if (this.validar()) {
                console.log(this.laudo);
                delete this.laudo.dtatualizacao;
                this.laudo.hashcode = this.util.getHascode();
                this.laudo.iduser = this.util.getIdUser();
                let res: any = await this.conn.saveLaudoPlanta(this.laudo);
                console.log(res);
                this.laudo = res.laudo;
                this.laudo.dscultura = this.util.culturas.find(
                    (item) => item.id == this.laudo.idcultura
                ).dscultura;
                res.laudo.dscultura = this.laudo.dscultura;
                this.calculo = res;
                this.islaudo = true;
                let sldlaudo: any = await this.conn.getSaldo().toPromise();
                this.util.setSaldoLaudos(sldlaudo['saldo']);
            }
        } catch (error) {}
        this.loading = false;
    }
}
