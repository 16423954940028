<menu></menu>
<div id="container">
    <div class="form">
        <div class="header">
            Listagem de Laudos
        </div>
        <div class="struct" id="struct">
            <buscalaudo *ngIf="!loading" [data]="list" (sign)="edit($event)"></buscalaudo>
        </div>
    </div>
</div>
<spinner *ngIf="loading"></spinner>