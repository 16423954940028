import { Component, OnInit } from '@angular/core';
import { Cliente } from 'src/app/class/cliente';
import { Consultor } from 'src/app/class/consultor';
import { Cultura } from 'src/app/class/cultura';
import { Solicitante } from 'src/app/class/solicitante';
import { User } from 'src/app/class/user';
import { ConnectionService } from 'src/app/services/connection.service';
import { UtilService } from 'src/app/services/util.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    public loading: boolean = false;
    public user: string = '';
    public senha: string = '';
    public version: any;

    constructor(private conn: ConnectionService, private util: UtilService) {}

    ngOnInit(): void {
        this.getVersion();
    }

    get server(){
        return environment.SERVERNAME
    }

    async getVersion() {
        const res: any = await this.conn.getVersion();
        this.version = String(res.version).split("").join(".")
    }

    async login() {
        this.loading = true;
        let res: User = User.fromJson(
            await this.conn.login(this.user, this.senha).toPromise()
        );
        console.log(res);
        if (res) {
            if (res.isativo == '0') {
                this.loading = false;
                this.util.alertDanger('Usuário Inativo!');
                return;
            }
            if (res.id) {
                this.util.setUser(res);
                this.util.culturas = Cultura.fromJsonArray(
                    await this.conn.getCultura().toPromise()
                );
                this.util.clientes = Cliente.fromJsonArray(
                    await this.conn.getCliente().toPromise()
                );
                this.util.solicitantes = Solicitante.fromJsonArray(
                    await this.conn.getSolicitante().toPromise()
                );
                this.util.consultores = Consultor.fromJsonArray(
                    await this.conn.getConsultor().toPromise()
                );
                this.util.go('selection');
            } else {
                this.util.alertDanger(res['error']);
            }
        }
        this.loading = false;
    }
}
