export class Generic {
    static fromJson(this, json) {
        let data: typeof this = new this();
        for (let key in json) {
            data[key] = json[key];
        }
        return data;
    }

    static fromJsonArray(this, arr) {
        let dtarr: typeof this[] = [];
        for (let i = 0; i < arr.length; i++) {
            dtarr.push(this.fromJson(arr[i]));
        }
        return dtarr;
    }

    toJson(this) {
        let js: any = {};
        for (let k in this) {
            if (this[k]) js[k] = this[k];
        }
        return js;
    }
}
