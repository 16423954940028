import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { Laudo } from 'src/app/class/laudo';
import { ConnectionService } from 'src/app/services/connection.service';
import { UtilService } from 'src/app/services/util.service';
import * as moment from 'moment';

@Component({
    selector: 'novolaudodetail',
    templateUrl: './novolaudodetail.component.html',
    styleUrls: ['./novolaudodetail.component.scss'],
})
export class NovolaudodetailComponent implements OnInit {
    @Output() event: EventEmitter<any> = new EventEmitter();
    @Output() evtcalc: EventEmitter<any> = new EventEmitter();

    @Input() laudo: Laudo = new Laudo();
    public waiting: boolean = false;

    /* CONTROLLERS */
    @ViewChild('al1') al1: ElementRef;
    @ViewChild('b2') b2: ElementRef;
    @ViewChild('b3') b3: ElementRef;
    @ViewChild('ca1') ca1: ElementRef;
    @ViewChild('ca3') ca3: ElementRef;
    @ViewChild('cadesejado') cadesejado: ElementRef;
    @ViewChild('ctc1') ctc1: ElementRef;
    @ViewChild('cu2') cu2: ElementRef;
    @ViewChild('cu3') cu3: ElementRef;
    @ViewChild('fe2') fe2: ElementRef;
    @ViewChild('fe3') fe3: ElementRef;
    @ViewChild('k1') k1: ElementRef;
    @ViewChild('k3') k3: ElementRef;
    @ViewChild('kdesejado') kdesejado: ElementRef;
    @ViewChild('mg1') mg1: ElementRef;
    @ViewChild('mg3') mg3: ElementRef;
    @ViewChild('mgdesejado') mgdesejado: ElementRef;
    @ViewChild('mn2') mn2: ElementRef;
    @ViewChild('mn3') mn3: ElementRef;
    @ViewChild('p2') p2: ElementRef;
    @ViewChild('p3') p3: ElementRef;
    @ViewChild('prem2') prem2: ElementRef;
    @ViewChild('s2') s2: ElementRef;
    @ViewChild('s3') s3: ElementRef;
    @ViewChild('vrdesejado') vrdesejado: ElementRef;
    @ViewChild('zn2') zn2: ElementRef;
    @ViewChild('zn3') zn3: ElementRef;

    constructor(private conn: ConnectionService, public util: UtilService) {}

    ngOnInit(): void {
        setTimeout(() => {
            document.getElementById('struct').scrollTo(0, 0);
        }, 50);
        setTimeout(() => {
            document.getElementById('ca1').focus();
        }, 100);
    }

    back() {
        this.event.emit(1);
    }

    async calc() {
        this.evtcalc.emit();
    }

    go() {
        this.evtcalc.emit();
        this.event.emit(3);
    }

    // async save() {
    //     this.waiting = true;
    //     try {
    //         this.laudo = await this.conn.saveLaudo(this.laudo);
    //     } catch {
    //         console.log('Erro ao salvar!');
    //         this.util.alertDanger('Erro ao salvar!');
    //     }
    //     this.waiting = false;
    // }
}
