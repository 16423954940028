import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Cultura } from 'src/app/class/cultura';
import { PickerComponent } from 'src/app/components/picker/picker.component';
import { ConnectionService } from 'src/app/services/connection.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-novacultura',
  templateUrl: './novacultura.component.html',
  styleUrls: ['./novacultura.component.scss']
})
export class NovaculturaComponent implements OnInit {
  public cultura: Cultura = new Cultura()
  public loading:boolean =  false

  constructor(
    public dialogRef: MatDialogRef<PickerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public util: UtilService,
    private conn: ConnectionService
  ) {

  }

  ngOnInit(): void {
    if (this.data) {
      this.cultura = this.data
    }
  }

  close() {
    this.dialogRef.close();
  }

  validar(): boolean {
    if (!this.cultura.dscultura) {
      this.util.alertDanger("Cultura é campo obrigatório!")
      return false
    }
    return true
  }

  async salvar() {
    if (!this.validar()) return;
    this.loading = true
    try {
      let res: Cultura[] = Cultura.fromJsonArray(await this.conn.setCultura(this.cultura).toPromise())
      console.log(res);
      if (res ? res.length > 0 : false) {
        if (res[0].id) {
          this.cultura = res[0]
        } else {
          this.util.alertDanger("Erro ao adicionar/editar Cultura!")
        }
      } else {
        this.util.alertDanger("Erro ao adicionar/editar Cultura!")
      }
    } catch {
      this.util.alertDanger("Erro ao adicionar/editar Cultura!")
    }
    this.loading = false
  }


}