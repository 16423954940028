import * as am4core from '@amcharts/amcharts4/core';

export const bbf_theme = (target) => {
    if (target instanceof am4core.ColorSet) {
        target.list = colors_list;
    }
};

export const bbf_theme_inverse = (target) => {
    if (target instanceof am4core.ColorSet) {
        target.list = colors_list.reverse();
    }
};

export const colors_list = [am4core.color('#312783'), am4core.color('#3AAA35')];

export const colors_list_unique = [am4core.color('#312783')];
