import { Component, OnInit } from '@angular/core';
import { Cliente } from 'src/app/class/cliente';
import { Cultura } from 'src/app/class/cultura';
import { Solicitante } from 'src/app/class/solicitante';
import { ConnectionService } from 'src/app/services/connection.service';
import { UtilService } from 'src/app/services/util.service';
import { Laudo } from '../../class/laudo';

@Component({
    selector: 'app-fichainicial',
    templateUrl: './fichainicial.component.html',
    styleUrls: ['./fichainicial.component.scss'],
})
export class FichainicialComponent implements OnInit {
    public tab = 1;
    public laudo: Laudo = new Laudo();
    public backup: Laudo = new Laudo();
    public calculo: any;
    public loading = false;

    constructor(public util: UtilService, private conn: ConnectionService) {}

    ngOnInit(): void {
        if (this.util.laudo) {
            this.laudo = this.util.laudo;
            this.backup = this.util.laudo;
        }
        if (this.util.getSaldoLaudos() <= 0 && !this.laudo.id) {
            console.log('Não há saldo de laudos!');
            this.util.go('listlaudos');
            this.util
                .optionPane(
                    'Atenção!',
                    'Você não possui saldo de laudos, entre em contato com o administrador para adquirir novos laudos!',
                    [{ value: 'ok', name: 'OK' }]
                )
                .then((_) => {});
        }
        this.init();
    }

    async init() {
        this.util.initSpin('Carregando dados...');
        try {
            this.laudo.idsolicitante = this.util.user.idsolicitante;
            this.laudo.dssolicitante = this.util.solicitantes.find(
                (item) => item.id == this.laudo.idsolicitante
            ).dssolicitante;
            this.util.culturas = Cultura.fromJsonArray(
                await this.conn.getCultura().toPromise()
            );
            console.log(this.util.culturas);
            this.util.clientes = Cliente.fromJsonArray(
                await this.conn.getCliente().toPromise()
            );
            console.log(this.util.clientes);
            this.util.solicitantes = Solicitante.fromJsonArray(
                await this.conn.getSolicitante().toPromise()
            );
            console.log(this.util.solicitantes);
            if(this.util.consultores.length==1){
                this.laudo.idconsultor = this.util.consultores[0]['id']
                this.laudo.dsconsultor = this.util.consultores[0]['dsconsultor']
            }
        } catch (error) {}
        this.util.stopSpin();
    }

    public duplicar() {
        this.laudo.id = null;
        this.tab = 1;
        this.laudo.isblock = '0';
        this.laudo.observacoes = null;
        this.laudo.area = null;
        console.log(this.laudo);
        
    }

    public state(evt) {
        console.log(evt);
        if (evt == 'gerar') {
            this.gerarGraph();
        } else {
            this.tab = evt;
        }
    }

    public newLaudo() {
        this.tab = 1;
        this.util.laudo = new Laudo();
        this.ngOnInit();
    }

    public async saveLaudo() {
        // this.loading = true;
        try {
            let l = await this.conn.saveLaudo(this.laudo);
            console.log(l);

            if ('error' in l) {
                this.util.alertDanger(l['error']);
            } else {
                this.laudo.dtatualizacao = l[0].dtatualizacao;
            }
        } catch {
            this.util.alertDanger('Erro ao salvar!');
        }
        // this.loading = false;
    }

    public async inactivate(){
        this.util.initSpin("Inativando laudo...")
        try {
            let res = await this.conn.inactivateLaudo(this.laudo.id).toPromise();
            console.log(res);
            this.newLaudo();
            
        } catch (error) {
            
        }
        this.util.stopSpin()
      }

    public async gerarGraph(evt = null) {
        this.loading = true;
        this.calculo = await this.conn.calcLaudo(this.laudo);
        if (!this.calculo) {
            this.loading = false;
            return;
        }
        let l = await this.conn.saveLaudo(this.laudo);
        try {
            if ('error' in l) {
                this.util.alertDanger(l['error']);
                this.loading = false;
                this.util.go('listlaudos');
            } else {
                this.laudo.dtatualizacao = l[0].dtatualizacao;
                if(this.laudo.id != l[0].id) this.laudo.isblock = "0";
                this.laudo.id = l[0].id;
            }
        } catch(e) {
            console.log(e);
            
            this.util.alertDanger('Erro ao salvar!');
            this.loading = false;
            this.tab = 1;
            this.util.go('listlaudos');
            return;
        }

        this.tab = 5;
        this.loading = false;
    }

    ngOnDestroy() {
        this.util.laudo = new Laudo();
    }
}
