<h2 class="c">Resultados Diagnósticos</h2>
<div class="section">Relatório da Propriedade - Análise de Solo</div>

<div class="table top" table="true">
    <div>
        <div table="true" style="--cs: 250px 1fr">
            <div class="brlf">
                <div class="label c">Solicitante</div>
            </div>
            <div class="brrg">
                <div class="label">
                    {{ calculo.dssolicitante }}
                </div>
            </div>
        </div>
    </div>
    <div>
        <div table="true" style="--cs: 250px 1fr">
            <div class="brlf">
                <div class="label c">Proprietário:</div>
            </div>
            <div class="brrg">
                <div class="label">
                    {{ calculo.dscliente }}
                </div>
            </div>
        </div>
    </div>
    <div>
        <div table="true" style="--cs: 250px 1fr">
            <div class="brlf">
                <div class="label c">Identificação:</div>
            </div>
            <div class="brrg">
                <div class="label">
                    {{ calculo.idamostra }}
                </div>
            </div>
        </div>
    </div>
    <div>
        <div table="true" style="--cs: 250px 1fr">
            <div class="brlf">
                <div class="label c">Data:</div>
            </div>
            <div class="brrg">
                <div class="label">
                    {{ calculo.data }}
                </div>
            </div>
        </div>
    </div>
    <div>
        <div table="true" style="--cs: 250px 1fr">
            <div class="brlf">
                <div class="label c">Cultivo:</div>
            </div>
            <div class="brrg">
                <div class="label">
                    {{ calculo.dscultura }}
                </div>
            </div>
        </div>
    </div>
    <div>
        <div table="true" style="--cs: 250px 1fr">
            <div class="brlf">
                <div class="label c">
                    Produt. Estimada
                    <span *ngIf="verAlg(calculo.dscultura)">(@/ha)</span>
                    <span *ngIf="!verAlg(calculo.dscultura)">(sc/ha)</span>:
                </div>
            </div>
            <div class="brrg">
                <div class="label">
                    {{ calculo.prodestimada }}
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="this.laudo.issegunda == '1'">
        <div table="true" style="--cs: 250px 1fr">
            <div class="brlf">
                <div class="label c">Cultivo Anterior:</div>
            </div>
            <div class="brrg">
                <div class="label">
                    {{ calculo.dsculturaant }}
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="this.laudo.issegunda == '1'">
        <div table="true" style="--cs: 250px 1fr">
            <div class="brlf">
                <div class="label c">
                    Produção anterior:
                    <span *ngIf="verAlg(calculo.dsculturaant)">(@/ha)</span>
                    <span *ngIf="!verAlg(calculo.dsculturaant)">(sc/ha)</span>
                </div>
            </div>
            <div class="brrg">
                <div class="label">
                    {{ calculo.produtividadeant }}
                </div>
            </div>
        </div>
    </div>
    <div>
        <div table="true" style="--cs: 250px 1fr">
            <div class="brlf">
                <div class="label c">Logo:</div>
            </div>
            <div class="brrg">
                <div class="label">
                    <mat-slide-toggle [(ngModel)]="util.usinglogo"
                        ><span style="font-size: 12px"
                            >Usar logo configurada?</span
                        ></mat-slide-toggle
                    >
                </div>
            </div>
        </div>
    </div>
</div>
<br />
<br />
<br />
<div class="section">Correção de Solo (Kg / ha)</div>
<div>
    <div class="table" style="padding: 8px">
        <!-- <div class="toptable c">
            <div class="label">
                Correção de solo (Kg / ha)
            </div>
        </div> -->
        <div class="toptable c" table="true" style="--cs: repeat(4, 1fr)">
            <div class="label">CaO</div>
            <div class="label">MgO</div>
            <div class="label">K<small>2</small>O</div>
            <div class="label">P<small>2</small>O<small>5</small></div>
        </div>
        <div class="bodytable c" table="true" style="--cs: repeat(4, 1fr)">
            <div class="inputtab">
                <input [value]="round(calculo.t16.cao_ha)" disabled />
            </div>
            <div class="inputtab">
                <input [value]="round(calculo.t16.mgo_ha)" disabled />
            </div>
            <div class="inputtab">
                <input [value]="round(calculo.t16.k2o_ha)" disabled />
            </div>
            <div class="inputtab">
                <input [value]="round(calculo.t16.p2o5_ha)" disabled />
            </div>
        </div>
    </div>
    <div class="table">
        <div class="top" table="true">
            <div class="item">
                <div class="header">
                    <span>Correção de Solo</span>
                </div>
                <br />
                <div class="bditem">
                    <!-- <barchart [data]="datacorsolo" [labels]="['Déficit','Sit. Atual']" titlex="" titley="Quantidade (%)"
                        (output)="calculo.imgcorsolo = $event" [id]="'chartcorsolo'"></barchart> -->
                    <linechart
                        #gcorsolo
                        [data]="datacorsolo"
                        [labels]="['Atual', 'Déficit']"
                        [colors]="[ '#312783', '#3AAA35']"
                        titlex=""
                        titley=""
                        [id]="'chartcorsolo'"
                    ></linechart>
                </div>
            </div>
            <div class="item">
                <div class="header">
                    <span>Potencial Produtivo</span>
                </div>
                <br />
                <div class="bditem">
                    <!-- <linechart
                        #gpotprod
                        [data]="datapotprod"
                        [labels]="['Atual', 'Ideal']"
                        titlex=""
                        titley=""
                        [id]="'chartpotprod'"
                        [calculate]="false"
                        [legx]="false"
                    ></linechart> -->
                    <piechart
                        #gpotprod
                        [data]="datapotprod"
                        [id]="'chartpotprod'"
                        dsfull="Ideal"
                        dsvalue="Atual"
                    ></piechart>
                    <!-- <percentchart [id]="'chartpotprod'"></percentchart> -->
                </div>
            </div>
        </div>
        <br />
        <br />
        <div class="section">Limitação e Restituição</div>
        <div class="table" style="padding: 8px">
            <!-- <div class="toptable c">
                    <div class="label">
                        Resultado de Restituição
                    </div>
                </div> -->
            <div class="toptable c" table="true" style="--cs: 1fr 1fr">
                <div class="label">Kg / ha</div>
                <div class="label">g / ha</div>
            </div>
            <div class="toptable c" table="true" style="--cs: repeat(10, 1fr)">
                <div class="label">Ca</div>
                <div class="label">Mg</div>
                <div class="label">K<small>2</small>O</div>
                <div class="label">P<small>2</small>O<small>5</small></div>
                <div class="label">S</div>
                <div class="label">Fe</div>
                <div class="label">Mn</div>
                <div class="label">Cu</div>
                <div class="label">Zn</div>
                <div class="label">B</div>
            </div>
            <div class="bodytable c" table="true" style="--cs: repeat(10, 1fr)">
                <!-- <div class="label">{{getValue('ca1')}}</div>
                    <div class="label">{{getValue('mg1')}}</div>
                    <div class="label">{{getValue('k1')}}</div>
                    <div class="label">{{getValue('p1')}}</div>
                    <div class="label">{{getValue('s1')}}</div>
                    <div class="label">{{getValue('fe1')}}</div>
                    <div class="label">{{getValue('mn1')}}</div>
                    <div class="label">{{getValue('cu1')}}</div>
                    <div class="label">{{getValue('zn1')}}</div>
                    <div class="label">{{getValue('b1')}}</div> -->
                <div
                    class="inputtab"
                    [ngStyle]="{
                        'background-color':
                            getValue('ca1') < 0 ? '#ff7081' : 'unset'
                    }"
                >
                    <input [value]="abs('ca1')" disabled />
                </div>
                <div
                    class="inputtab"
                    [ngStyle]="{
                        'background-color':
                            getValue('mg1') < 0 ? '#ff7081' : 'unset'
                    }"
                >
                    <input [value]="abs('mg1')" disabled />
                </div>
                <div
                    class="inputtab"
                    [ngStyle]="{
                        'background-color':
                            getValue('k1') < 0 ? '#ff7081' : 'unset'
                    }"
                >
                    <input [value]="abs('k1')" disabled />
                </div>
                <div
                    class="inputtab"
                    [ngStyle]="{
                        'background-color':
                            getValue('p1') < 0 ? '#ff7081' : 'unset'
                    }"
                >
                    <input [value]="abs('p1')" disabled />
                </div>
                <div
                    class="inputtab"
                    [ngStyle]="{
                        'background-color':
                            getValue('s1') < 0 ? '#ff7081' : 'unset'
                    }"
                >
                    <input [value]="abs('s1')" disabled />
                </div>
                <div
                    class="inputtab"
                    [ngStyle]="{
                        'background-color':
                            getValue('fe1') < 0 ? '#ff7081' : 'unset'
                    }"
                >
                    <input [value]="abs('fe1')" disabled />
                </div>
                <div
                    class="inputtab"
                    [ngStyle]="{
                        'background-color':
                            getValue('mn1') < 0 ? '#ff7081' : 'unset'
                    }"
                >
                    <input [value]="abs('mn1')" disabled />
                </div>
                <div
                    class="inputtab"
                    [ngStyle]="{
                        'background-color':
                            getValue('cu1') < 0 ? '#ff7081' : 'unset'
                    }"
                >
                    <input [value]="abs('cu1')" disabled />
                </div>
                <div
                    class="inputtab"
                    [ngStyle]="{
                        'background-color':
                            getValue('zn1') < 0 ? '#ff7081' : 'unset'
                    }"
                >
                    <input [value]="abs('zn1')" disabled />
                </div>
                <div
                    class="inputtab"
                    [ngStyle]="{
                        'background-color':
                            getValue('b1') < 0 ? '#ff7081' : 'unset'
                    }"
                >
                    <input [value]="abs('b1')" disabled />
                </div>
            </div>
        </div>
        <div class="table">
            <div table="true" class="top" >
                <div class="item">
                    <div class="header">
                        <span>Macronutrientes</span>
                    </div>
                    <br />
                    <div class="bditem">
                        <barchart
                            #gmacronutri
                            [data]="datamacronutri"
                            [labels]="[ 'Atual', 'Déficit']"
                            [colors]="[ '#312783', '#3AAA35']"
                            titlex=""
                            titley=""
                            [legy]="false"
                            [id]="'chartmacronutrientes'"
                        ></barchart>
                        <!-- <linechart [data]="datamacronutri" [labels]="['Déficit','Sit. Atual']" titlex="" titley=""
                        (output)="calculo.imgmacro = $event" [id]="'chartmacronutrientes'"></linechart> -->
                    </div>
                </div>
                <div class="item">
                    <div class="header">
                        <span>Micronutrientes</span>
                    </div>
                    <br />
                    <div class="bditem">
                        <barchart
                            #gmicronutri
                            [data]="datamicronutri"
                            [labels]="[ 'Atual', 'Déficit']"
                            [colors]="[ '#312783', '#3AAA35']"
                            titlex=""
                            titley=""
                            [legy]="false"
                            [id]="'chartmicronutrientes'"
                        ></barchart>
                        <!-- <linechart [data]="datamicronutri" [labels]="['Déficit','Sit. Atual']" titlex="" titley=""
                        (output)="calculo.imgmicro = $event" [id]="'chartmicronutrientes'"></linechart> -->
                    </div>
                </div>
            </div>
        </div>
        <br />
        <!-- <div class="item">
            <div class="header">
                <span>Macronutrientes</span>
            </div>
            <br>
            <div class="bditem" style="display: inline-block;">
                <div *ngFor="let item of macro" class="subitem" style="float: left; min-width: 27vw;">
                    <interchart [title]="labelsmacro[item]" [data]="datamacronutri[item]" titlex="" titley=""
                        [dataid]='item' (output)="calculo['imgmacro'+item] = $event"></interchart>
                </div>
            </div>
        </div>
        <br />
        <div class="item">
            <div class="header">
                <span>Micronutrientes</span>
            </div>
            <br>
            <div class="bditem" style="display: inline-block;">
                <div *ngFor="let item of micro" class="subitem" style="float: left; min-width: 27vw;">
                    <interchart [title]="labelsmicro[item]" [data]="datamicronutri[item]" titlex="" titley=""
                        [dataid]='item' (output)="calculo['imgmicro'+item] = $event"></interchart>
                </div>
            </div>
        </div> -->
    </div>
</div>
<br />
<div class="section">Recomendações</div>
<div table="true" style="--cs: 1fr">
    <textarea
        name="observacoes"
        rows="14"
        style="resize: none"
        (keyup)="clearLines()"
        [(ngModel)]="laudo.observacoes"
        [disabled]="laudo.isblock=='1'"
    ></textarea>
    <div
        style="text-align: right; font-size: 12px; color: var(--color-primary)"
    >
        <span>{{ getLinesObs() }} linhas de 14</span>
        <span *ngIf="getLinesObs() == 14" style="color: var(--color-red)"
            > (Limite máximo de linhas alcançado)</span
        >
    </div>
</div>
<br />
<!-- <div class="button btgreen" (click)="salvar()" [ngClass]="{ btload: loadingb }" *ngIf="laudo.isblock!='1'">
    <span *ngIf="!loadingb">Salvar Recomendação</span>
    <span *ngIf="loadingb">Salvando...</span>
</div> -->

<!-- <div table="true" style="--cs: 1fr 1fr 1fr 1fr; --rs: 1fr; column-gap: 4px">
    <div></div>
    <div
        color="sucess"
        class="button btdark"
        table="true"
        (click)="event.emit(1)"
    >
        <mat-icon aria-hidden="false">home</mat-icon>
        <span>Início</span>
    </div>
    <div color="sucess" class="button btgreen" table="true" (click)="print()">
        <mat-icon aria-hidden="false">print</mat-icon>
        <span>Imprimir Laudo</span>
    </div>
</div> -->
<fabbutton
    icon="print.svg"
    (click)="print()"
    color="var(--color-dark)"
></fabbutton>
<spinner *ngIf="loading"></spinner>
