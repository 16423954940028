<menu></menu>
<div id="container">
    <div class="form">
        <div class="header" table="true" style="--cs: 1fr 180px">
            <span>BBF SOIL </span>
            
            <div class="iconstop">
                <mat-icon
                    aria-hidden="false"
                    (click)="inactivate()"
                    *ngIf="laudo.id"
                    matTooltip="Inativar Laudo"
                    matTooltipPosition="below"
                    >delete_forever</mat-icon
                >
                <mat-icon
                    aria-hidden="false"
                    (click)="duplicar()"
                    *ngIf="laudo.id && util.getSaldoLaudos()>0"
                    matTooltip="Duplicar Laudo"
                    matTooltipPosition="below"
                    >content_copy</mat-icon
                >
                <mat-icon
                    aria-hidden="false"
                    (click)="gerarGraph()"
                    *ngIf="laudo.id"
                    matTooltip="Gerar Gráficos"
                    matTooltipPosition="below"
                    >insert_chart</mat-icon
                >
                <mat-icon
                    aria-hidden="false"
                    (click)="tab = 1"
                    *ngIf="tab != 1"
                    matTooltip="Página Inicial"
                    matTooltipPosition="below"
                    >home</mat-icon
                >
            </div>
        </div>
        <div class="struct" id="struct" *ngIf="!loading">
            <novolaudo
                *ngIf="tab == 1"
                (event)="state($event)"
                [laudo]="laudo"
            ></novolaudo>
            <novolaudodetail
                *ngIf="tab == 2"
                (evtcalc)="gerarGraph($event)"
                (event)="state($event)"
                [laudo]="laudo"
            >
            </novolaudodetail>
            <novolaudocalcs
                *ngIf="tab == 3"
                [calculo]="calculo"
                (event)="state($event)"
            ></novolaudocalcs>
            <novolaudodtgraph
                *ngIf="tab == 4"
                [calculo]="calculo"
                (event)="state($event)"
            ></novolaudodtgraph>
            <geralaudo
                *ngIf="tab == 5"
                [calculo]="calculo"
                [laudo]="laudo"
                (event)="state($event)"
                (save)="newLaudo()"
            ></geralaudo>
        </div>
    </div>
</div>
<spinner *ngIf="loading"></spinner>
