<menu></menu>
<div id="container">
    <div class="form">
        <div class="header" table=true style="--cs:1fr 50px">
            <span>Listagem de consultores</span>
            <mat-icon (click)="edit(null)" *ngIf="util.isMaster()">note_add</mat-icon>
        </div>
        <div class="struct" id="struct">
            <buscaconsultor *ngIf="!loading" [data]="consultores" (sign)="edit($event)"></buscaconsultor>
        </div>
    </div>
</div>
<spinner *ngIf="loading"></spinner>