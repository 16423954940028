
import { Generic } from "./generic";

export class Laudo extends Generic {
    id: string;
    iduser: string;
    idcliente: string;
    dscliente: string;
    idcultura: string;
    idamostra: string;
    idsolicitante: string;
    dssolicitante: string;
    idconsultor:string;
    dsconsultor:string;
    area:string;
    producaoest: string;
    issegunda: any = "0";
    idculturaant: number;
    produtividadeant: string;
    idsegcultura: string;
    produtividadeest: string;
    isinfiltracao: any = "0";
    txinfiltracao: any = "0";
    infiltracaoac: any = "0";
    cadesejado: string;
    mgdesejado: string;
    kdesejado: string;
    vrdesejado: string;
    ca1: string;
    mg1: string;
    k1: string;
    al1: string;
    ctc1: string;
    p2: string;
    prem2: string;
    s2: string;
    fe2: string;
    mn2: string;
    cu2: string;
    zn2: string;
    b2: string;
    ca3: string;
    mg3: string;
    k3: string;
    p3: string;
    s3: string;
    fe3: string;
    mn3: string;
    cu3: string;
    zn3: string;
    b3: string;
    hascode: string;
    dtlaudo: string;
    dtatualizacao: string;
    observacoes: string;
    isblock: string;
    isativo: string = "1";

    calcResAnQuiConv() {
        var kg_ha = {}
        kg_ha['Ca'] = Number(this.ca1) * 200 * 2
        kg_ha['Mg'] = Number(this.mg1) * 120 * 2
        kg_ha['K'] = Number(this.k1) * 390 * 2
        kg_ha['P'] = Number(this.p2) * 2
        kg_ha['S'] = Number(this.s2) * 2
        var g_ha = {}
        g_ha['Fe'] = Number(this.fe2) * 2000
        g_ha['Mn'] = Number(this.mn2) * 2000
        g_ha['Cu'] = Number(this.cu2) * 2000
        g_ha['Zn'] = Number(this.zn2) * 2000
        g_ha['B'] = Number(this.b2) * 666.67
        var mg_dm3 = {}
        mg_dm3['NiCri'] = 4.62 + 0.324731 * Number(this.prem2) + 0.00160568 * Math.pow(Number(this.prem2), 2)
        var per = {}
        per['VR'] = (Number(this.p2) / mg_dm3['NiCri']) * 100
        kg_ha['Al'] = Number(this.al1) * 90 * 2
        console.log(kg_ha)
        console.log(g_ha);
        console.log(mg_dm3);
        console.log(per);

    }
}

export class CorrectIndicePluv extends Generic {
    coefeficitxinfilt: number;
    coefeficiinfiltac: number;
    indpluvideal: number;
    indpluvcalc: number;
    indpluvcorr: number;

    getCoefeficitxinfilt(txinfiltracao) {
        if (Number(txinfiltracao) != NaN) {
            if (Number(txinfiltracao) < 30) {
                return Number(txinfiltracao) / 25
            } else return 1
        }
        else return 0
    }
}