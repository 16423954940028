import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NegativecolumchartComponent } from 'src/app/components/negativecolumchart/negativecolumchart.component';
import { StackedcolumchartComponent } from 'src/app/components/stackedcolumchart/stackedcolumchart.component';
import { colors_list_unique } from 'src/app/components/theme/theme_graph';
import { ConnectionService } from 'src/app/services/connection.service';
import { PdfgenerateService } from 'src/app/services/pdfgenerate.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
    selector: 'laudofolha',
    templateUrl: './laudofolha.component.html',
    styleUrls: ['./laudofolha.component.scss'],
})
export class LaudofolhaComponent implements OnInit {
    @Input() calculo: any;
    public color_unique = colors_list_unique;
    public macronutrientes = {
        n: 'N',
        p: 'P<sub>2</sub>O<sub>5</sub>',
        k: 'K<sub>2</sub>O',
        ca: 'Ca',
        mg: 'Mg',
        s: 'S',
    };
    public micronutrientes = { fe: 'Fe', b: 'B', mn: 'Mn', zn: 'Zn', cu: 'Cu', mo: 'Mo' };
    public labelsOrganicMatter = {
        n: 'N',
        p: 'P',
        k: 'K',
        ca: 'Ca',
        mg: 'Mg',
        s: 'S',
        fe: 'Fe',
        b: 'B',
        mn: 'Mn',
        zn: 'Zn',
        cu: 'Cu',
        mo: 'Mo',
    };
    public max_rows_obs = 10;
    public bloqueia_observacoes: boolean = false;
    public loading: boolean = false;
    public uselogo: boolean = false;
    public adapterMatOrgNut = (label, target) => {
        if (target.dataItem.values.value.value == 0) {
            return 'Deficiência';
        } else if (target.dataItem.values.value.value == 100) {
            return 'Excesso';
        } else if (target.dataItem.values.value.value == 50) {
            return '0';
        }
        return '';
    };

    @ViewChild('chartmateriaorganicanutriente')
    chartmateriaorganicanutriente: StackedcolumchartComponent;
    @ViewChild('chartsuficiencianutricional')
    chartsuficiencianutricional: NegativecolumchartComponent;
    @ViewChild('chartequilibrionutricional')
    chartequilibrionutricional: StackedcolumchartComponent;

    constructor(
        private conn: ConnectionService,
        private util: UtilService,
        private pdf: PdfgenerateService
    ) {
        this.uselogo = this.util.getUseLogoPlanta();
    }

    public formatDataToChartOrganicMatter() {
        let atual = this.calculo.relacao_nutrientes_ms_atual;
        let desejado = this.calculo.relacao_nutrientes_ms_desejado;

        return Object.keys(this.labelsOrganicMatter).map((key) => {
            return {
                category: this.labelsOrganicMatter[key],
                Atual: atual[key],
                Ideal: desejado[key],
            };
        });
    }

    // public formatDataToChartNutritionalSufficiency() {
    //     let atual = this.calculo.laudo.nutrientes;
    //     let ideal = this.calculo.teores_ideais;

    //     return Object.keys(this.labelsOrganicMatter).map((key) => {
    //         return {
    //             category: this.labelsOrganicMatter[key],
    //             Atual: Number(atual[key]),
    //             Ideal: Number(ideal[key]) * -1,
    //         };
    //     });
    // }

    public formatDataToChartNutritionalSufficiency() {
        let atual = this.calculo.laudo.nutrientes;
        let ideal = this.calculo.teores_ideais;

        return Object.keys(this.labelsOrganicMatter).map((key) => {
            return {
                category: this.labelsOrganicMatter[key],
                Atual:
                    (Number(atual[key]) * 100) /
                    (Number(atual[key]) + Number(ideal[key])),
            };
        });
    }

    public formatDataToChartNutritionalBalance() {
        let data = this.calculo.laudo_resultadosL2;
        let res = Object.keys(this.labelsOrganicMatter).map((key) => {
            return {
                category: this.labelsOrganicMatter[key],
                'Equilíbrio Nutricional': data[key],
            };
        });
        return res;
    }

    public changeUseLogoPlanta() {
        this.util.setUseLogoPlanta(this.uselogo);
    }

    async print() {
        this.loading = true;
        try {
            let res: any = await this.conn.saveObservaçãoLaudoPlanta(
                this.calculo.laudo.id,
                this.calculo.laudo.observacoes
            );
            console.log(res);
            if (res.message && this.calculo.laudo.observacoes) this.bloqueia_observacoes = true;
            this.calculo.charts = {
                chartmateriaorganicanutriente:
                    await this.chartmateriaorganicanutriente.exportChart(),
                chartsuficiencianutricional:
                    await this.chartsuficiencianutricional.exportChart(),
                chartequilibrionutricional:
                    await this.chartequilibrionutricional.exportChart('1300px'),
            };
            await this.util.sleep(1000);
            this.pdf.image = this.uselogo ? this.util.getImageUser() : null;
            await this.pdf.generatePdfFolha(this.calculo);
            this.loading = false;
        } catch (error) {
            this.loading = false;
        }
    }

    validaObservacoes() {
        let obs = this.calculo.laudo.observacoes;
        console.log(obs.split('\n').length);

        if (obs.split('\n').length > this.max_rows_obs) {
            this.calculo.laudo.observacoes = obs
                .split('\n')
                .slice(0, this.max_rows_obs)
                .join('\n');
        }
    }

    getRowsObs(): number {
        try {
            return this.calculo.laudo.observacoes.split("\n").length;
        } catch (error) {
            return 0;
        }
    }

    ngOnInit(): void {
        console.log(this.calculo);
        this.bloqueia_observacoes = Boolean(this.calculo.laudo.observacoes);
    }
}
