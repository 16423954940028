<div id="search">
    <div class="table">
        <div class="toptable" table=true style="--cs: 120px 1fr">
            <div class="label">
                ID
            </div>
            <div class="label">
                Cultura
            </div>
        </div>
        <div class="bodytable" table=true style="--cs: 120px 1fr" *ngFor="let item of data" (click)="select(item)">
            <div class="label">
                {{item.id}}
            </div>
            <div class="label">
                {{item.dscultura}}
            </div>
        </div>
    </div>
</div>