import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_spiritedaway from "@amcharts/amcharts4/themes/spiritedaway";
import * as moment from 'moment';

function am4themes_myTheme(target) {
  if (target instanceof am4core.ColorSet) {
    target.list = [
      am4core.color("#af2121"),
      am4core.color("#016666"),
      am4core.color("#2E3192"),
      am4core.color("#DF3520"),
      am4core.color("#64297B"),
      am4core.color("#232555")
    ];
  }
}

type LegendPosition = "left" | "right" | "top" | "bottom" | "absolute";

@Component({
  selector: 'interchart',
  templateUrl: './interchart.component.html',
  styleUrls: ['./interchart.component.scss']
})
export class InterchartComponent implements OnInit {
  @Input() data: any = []
  @Input() id: any = "interchart" + moment().valueOf()
  @Input() labels: any = []
  @Input() dataid: any;
  @Input() title: string;
  @Input() titlex: string;
  @Input() titley: string;
  @Input() tension: number = 0.77;
  @Input() legendpos: LegendPosition = 'bottom';
  @Input() steped: boolean = false;
  @Output() output: EventEmitter<any> = new EventEmitter();

  constructor() {
    am4core.useTheme(am4themes_myTheme);
    am4core.useTheme(am4themes_animated);
  }

  ngOnInit(): void {
    if (this.dataid) this.id += this.dataid
    setTimeout(() => {
      this.create()
    }, 300);
  }

  create() {
    let chart = am4core.create(this.id, am4charts.RadarChart);
    chart.data = this.data;
    chart.logo.height = -15000;
    chart.startAngle = -90;
    chart.endAngle = 180;
    chart.innerRadius = am4core.percent(50);

    // Set number format
    chart.numberFormatter.numberFormat = "#.#'%'";

    // Create axes
    let axisX = chart.xAxes.push(new am4charts.ValueAxis<am4charts.AxisRendererCircular>());
    axisX.renderer.grid.template.strokeOpacity = 0;
    axisX.renderer.fontSize = 10
    axisX.min = 0;
    axisX.max = 100;
    axisX.renderer.minGridDistance = 10;
    axisX.strictMinMax = true;

    let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis<am4charts.AxisRendererRadial>());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.grid.template.strokeOpacity = 0;
    categoryAxis.renderer.labels.template.horizontalCenter = "right";
    categoryAxis.renderer.labels.template.adapter.add("fill", function (fill, target) {
      return (target.dataItem.index >= 0) ? chart.colors.getIndex(target.dataItem.index) : fill;
    });
    categoryAxis.renderer.minGridDistance = 10;

    this.generateSerie(chart, 'category', 'full', 0.1)
    this.generateSerie(chart, 'category', 'value', 0.9)
    // Legend
    // chart.legend = new am4charts.Legend();
    // chart.legend.fontSize = 12;
    // chart.legend.position = this.legendpos
    // chart.legend.marginBottom = 20;
    // chart.legend.valueLabels.template.fontSize = 12;
    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.align = "left";
    chart.exporting.menu.verticalAlign = "top";
    chart.exporting.title = this.title;
    chart.exporting.menu.items = [{
      "label": "...",
      "menu": [
        { "type": "png", "label": "PNG" },
        { "type": "print", "label": "Imprimir" },
      ]
    }];
    if (this.title) {
      let title = chart.titles.create();
      title.text = this.title;
      title.fontSize = 16;
      title.marginTop = 12;
      title.marginBottom = 12;
    }
    chart.cursor = new am4charts.RadarCursor();
    chart.cursor.tooltip.disabled = true
    chart.events.on('ready',() => {
      chart.exporting.getImage("jpg").then(res => {
        this.output.emit(res)
      });
    });
  }

  generateSerie(chart, x, y, opacity) {
    let series;
    series = chart.series.push(new am4charts.RadarColumnSeries());
    series.dataFields.valueX = y;
    series.dataFields.categoryY = x;
    series.clustered = false;
    series.columns.template.fill = new am4core.InterfaceColorSet().getFor("alternativeBackground");
    series.columns.template.fillOpacity = opacity;
    series.columns.template.strokeWidth = 0;
    series.columns.template.radarColumn.cornerRadius = 20;
    series.columns.template.tooltipText = "{category}: [bold]{value}[/]";

    series.columns.template.adapter.add("fill", function (fill, target) {
      return chart.colors.getIndex(target.dataItem.index);
    });

  }

}
