import {
    Component,
    ElementRef,
    Inject,
    OnInit,
    ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Solicitante } from 'src/app/class/solicitante';
import { User } from 'src/app/class/user';
import { PickerComponent } from 'src/app/components/picker/picker.component';
import { ConnectionService } from 'src/app/services/connection.service';
import { UtilService } from 'src/app/services/util.service';
import { NovosolicitanteComponent } from '../novosolicitante/novosolicitante.component';

@Component({
    selector: 'app-novousuario',
    templateUrl: './novousuario.component.html',
    styleUrls: ['./novousuario.component.scss'],
})
export class NovousuarioComponent implements OnInit {
    public senhac;
    public isativo: boolean = true;
    public isadm: boolean = false;
    public usuario: User = new User();
    public loading: boolean = false;
    public dssolicitante: string;
    /* CONTROLLERS */
    @ViewChild('dsuser') inputuser: ElementRef;
    @ViewChild('login') inputlogin: ElementRef;
    @ViewChild('senha') inputsenha: ElementRef;
    @ViewChild('senha2') inputsenha2: ElementRef;

    constructor(
        public dialogRef: MatDialogRef<PickerComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public util: UtilService,
        private conn: ConnectionService
    ) {}

    ngOnInit(): void {
        if (this.data) {
            this.usuario = this.data;
            console.log(this.usuario);
            try {
                this.dssolicitante = this.util.solicitantes.find(
                    (item) => item.id == this.usuario.idsolicitante
                ).dssolicitante;
            } catch (error) {}

            this.isadm = this.usuario.ismaster == '1';
            this.isativo = this.usuario.isativo == '1';
        }
    }

    async buscasolicitante() {
        try {
            this.loading = true;
            this.util.solicitantes = Solicitante.fromJsonArray(
                await this.conn.getSolicitante().toPromise()
            );
            this.loading = false;
            let d: Solicitante = await this.util.dialog(PickerComponent, {
                title: 'Selecionar Solicitante',
                items: this.util.solicitantes,
                table: 'solicitante',
                component: NovosolicitanteComponent,
            });
            if (d) {
                this.usuario.idsolicitante = d.id;
                this.dssolicitante = d.dssolicitante;
            }
        } catch (error) {}
    }

    close() {
        this.dialogRef.close();
    }

    validar(): boolean {
        if (!this.usuario.dsuser) {
            this.util.alertDanger('Usuário é campo obrigatório!');
            this.inputuser.nativeElement.focus();
            return false;
        } else if (!this.usuario.login) {
            this.util.alertDanger('Login é campo obrigatório!');
            this.inputlogin.nativeElement.focus();
            return false;
        } else if (!this.usuario.id && !this.usuario.senha) {
            this.util.alertDanger('Senha é campo obrigatório!');
            this.inputsenha.nativeElement.focus();
            return false;
        } else if (this.usuario.senha != this.senhac && this.usuario.senha) {
            this.util.alertDanger(
                'Confirmação de senha não bate com a senha digitada!'
            );
            this.inputsenha2.nativeElement.focus();
            return false;
        }
        return true;
    }

    async salvar() {
        if (!this.validar()) return;
        this.loading = true;
        try {
            this.usuario.isativo = this.isativo ? '1' : '0';
            this.usuario.ismaster = this.isadm ? '1' : '0';
            console.log(this.usuario);
            let res: User[] = User.fromJsonArray(
                await this.conn.setUser(this.usuario).toPromise()
            );
            console.log(res);
            if (res ? res.length > 0 : false) {
                if (res[0].id) {
                    this.usuario = res[0];
                    this.senhac = null;
                } else {
                    if (res['error']) this.util.alertDanger(res['error']);
                    else
                        this.util.alertDanger(
                            'Erro ao adicionar/editar Usuário!'
                        );
                }
            } else {
                this.util.alertDanger('Erro ao adicionar/editar Usuário!');
            }
        } catch (e) {
            console.log(e);
            this.util.alertDanger('Erro ao adicionar/editar Usuário!');
        }
        this.loading = false;
    }
}
