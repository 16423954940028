import { Component, OnInit } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';

@Component({
    selector: 'app-selection',
    templateUrl: './selection.component.html',
    styleUrls: ['./selection.component.scss'],
})
export class SelectionComponent implements OnInit {
    constructor(public util: UtilService) {}

    ngOnInit(): void {}

    go(tplaudo) {
        this.util.tplaudo = tplaudo;
        if (tplaudo == 'solo') {
            if (this.util.getSaldoLaudos() > 0) this.util.go('fichainicial');
            else this.util.go('listlaudos');
        } else if (tplaudo == 'folha') {
            if (this.util.getSaldoLaudos() > 0) this.util.go('cadfolha');
            else this.util.go('listlaudosfolha');
        }
    }
}
