import { Component, OnInit } from '@angular/core';
import { Laudo } from 'src/app/class/laudo';
import { ConnectionService } from 'src/app/services/connection.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'listlaudos',
  templateUrl: './listlaudos.component.html',
  styleUrls: ['./listlaudos.component.scss']
})
export class ListlaudosComponent implements OnInit {
  public laudos: Laudo [] = []
  public list: Laudo []
  public loading:boolean = false

  constructor(
    private conn: ConnectionService,
    public util: UtilService
  ) { }

  ngOnInit(): void {
    this.getLaudos()
  }

  async getLaudos(){
    this.loading = true
    this.laudos = Laudo.fromJsonArray(await this.conn.getLaudos().toPromise())
    this.list = this.laudos
    console.log(this.laudos);
    this.loading = false
  }

  async edit(laudo){
    console.log(laudo);
    this.util.laudo = laudo
    this.util.go('fichainicial')
  }

}
