import { EventEmitter, Input, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'buscacultura',
  templateUrl: './buscacultura.component.html',
  styleUrls: ['./buscacultura.component.scss']
})
export class BuscaculturaComponent implements OnInit {
  @Input() data: any = []
  @Output() sign = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  public select(item) {
    this.sign.emit(item)
  }

}
