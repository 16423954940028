import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Solicitante } from 'src/app/class/solicitante';
import { PickerComponent } from 'src/app/components/picker/picker.component';
import { ConnectionService } from 'src/app/services/connection.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
    selector: 'app-novosolicitante',
    templateUrl: './novosolicitante.component.html',
    styleUrls: ['./novosolicitante.component.scss'],
})
export class NovosolicitanteComponent implements OnInit {
    public solicitante: Solicitante = new Solicitante();
    public loading: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<PickerComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public util: UtilService,
        private conn: ConnectionService
    ) {}

    ngOnInit(): void {
        if (this.data) {
            this.solicitante = this.data;
        }
    }


    async close() {
        this.loading = true;
        this.util.solicitantes = Solicitante.fromJsonArray(
            await this.conn.getSolicitante().toPromise()
        );
        this.loading = false;
        this.dialogRef.close(this.util.solicitantes);
    }

    validar(): boolean {
        if (!this.solicitante.dssolicitante) {
            this.util.alertDanger('Solicitante é campo obrigatório!');
            return false;
        }
        return true;
    }

    async salvar() {
        if (!this.validar()) return;
        this.loading = true;
        try {
            let res: Solicitante[] = Solicitante.fromJsonArray(
                await this.conn.setSolicitante(this.solicitante).toPromise()
            );
            console.log(res);
            if (res ? res.length > 0 : false) {
                if (res[0].id) {
                    this.solicitante = res[0];
                } else {
                    this.util.alertDanger(
                        'Erro ao adicionar/editar Solicitante!'
                    );
                }
            } else {
                this.util.alertDanger('Erro ao adicionar/editar Solicitante!');
            }
        } catch (e) {
            console.log(e);

            this.util.alertDanger('Erro ao adicionar/editar Solicitante!');
        }
        this.loading = false;
    }
}
