import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { Laudo } from 'src/app/class/laudo';
import { Cultura } from 'src/app/class/cultura';
import { ConnectionService } from 'src/app/services/connection.service';
import { UtilService } from 'src/app/services/util.service';
import { Cliente } from 'src/app/class/cliente';
import { Solicitante } from 'src/app/class/solicitante';
import { PickerComponent } from 'src/app/components/picker/picker.component';
import { NovoclienteComponent } from '../novocliente/novocliente.component';
import { NovosolicitanteComponent } from '../novosolicitante/novosolicitante.component';
import { MatSelect } from '@angular/material/select';
import { Consultor } from 'src/app/class/consultor';
import { NovoconsultorComponent } from '../novoconsultor/novoconsultor.component';

@Component({
    selector: 'novolaudo',
    templateUrl: './novolaudo.component.html',
    styleUrls: ['./novolaudo.component.scss'],
})
export class NovolaudoComponent implements OnInit {
    @Output() event: EventEmitter<any> = new EventEmitter();
    @Input() laudo: Laudo = new Laudo();
    public loading: boolean = false;
    public issegunda: boolean = false;
    public isinfiltracao: boolean = false;
    public cultura: Cultura[] = [];

    /* CONTROLLERS */
    @ViewChild('idcliente') idcliente: ElementRef;
    @ViewChild('idsolicitante') idsolicitante: ElementRef;
    @ViewChild('idamostra') idamostra: ElementRef;
    @ViewChild('idcultura') idcultura: MatSelect;
    @ViewChild('producaoest') producaoest: ElementRef;
    @ViewChild('idculturaant') idculturaant: MatSelect;
    @ViewChild('produtividadeant') produtividadeant: ElementRef;
    @ViewChild('idsegcultura') idsegcultura: MatSelect;
    @ViewChild('produtividadeest') produtividadeest: ElementRef;
    @ViewChild('txinfiltracao') txinfiltracao: ElementRef;
    @ViewChild('infiltracaoac') infiltracaoac: ElementRef;
    @ViewChild('cadesejado') cadesejado: ElementRef;
    @ViewChild('mgdesejado') mgdesejado: ElementRef;
    @ViewChild('kdesejado') kdesejado: ElementRef;
    @ViewChild('vrdesejado') vrdesejado: ElementRef;

    constructor(private conn: ConnectionService, public util: UtilService) {}

    ngOnInit(): void {
        this.getBox();
        document.getElementById('struct').scrollTo(0, 0);
        document.getElementById('first').focus();
    }

    async getBox() {
        this.loading = true;
        
        
        // this.util.culturas = Cultura.fromJsonArray(
        //     await this.conn.getCultura().toPromise()
        // );
        // console.log(this.util.culturas);
        // this.util.clientes = Cliente.fromJsonArray(
        //     await this.conn.getCliente().toPromise()
        // );
        // console.log(this.util.clientes);
        // this.util.solicitantes = Solicitante.fromJsonArray(
        //     await this.conn.getSolicitante().toPromise()
        // );
        // console.log(this.util.solicitantes);
        if (this.laudo.id) {
            this.isinfiltracao = this.laudo.isinfiltracao == '1';
            this.issegunda = this.laudo.issegunda == '1';
        }
        this.util.force_novo = false;
        this.laudo.isblock = this.laudo.observacoes ? "1" : "0";
        this.loading = false;
    }

    async lostFocusCliente() {
        try {
            this.laudo.dscliente = this.util.clientes.find(
                (item) => item.id == this.laudo.idcliente
            ).dscliente;
            if (!this.laudo.dscliente) {
                this.laudo.idcliente = null;
                this.laudo.dscliente = null;
            }
        } catch {
            this.laudo.idcliente = null;
            this.laudo.dscliente = null;
        }
    }

    async lostFocusSolicitante() {
        try {
            this.laudo.dssolicitante = this.util.solicitantes.find(
                (item) => item.id == this.laudo.idsolicitante
            ).dssolicitante;
            if (!this.laudo.dssolicitante) {
                this.laudo.idsolicitante = null;
                this.laudo.dssolicitante = null;
            }
        } catch {
            this.laudo.idsolicitante = null;
            this.laudo.dssolicitante = null;
        }
    }

    async lostFocusConsultor() {
        try {
            this.laudo.dsconsultor = this.util.consultores.find(
                (item) => item.id == this.laudo.idconsultor
            ).dsconsultor;
            if (!this.laudo.dsconsultor) {
                this.laudo.idconsultor = null;
                this.laudo.dsconsultor = null;
            }
        } catch {
            this.laudo.idconsultor = null;
            this.laudo.dsconsultor = null;
        }
    }

    changeSegunda(evt) {
        this.laudo.issegunda = evt.checked ? '1' : '0';
        this.laudo.idculturaant = null;
        this.laudo.produtividadeant = null;
        this.laudo.idsegcultura = null;
        this.laudo.produtividadeest = null;
        this.laudo.observacoes = "";
        this.util.force_novo = true;
    }

    changeInfiltracao(evt) {
        this.laudo.isinfiltracao = evt.checked ? '1' : '0';
        this.laudo.txinfiltracao = 0;
        this.laudo.infiltracaoac = 0;
    }

    async pickSolicitante() {
        let d: Solicitante = await this.util.dialog(PickerComponent, {
            title: 'Selecionar Solicitante',
            items: this.util.solicitantes,
            table: 'solicitante',
            component: NovosolicitanteComponent,
        });
        if (d) {
            this.laudo.idsolicitante = d.id;
            this.laudo.dssolicitante = d.dssolicitante;
        }
    }

    async pickConsultor() {
        let d: Consultor = await this.util.dialog(PickerComponent, {
            title: 'Selecionar Consultor',
            items: this.util.consultores,
            table: 'consultor',
            component: NovoconsultorComponent,
        });
        if (d) {
            this.laudo.idconsultor = d.id;
            this.laudo.dsconsultor = d.dsconsultor;
        }
    }

    getCult(id) {
        try {
            return this.util.culturas.find((item) => item.id == id).dscultura;
        } catch {
            return 'Nenhuma';
        }
    }

    undAlgod(id) {
        try {
            return String(this.getCult(id)).toUpperCase().indexOf('ALGOD') > -1
                ? '@/ha'
                : 'sc/ha';
        } catch (error) {
            return 'sc/ha';
        }
    }


    async getClients() {
        return await this.conn.getCliente().toPromise();
    }

    async pickCliente() {
        let d: Cliente = await this.util.dialog(PickerComponent, {
            title: 'Selecionar Cliente',
            items: this.util.clientes,
            table: 'cliente',
            component: NovoclienteComponent,
        });
        if (d) {
            this.laudo.idcliente = d.id;
            this.laudo.dscliente = d.dscliente;
        }
    }

    foco(element) {
        element.nativeElement.focus();
    }

    validar() {
        if (!this.laudo.idcliente) {
            this.util.alertDanger('Proprietário é campo obrigatório!');
            this.foco(this.idcliente);
            return false;
        } else if (!this.laudo.idamostra) {
            this.util.alertDanger(
                'Identificação da Amostra é campo obrigatório!'
            );
            this.foco(this.idamostra);
            return false;
        } else if (!this.laudo.idsolicitante) {
            this.util.alertDanger('Solicitante é campo obrigatório!');
            this.foco(this.idsolicitante);
            return false;
        } else if (!this.laudo.idcultura) {
            this.util.alertDanger('Cultura é campo obrigatório!');
            this.idcultura.focus();
            return false;
        } else if (!this.laudo.producaoest) {
            this.util.alertDanger('Produção Estimada é campo obrigatório!');
            this.foco(this.producaoest);
            return false;
        } else if (this.laudo.issegunda == '1' && !this.laudo.idculturaant) {
            this.util.alertDanger('Cultura Anterior é campo obrigatório!');
            this.idculturaant.focus();
            return false;
        } else if (
            this.laudo.issegunda == '1' &&
            !this.laudo.produtividadeant
        ) {
            this.util.alertDanger(
                'Produtividade Anterior é campo obrigatório!'
            );
            this.foco(this.produtividadeant);
            return false;
        } else if (this.laudo.issegunda == '1' && !this.laudo.idsegcultura) {
            this.util.alertDanger('Segunda cultura é campo obrigatório!');
            this.idsegcultura.focus();
            return false;
        } else if (
            this.laudo.issegunda == '1' &&
            !this.laudo.produtividadeest
        ) {
            this.util.alertDanger(
                'Prod. Estimada Seg. Cultura é campo obrigatório!'
            );
            this.foco(this.produtividadeest);
            return false;
        } else if (
            this.laudo.isinfiltracao == '1' &&
            !this.laudo.txinfiltracao
        ) {
            this.util.alertDanger('Taxa de Infiltração é campo obrigatório!');
            this.foco(this.txinfiltracao);
            return false;
        } else if (!this.laudo.cadesejado) {
            this.util.alertDanger('Ca desejado é campo obrigatório!');
            this.foco(this.cadesejado);
            return false;
        } else if (!this.laudo.mgdesejado) {
            this.util.alertDanger('Mg desejado é campo obrigatório!');
            this.foco(this.mgdesejado);
            return false;
        } else if (!this.laudo.kdesejado) {
            this.util.alertDanger('K desejado é campo obrigatório!');
            this.foco(this.kdesejado);
            return false;
        } else if (!this.laudo.vrdesejado) {
            this.util.alertDanger('VR desejado é campo obrigatório!');
            this.foco(this.vrdesejado);
            return false;
        }

        return true;
    }

    next() {
        if (!this.validar()) {
            return;
        }
        if (this.laudo.id) {
            this.event.emit('gerar');
        } else {
            this.event.emit(2);
        }
        console.log(this.laudo);
    }
}
