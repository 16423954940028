<div id="picker">
    <div class="header" table=true style="--cs: 1fr 50px">
        <span>Novo Consultor</span>
        <mat-icon (click)="close()">close</mat-icon>
    </div>
    <div class="struct">
        <div table=true style="--cs: 120px 1fr ">
            <div table=true style="--cs: 1fr; --rs: 20px 50px">
                <div>
                    ID
                </div>
                <div class="input">
                    <input disabled [(ngModel)]="consultor.id" />
                </div>
            </div>
            <div table=true style="--cs: 1fr; --rs: 20px 50px">
                <div>
                    Descrição
                    <span *ngIf="!consultor.dsconsultor" class="errorlabel"> (Descrição é campo obrigatório!)</span>
                </div>
                <div class="input">
                    <input [(ngModel)]="consultor.dsconsultor" />
                </div>
            </div>
        </div>
        <br />
        <br />
        <div table=true style="--cs: 1fr 1fr 1fr">
            <div></div>
            <div color="sucess" class="button btred" table=true (click)="close()">
                <mat-icon aria-hidden="false">close</mat-icon>
                <span>Cancelar</span>
            </div>
            <div color="sucess" class="button btgreen" table=true (click)="salvar()">
                <mat-icon aria-hidden="false">done</mat-icon>
                <span>Salvar</span>
            </div>
        </div>
    </div>
</div>
<spinner *ngIf="loading"></spinner>