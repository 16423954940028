import { Component, OnInit } from '@angular/core';
import { Cultura } from 'src/app/class/cultura';
import { NovaculturaComponent } from 'src/app/register/novacultura/novacultura.component';
import { ConnectionService } from 'src/app/services/connection.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-listcultura',
  templateUrl: './listcultura.component.html',
  styleUrls: ['./listcultura.component.scss']
})
export class ListculturaComponent implements OnInit {
  public loading: boolean = false
  public culturas: Cultura[] = []
  constructor(
    private conn: ConnectionService,
    private util: UtilService
  ) {

  }

  ngOnInit(): void {
    this.carregar() 
  }

  async carregar(){
    this.loading = true
    this.culturas = []
    this.culturas = Cultura.fromJsonArray(await this.conn.getCultura().toPromise())
    console.log(this.culturas);
    
    this.loading = false
  }

  async edit(item){
    this.util.dialog(NovaculturaComponent,item).then(()=>{
      this.carregar()
    })
  }

}
