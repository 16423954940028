import { Component, OnInit, ViewChild } from '@angular/core';
import { FileuploadComponent } from 'src/app/components/fileupload/fileupload.component';
import { ConnectionService } from 'src/app/services/connection.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
    selector: 'app-logolaudos',
    templateUrl: './logolaudos.component.html',
    styleUrls: ['./logolaudos.component.scss'],
})
export class LogolaudosComponent implements OnInit {
    public image: any;
    public loading: any;
    @ViewChild('fileupload') fileupload: FileuploadComponent;

    constructor(private conn: ConnectionService, private util: UtilService) {}

    ngOnInit(): void {
        this.carregar();
    }

    async carregar() {
        this.loading = true;
        try {
            let res = await this.conn.getImage().toPromise();
            console.log(res);
            if (res ? res['imagem'] : false) {
                this.image = res['imagem'];
                this.fileupload.setImage(res['imagem']);
                this.util.setImageUser(res['imagem']);
            }
        } catch (error) {}
        this.loading = false;
    }

    async enviar() {
        console.log(this.image);
        this.loading = true;
        try {
            let res = await this.conn.setImage(this.image).toPromise();
            if (res ? res['imagem'] : false) {
                this.image = res['imagem'];
                this.util.setImageUser(res['imagem']);
            }
        } catch (error) {}
        this.loading = false;
    }
}
