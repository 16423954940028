import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'fileupload',
    templateUrl: './fileupload.component.html',
    styleUrls: ['./fileupload.component.scss'],
})
export class FileuploadComponent implements OnInit {
    @Input() public items = 0;
    public image: any;
    @Output() uploaded: EventEmitter<any> = new EventEmitter();
    @Output() deleted: EventEmitter<any> = new EventEmitter();
    @Output() downloaded: EventEmitter<any> = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}

    public setImage(image){
        console.log("Set image!");
        this.image = image
    }

    @HostListener('dragover', ['$event']) onDragOver(evt) {
        evt.preventDefault();
        evt.stopPropagation();
    }

    // Dragleave listener
    @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
        evt.preventDefault();
        evt.stopPropagation();
    }

    // Drop listener
    @HostListener('drop', ['$event']) public ondrop(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        let files = evt.dataTransfer.files;
        if (files.length > 0) {
            console.log(files);
            this.upload(files);
        }
    }

    openFile() {
        try {
            var filepicker = document.createElement('input');
            filepicker.setAttribute('type', 'file');
            // filepicker.setAttribute('multiple', 'multiple');
            // filepicker.setAttribute('webkitdirectory','')
            filepicker.addEventListener('change', (evt) => {
                const files = filepicker.files;
                this.upload(files);
            });
            filepicker.addEventListener('abort', (evt) => {
                console.log(evt);
            });
            filepicker.click();
            return filepicker.onchange;
        } catch (error) {
            console.log('Erro ao abrir arquivo');
        }
    }

    upload(files) {
        if (files && files.length) {
            for (let i = 0; i < files.length; i++) {
                const fileToRead = files[i];
                var reader = new FileReader();
                reader.onload = (e: any) => {
                    this.items++;
                    // console.log(e.target.result);
                    this.image = e.target.result;
                    this.uploaded.emit(e.target.result);
                };
                reader.readAsDataURL(fileToRead);
            }
        }
    }

    delete(value) {
        this.items--;
        this.deleted.emit(value);
    }

    download(value) {
        this.downloaded.emit(value);
    }
}
