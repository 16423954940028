<h2 class='c'>Recomendação Inicial</h2>
<div class="section">
    Resultados - Análise Química Convencional
</div>
<br />
<div class="table">
    <!-- TABELA 1 -->
    <div table=true style="--cs: 1fr 1fr 1fr">
        <div>
            <div class="toptable">
                <div class="label c">
                    Kg/ha
                </div>
            </div>
            <div class="toptable" table=true style="--cs: repeat(5,1fr)">
                <div class="label c">
                    Ca
                </div>
                <div class="label c">
                    Mg
                </div>
                <div class="label c">
                    K
                </div>
                <div class="label c">
                    P
                </div>
                <div class="label c">
                    S
                </div>
            </div>
            <div class="bodytable" table=true style="--cs: repeat(5,1fr)">
                <div class="inputtab">
                    <input [(ngModel)]="calculo.t1.ca1" id="first" type="number" step='0.01' disabled />
                </div>
                <div class="inputtab">
                    <input [(ngModel)]="calculo.t1.mg1" type="number" step='0.01' disabled />
                </div>
                <div class="inputtab">
                    <input [(ngModel)]="calculo.t1.k1" type="number" step='0.01' disabled />
                </div>
                <div class="inputtab">
                    <input [(ngModel)]="calculo.t1.p1" type="number" step='0.01' disabled />
                </div>
                <div class="inputtab">
                    <input [(ngModel)]="calculo.t1.s1" type="number" step='0.01' disabled />
                </div>
            </div>
        </div>
        <div>
            <div class="toptable">
                <div class="label c">
                    g/ha
                </div>
            </div>
            <div class="toptable" table=true style="--cs: repeat(5,1fr)">
                <div class="label c">
                    Fe
                </div>
                <div class="label c">
                    Mn
                </div>
                <div class="label c">
                    Cu
                </div>
                <div class="label c">
                    Zn
                </div>
                <div class="label c">
                    B
                </div>
            </div>
            <div class="bodytable" table=true style="--cs: repeat(5,1fr)">
                <div class="inputtab">
                    <input [(ngModel)]="calculo.t1.fe1" id="first" type="number" step='0.01' disabled />
                </div>
                <div class="inputtab">
                    <input [(ngModel)]="calculo.t1.mn1" type="number" step='0.01' disabled />
                </div>
                <div class="inputtab">
                    <input [(ngModel)]="calculo.t1.cu1" type="number" step='0.01' disabled />
                </div>
                <div class="inputtab">
                    <input [(ngModel)]="calculo.t1.zn1" type="number" step='0.01' disabled />
                </div>
                <div class="inputtab">
                    <input [(ngModel)]="calculo.t1.b1" type="number" step='0.01' disabled />
                </div>
            </div>
        </div>
        <div table=true style="--cs: 1fr 1fr 1fr">
            <div>
                <div class="toptable">
                    <div class="label c">
                        mg/dm3
                    </div>
                </div>
                <div class="toptable" table=true style="--cs: 1fr">
                    <div class="label c">
                        NiCri
                    </div>
                </div>
                <div class="bodytable" table=true style="--cs: 1fr">
                    <div class="inputtab">
                        <input [(ngModel)]="calculo.t1.nicri1" id="first" type="number" step='0.01' disabled />
                    </div>
                </div>
            </div>
            <div>
                <div class="toptable">
                    <div class="label c">
                        (%)
                    </div>
                </div>
                <div class="toptable" table=true style="--cs: 1fr">
                    <div class="label c">
                        Vr
                    </div>
                </div>
                <div class="bodytable" table=true style="--cs: 1fr">
                    <div class="inputtab">
                        <input [(ngModel)]="calculo.t1.vr1" id="first" type="number" step='0.01' disabled />
                    </div>
                </div>
            </div>
            <div>
                <div class="toptable">
                    <div class="label c">
                        Kg/ha
                    </div>
                </div>
                <div class="toptable" table=true style="--cs: 1fr">
                    <div class="label c">
                        Al
                    </div>
                </div>
                <div class="bodytable" table=true style="--cs: 1fr">
                    <div class="inputtab">
                        <input [(ngModel)]="calculo.t1.al1" id="first" type="number" step='0.01' disabled />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br />

    <!-- INSERÇÃO AUTOMATICA DE ITENS -->

    <div *ngFor="let item of keys">
        <h2 class='c' *ngIf="item.id=='t5'">
            <br />Novo cálculo para mesma análise<br />
        </h2>
        <br />
        <div class="section">
            {{item.title}}
        </div>
        <div table=true style="--cs: 1fr 1fr">
            <div>
                <div class="toptable">
                    <div class="label c">
                        Kg/ha
                    </div>
                </div>
                <div class="toptable" table=true style="--cs: repeat(5,1fr)">
                    <div class="label c" *ngFor="let label of item.kgha">
                        {{label}}
                    </div>
                </div>
                <div class="bodytable" table=true style="--cs: repeat(5,1fr)">
                    <div class="inputtab">
                        <input [(ngModel)]="calculo[item.id].ca1" id="first" type="number" step='0.01' disabled />
                    </div>
                    <div class="inputtab">
                        <input [(ngModel)]="calculo[item.id].mg1" type="number" step='0.01' disabled />
                    </div>
                    <div class="inputtab">
                        <input [(ngModel)]="calculo[item.id].k1" type="number" step='0.01' disabled />
                    </div>
                    <div class="inputtab">
                        <input [(ngModel)]="calculo[item.id].p1" type="number" step='0.01' disabled />
                    </div>
                    <div class="inputtab">
                        <input [(ngModel)]="calculo[item.id].s1" type="number" step='0.01' disabled />
                    </div>
                </div>
            </div>
            <div>
                <div class="toptable">
                    <div class="label c">
                        g/ha
                    </div>
                </div>
                <div class="toptable" table=true style="--cs: repeat(5,1fr)">
                    <div class="label c" *ngFor="let label of item.gha">
                        {{label}}
                    </div>
                </div>
                <div class="bodytable" table=true style="--cs: repeat(5,1fr)">
                    <div class="inputtab">
                        <input [(ngModel)]="calculo[item.id].fe1" id="first" type="number" step='0.01' disabled />
                    </div>
                    <div class="inputtab">
                        <input [(ngModel)]="calculo[item.id].mn1" type="number" step='0.01' disabled />
                    </div>
                    <div class="inputtab">
                        <input [(ngModel)]="calculo[item.id].cu1" type="number" step='0.01' disabled />
                    </div>
                    <div class="inputtab">
                        <input [(ngModel)]="calculo[item.id].zn1" type="number" step='0.01' disabled />
                    </div>
                    <div class="inputtab">
                        <input [(ngModel)]="calculo[item.id].b1" type="number" step='0.01' disabled />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br />

    <!-- DADOS DE CORREÇÕES E CONDICIONAIS -->

    <div>
        <h2 class='c'>
            <br />Correções e Condicionais<br />
        </h2>
        <br />

        <div table=true style="--cs: 1fr 1fr">
            <div>
                <div class="section">
                    Correção de Índice Pluviométrico (Inicial)
                </div>
                <div table=true style="--cs: 1fr 168px">
                    <div class="toptable">
                        <div class="label c">
                            Coeficiente de eficiência (tx de infiltração)
                        </div>
                    </div>
                    <div class="bodytable">
                        <div class="inputtab">
                            <input [(ngModel)]="calculo.t12.cftxinfiltracao" id="first" type="number" step='0.01'
                                disabled />
                        </div>
                    </div>
                    <div class="toptable">
                        <div class="label c">
                            Coeficiente de eficiência (Infiltração acumulada)
                        </div>
                    </div>
                    <div class="bodytable">
                        <div class="inputtab">
                            <input [(ngModel)]="calculo.t12.cftxinfiltracaoac" id="first" type="number" step='0.01'
                                disabled />
                        </div>
                    </div>
                    <div class="toptable">
                        <div class="label c">
                            Índice Pluviométrico Ideal
                        </div>
                    </div>
                    <div class="bodytable">
                        <div class="inputtab">
                            <input [(ngModel)]="calculo.t12.indpluvideal" id="first" type="number" step='0.01'
                                disabled />
                        </div>
                    </div>
                    <div class="toptable">
                        <div class="label c">
                            Índice Pluviométrico para cálculo
                        </div>
                    </div>
                    <div class="bodytable">
                        <div class="inputtab">
                            <input [(ngModel)]="calculo.t12.indplucalc" id="first" type="number" step='0.01' disabled />
                        </div>
                    </div>
                    <div class="toptable">
                        <div class="label c">
                            Índice Pluviométrico corrigido
                        </div>
                    </div>
                    <div class="bodytable">
                        <div class="inputtab">
                            <input [(ngModel)]="calculo.t12.indplucorr" id="first" type="number" step='0.01' disabled />
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="section">
                    Condicionais do K
                </div>
                <div table=true style="--cs: 1fr 168px">
                    <div class="toptable">
                        <div class="label c">
                            Reserva
                        </div>
                    </div>
                    <div class="bodytable">
                        <div class="inputtab">
                            <input [(ngModel)]="calculo.t13.reserva" id="first" type="number" step='0.01' disabled />
                        </div>
                    </div>
                    <div class="toptable">
                        <div class="label c">
                            Liberação
                        </div>
                    </div>
                    <div class="bodytable">
                        <div class="inputtab">
                            <input [(ngModel)]="calculo.t13.liberacao" id="first" type="number" step='0.01' disabled />
                        </div>
                    </div>
                    <div class="toptable">
                        <div class="label c">
                            Crivo 1
                        </div>
                    </div>
                    <div class="bodytable">
                        <div class="inputtab">
                            <input [(ngModel)]="calculo.t13.crivo1" id="first" type="number" step='0.01' disabled />
                        </div>
                    </div>
                    <div class="toptable">
                        <div class="label c">
                            Crivo 2
                        </div>
                    </div>
                    <div class="bodytable">
                        <div class="inputtab">
                            <input [(ngModel)]="calculo.t13.crivo2" id="first" type="number" step='0.01' disabled />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br />
        <br />
        <div table=true style="--cs: 1fr 1fr">
            <div>
                <div class="section">
                    Correção de Índice Pluviométrico (Nova análise)
                </div>
                <div table=true style="--cs: 1fr 168px">
                    <div class="toptable">
                        <div class="label c">
                            Índice Pluviométrico Ideal
                        </div>
                    </div>
                    <div class="bodytable">
                        <div class="inputtab">
                            <input [(ngModel)]="calculo.t14.indpluvideal" id="first" type="number" step='0.01'
                                disabled />
                        </div>
                    </div>
                    <div class="toptable">
                        <div class="label c">
                            Índice Pluviométrico para cálculo
                        </div>
                    </div>
                    <div class="bodytable">
                        <div class="inputtab">
                            <input [(ngModel)]="calculo.t14.indplucalc" id="first" type="number" step='0.01' disabled />
                        </div>
                    </div>
                    <div class="toptable">
                        <div class="label c">
                            Índice Pluviométrico corrigido
                        </div>
                    </div>
                    <div class="bodytable">
                        <div class="inputtab">
                            <input [(ngModel)]="calculo.t14.indplucorr" id="first" type="number" step='0.01' disabled />
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="section">
                    Condicionais do K (Nova análise)
                </div>
                <div table=true style="--cs: 1fr 168px">
                    <div class="toptable">
                        <div class="label c">
                            Reserva
                        </div>
                    </div>
                    <div class="bodytable">
                        <div class="inputtab">
                            <input [(ngModel)]="calculo.t15.reserva" id="first" type="number" step='0.01' disabled />
                        </div>
                    </div>
                    <div class="toptable">
                        <div class="label c">
                            Liberação
                        </div>
                    </div>
                    <div class="bodytable">
                        <div class="inputtab">
                            <input [(ngModel)]="calculo.t15.liberacao" id="first" type="number" step='0.01' disabled />
                        </div>
                    </div>
                    <div class="toptable">
                        <div class="label c">
                            Crivo 1
                        </div>
                    </div>
                    <div class="bodytable">
                        <div class="inputtab">
                            <input [(ngModel)]="calculo.t15.crivo1" id="first" type="number" step='0.01' disabled />
                        </div>
                    </div>
                    <div class="toptable">
                        <div class="label c">
                            Crivo 2
                        </div>
                    </div>
                    <div class="bodytable">
                        <div class="inputtab">
                            <input [(ngModel)]="calculo.t15.crivo2" id="first" type="number" step='0.01' disabled />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br />
        <br />
        <div table=true style="--cs: 1fr">
            <div>
                <div class="section">
                    Correção de Solo
                </div>
                <div class="toptable c" table=true style="--cs: repeat(4,1fr)">
                    <div class="label">
                        Kg <br />
                        (CaO / ha)
                    </div>
                    <div class="label">
                        Kg <br />
                        (MgO / ha)
                    </div>
                    <div class="label">
                        Kg <br />
                        (K2O / ha)
                    </div>
                    <div class="label">
                        Kg <br />
                        (P2O5 / ha)
                    </div>
                </div>
                <div class="bodytable c" table=true style="--cs: repeat(4,1fr)">
                    <div class="inputtab">
                        <input [(ngModel)]="calculo.t16.cao_ha" id="first" type="number" step='0.01' disabled />
                    </div>
                    <div class="inputtab">
                        <input [(ngModel)]="calculo.t16.mgo_ha" id="first" type="number" step='0.01' disabled />
                    </div>
                    <div class="inputtab">
                        <input [(ngModel)]="calculo.t16.k2o_ha" id="first" type="number" step='0.01' disabled />
                    </div>
                    <div class="inputtab">
                        <input [(ngModel)]="calculo.t16.p2o5_ha" id="first" type="number" step='0.01' disabled />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br />
    <div table=true style="--cs: 1fr 1fr 1fr 1fr; --rs: 1fr; column-gap: 4px;">
        <div></div>
        <div></div>
        <div color="sucess" class="button btblue" table=true (click)="back()">
            <mat-icon aria-hidden="false">replay</mat-icon>
            <span>Voltar</span>
        </div>
        <div color="sucess" class="button btgreen" table=true (click)="next()">
            <mat-icon aria-hidden="false">navigate_next</mat-icon>
            <span>Dados Gráficos</span>
        </div>
    </div>
</div>