import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PdfgenerateService } from './services/pdfgenerate.service';
import { UtilService } from './services/util.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'frigottoclient';

  constructor(
    public route: Router,
    public util: UtilService,
    public pdf: PdfgenerateService
  ){
    setInterval(() => {
      if(!this.util.getHascode()){
        this.route.navigate(["/"])
      }
    }, 50);
  }

}
