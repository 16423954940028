import { Component, OnInit } from '@angular/core';
import { ConnectionService } from 'src/app/services/connection.service';
import { UtilService } from 'src/app/services/util.service';

interface FormInterface{
    produtor?:string;
    talhao?:string;
    solicitante?:string;
    data?:string;
}

@Component({
    selector: 'app-listlaudosfolha',
    templateUrl: './listlaudosfolha.component.html',
    styleUrls: ['./listlaudosfolha.component.scss'],
})
export class ListlaudosfolhaComponent implements OnInit {
    public loading: boolean = true;
    public list: any;
    public form: FormInterface = {};

    constructor(private conn: ConnectionService, private util: UtilService) {}

    ngOnInit(): void {
        this.getLaudos();
    }

    async getLaudos() {
        this.loading = true;
        try {
            this.list = await this.conn.getLaudoPlanta();
            console.log(this.list);
        } catch (error) {}
        this.loading = false;
    }

    async searchLaudo(){
        this.loading = true;
        try {
            this.list = await this.conn.getLaudoPlantaSearch(this.form);
            console.log(this.list);
        } catch (error) {}
        this.loading = false;
    }

    open(id) {
        this.util.go(`cadfolha/${id}`);
    }
}
