<div class="struct" id="struct">
    <div id="laudo_folha">
        <div class="section">Informações do Laudo</div>
        <div class="grid2">
            <div class="info">
                <span class="tag">Solicitado por: </span>
                <span class="text">{{ calculo.laudo.dssolicitante }}</span>
            </div>
            <div class="info">
                <span class="tag">Data do Laudo: </span>
                <span class="text">{{
                    calculo.laudo.dtlaudo | date: "dd/MM/yyyy"
                }}</span>
            </div>
            <div class="info">
                <span class="tag">Produtor: </span>
                <span class="text">{{ calculo.laudo.dscliente }}</span>
            </div>
            <div class="info">
                <span class="tag">Dias: </span>
                <span class="text">{{ calculo.laudo.dias }}</span>
            </div>
            <div class="info">
                <span class="tag">Talhão: </span>
                <span class="text">{{ calculo.laudo.talhao }}</span>
            </div>
            <div class="info">
                <span class="tag">Cultura: </span>
                <span class="text">{{ calculo.laudo.dscultura }}</span>
            </div>
            <div class="info">
                <span class="tag">Município: </span>
                <span class="text">{{ calculo.laudo.municipio }}</span>
            </div>
            <div class="info">
                <span class="tag">Estado: </span>
                <span class="text">{{ calculo.laudo.estado }} </span>
            </div>
            
        </div>
        <div class="info">
            <mat-slide-toggle [(ngModel)]="uselogo" (change)="changeUseLogoPlanta()"
                ><span style="font-size: 12px"
                    >Usar logo configurada?</span
                ></mat-slide-toggle
            >
        </div>
        <div class="section">Nutrientes</div>
        <div class="grid2">
            <div class="card">
                <div class="title">Macronutrientes - Kg/ha</div>
                <div class="tab">
                    <div class="table">
                        <div class="grid6">
                            <div
                                *ngFor="let m of macronutrientes | getkeyvalue"
                            >
                                <div class="toptable">
                                    <div
                                        class="label c"
                                        [innerHtml]="m.value"
                                    ></div>
                                </div>
                                <div class="bodytable">
                                    <div class="inputtab">
                                        <input
                                            [(ngModel)]="
                                                calculo
                                                    .resultado_final_soma_nutrientes[
                                                    m.key
                                                ]
                                            "
                                            type="number"
                                            min="0"
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="title">Micronutrientes - g/ha</div>
                <div class="tab">
                    <div class="table">
                        <div class="grid6">
                            <div
                                *ngFor="let m of micronutrientes | getkeyvalue"
                            >
                                <div class="toptable">
                                    <div
                                        class="label c"
                                        [innerHtml]="m.value"
                                    ></div>
                                </div>
                                <div class="bodytable">
                                    <div class="inputtab">
                                        <input
                                            [(ngModel)]="
                                                calculo
                                                    .resultado_final_soma_nutrientes[
                                                    m.key
                                                ]
                                            "
                                            type="number"
                                            min="0"
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section">Tecido Vegetal</div>
        <div class="grid2">
            <div class="card">
                <div class="title">Nutriente/Matéria Seca (%)</div>
                <div class="chart">
                    <stackedbarchart
                        id_chart="chart"
                        #chartmateriaorganicanutriente
                        [inversed]="false"
                        [data]="formatDataToChartOrganicMatter()"
                        [minY]="0"
                        [legend]="false"
                        [maxY]="110"
                        [adapterX]="adapterMatOrgNut"
                    ></stackedbarchart>
                </div>
            </div>
            <div class="card">
                <div class="title">Equilíbrio Nutricional</div>
                <div class="chart">
                    <negativecolumchart
                        id_chart="chart2"
                        #chartsuficiencianutricional
                        [labels]="false"
                        [colors]="['#312783']"
                        [data]="formatDataToChartNutritionalSufficiency()"
                    ></negativecolumchart>
                </div>
            </div>
        </div>
        <div class="grid1">
            <div class="card">
                <div class="title">Lei de Liebig - Lei do Barril</div>
                <div class="chart">
                    <stackedcolumchart
                        #chartequilibrionutricional
                        id_chart="chart3"
                        [data]="formatDataToChartNutritionalBalance()"
                        [calculate]="false"
                        [legend]="false"
                        [maxY]="210"
                        [color]="color_unique"
                        [inirangeY]="1"
                        [rangeY]="150"
                        [wcolumn]="100"
                        [showAxisY]="false"
                        [showLabels]="false"
                        [rangetext]="'Aplica'"
                        [centerbullet]="false"
                        width="85vw"
                    ></stackedcolumchart>
                </div>
            </div>
        </div>
        <div class="grid1">
            <div class="card">
                <div class="title">Observações</div>
                <div class="tab">
                    <textarea
                        name="observacoes"
                        [rows]="max_rows_obs"
                        [(ngModel)]="calculo.laudo.observacoes"
                        [disabled]="bloqueia_observacoes"
                        (keyup)="validaObservacoes()"
                    ></textarea>
                </div>
            </div>
            <div class="label_obs">
                {{getRowsObs()}} linhas de {{max_rows_obs}}.
            </div>
        </div>
    </div>
</div>
<spinner *ngIf="loading"></spinner>
<fabbutton
    icon="print.svg"
    (click)="print()"
    color="var(--color-dark)"
></fabbutton>
