import { Component, Input, OnInit } from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { colors_list } from '../theme/theme_graph';

@Component({
    selector: 'negativecolumchart',
    templateUrl: './negativecolumchart.component.html',
    styleUrls: ['./negativecolumchart.component.scss'],
})
export class NegativecolumchartComponent implements OnInit {
    @Input() id_chart;
    @Input() data = [];
    @Input() minY = 0;
    @Input() maxY = 100;
    @Input() rangeX;
    @Input() calculate = true;
    @Input() legend = true;
    @Input() labels = true;
    @Input() width = '647px';
    @Input() colors;
    public chart: am4charts.XYChart;

    constructor() {}

    ngOnInit(): void {}

    ngAfterViewInit() {
        this.init();
    }

    async exportChart(size = '647px') {
        let options = this.chart.exporting.getFormatOptions('png');
        options.minWidth = 2600;
        options.minHeight = 1700;
        options.maxWidth = 2600;
        options.maxHeight = 1700;
        let width = this.width;
        this.width = size;
        let image = await this.chart.exporting.getImage('png');
        this.width = width;
        return image;
    }

    init() {
        this.chart = am4core.create(this.id_chart, am4charts.XYChart);
        this.chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
        this.chart.logo.height = -15000;
        this.chart.data = this.data;
        console.log(this.data);

        this.chart.colors.list = colors_list;
        if (this.colors) {
            this.chart.colors.list = this.colors.map((color) =>
                am4core.color(color)
            );
        }
        this.chart.colors.step = 1;
        this.chart.padding(12, 12, 12, 12);
        if (this.legend) {
            this.chart.legend = new am4charts.Legend();
            this.chart.legend.labels.template.fontSize = 14;
        }

        let categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = 'category';
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minWidth = 10;
        categoryAxis.renderer.minGridDistance = 10;
        categoryAxis.renderer.labels.template.fontSize = 16;

        // categoryAxis.renderer.inversed = true;

        let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
        // valueAxis.extraMin = 0.1;
        // valueAxis.extraMax = 0.1;
        valueAxis.strictMinMax = true;
        valueAxis.renderer.minGridDistance = 10;
        valueAxis.renderer.minWidth = 10;
        valueAxis.min = this.minY;
        valueAxis.max = this.maxY;
        valueAxis.renderer.labels.template.adapter.add(
            'text',
            (label, target) => {
                return String(target.dataItem.values.value.value * 2 - 100)+"%";
            }
        );
        valueAxis.renderer.labels.template.fontSize = 13;


        for (let s in this.data[0]) {
            if (s != 'category') {
                this.generateSeries(s, s, 'category', this.data[0][s]);
            }
        }
    }

    generateSeries(name, valueY, category = 'category', data) {
        let series = this.chart.series.push(new am4charts.ColumnSeries());
        series.columns.template.width = am4core.percent(80);
        // series.columns.template.tooltipText = "{valueY.formatNumber('#.00')}%";
        series.columns.template.tooltipText = "{valueY.formatNumber('#.00')}";
        series.columns.template.adapter.add('tooltipText', (text, target) => {
            return (target.tooltipDataItem.dataContext['Atual'] * 2 - 100).toFixed(2)+"%";
        });
        series.name = name;
        series.dataFields.categoryX = category;
        series.dataFields.valueY = valueY;
        series.dataItems.template.locations.categoryX = 0.5;
        series.clustered = false;
        series.tooltip.pointerOrientation = 'vertical';

        if (this.labels) {
            let bullet = series.bullets.push(new am4charts.LabelBullet());
            bullet.interactionsEnabled = false;
            bullet.label.text = "{valueY.formatNumber('#.00')}%";
            bullet.label.fill = colors_list[0];
            bullet.label.fontSize = 11;
            if (data < 0) {
                bullet.label.dy = 12;
            } else {
                bullet.label.dy = -12;
            }
        }
    }
}
